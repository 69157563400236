import { useEffect, useState } from 'react'
import { Link, LinkProps, useLocation } from 'wouter'

export interface QueryLinkProps extends Omit<LinkProps, 'href'> {
  href: string
}

const QueryLink = ({ children, href, ...props }: QueryLinkProps) => {
  const [location] = useLocation()
  const [search, setSearch] = useState<string>('')

  useEffect(() => {
    setSearch(window.location.search)
  }, [location])

  const fullHref = `${href}${search}`

  return (
    <Link {...(props as any)} href={fullHref}>
      {children}
    </Link>
  )
}

export default QueryLink
