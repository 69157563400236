import styled from 'styled-components'

export const FooterContainer = styled.footer`
  background: ${({ theme }) => theme.colors.corporateBlue};
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 40px 15px;
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    max-width: ${({ theme }) => theme.sizes.mainContent};
    padding: 30px 24px;
  }
`
