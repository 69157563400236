import { atom } from 'recoil'
import { StoreStateName } from '../storeStateName'
import { IChannel, IProgramme } from '../../types/entities'

export type BroadcastStateType = Record<string, BroadcastDateType>
export type BroadcastDateType = Record<IChannel['id'], BroadcastDateChannelType>
export type BroadcastDateChannelType = IProgramme[]

export const broadcastsState = atom<BroadcastStateType>({
  key: StoreStateName.BROADCAST,
  default: {}
})
