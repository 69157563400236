import AppConfig from './config'
import useDetectBroadcastingDate from './hooks/useDetectBroadcastingDate'
import { Redirect, Route, Switch } from 'wouter'
import { Helmet } from 'react-helmet'
import { broadcastingParamsToDate, isBroadcastDateValid } from './utils/date'
import type {
  BroadcastingParamsType,
  ChannelGroupBroadcastingParamsType,
  ChannelGroupParamsType,
  ChannelGroupProgrammeDetailParamsType,
  ProgrammeDetailParamsType
} from './types/entities'
import { ChannelParamsType, ChannelProgrammeDetailParamsType } from './types/entities'
import HomepageWrapper from './views/HomePage/HomepageWrapper'
import ChannelPage from './views/LandingPage/ChannelPage'
import { HomepageProgramDetail } from './components/ProgramDetail/HomepageProgrammeDetail'
import { MobileLandingPageProgramDetail } from './components/LandingPage/ProgramDetails/MobileProgrammeDetail'
import { ChannelGroupPage } from './views/LandingPage/ChannelGroupPage'
import { ChannelGroupProgrammeDetail } from './components/LandingPage/ProgramDetails/ChannelGroupProgrammeDetail'
import { removeLeadingZero } from './utils/string'

const AppRouter = () => {
  useDetectBroadcastingDate()

  return (
    <Switch>
      <Route path={AppConfig.routes.home}>
        <HomepageWrapper />
        <Helmet>
          <link rel={'canonical'} href={AppConfig.domain.concat(AppConfig.routes.home)} />
          <title>TV Program - Přehledný televizní program více než 100 stanic</title>
          <meta
            name={'description'}
            content={
              'Televizní program oblíbených českých i zahraničních stanic 📺 Detaily pořadů na týden dopředu i zpětně 📺 Možnost řádkového nebo sloupcového zobrazení.'
            }
          />
        </Helmet>
      </Route>
      <Route path={AppConfig.routes.broadcasting(':year', ':month', ':day')}>
        {({ year, month, day }: BroadcastingParamsType) => {
          const dayNoLeadingZero = removeLeadingZero(day)
          const monthNoLeadingZero = removeLeadingZero(month)

          return isBroadcastDateValid(broadcastingParamsToDate({ year, month, day })) ? (
            <>
              <HomepageWrapper />
              <Helmet>
                <title>
                  TV Program na {dayNoLeadingZero}.{monthNoLeadingZero}.{year}
                </title>
                <meta
                  name={'description'}
                  content={`Televizní program na ${dayNoLeadingZero}.${monthNoLeadingZero}.${year} s tipy na filmy, seriály a pořady s možností výběru z desítek českých i zahraničních stanic.`}
                />
                <link
                  rel={'canonical'}
                  href={AppConfig.domain.concat(AppConfig.routes.broadcasting(year!, month!, day!))}
                />
              </Helmet>
            </>
          ) : (
            <Redirect href={AppConfig.routes.home} replace />
          )
        }}
      </Route>
      <Route path={AppConfig.routes.detail(':slug')}>
        {({ slug }: ProgrammeDetailParamsType) => (
          <>
            <HomepageWrapper />
            <HomepageProgramDetail slug={slug} />
          </>
        )}
      </Route>
      <Route path={AppConfig.routes.channel(':slug')}>
        {({ slug }: ChannelParamsType) => (
          <>
            <ChannelPage slug={slug} />
            <Helmet>
              <link
                rel={'canonical'}
                href={AppConfig.domain.concat(AppConfig.routes.channel(slug))}
              />
            </Helmet>
          </>
        )}
      </Route>
      <Route path={AppConfig.routes.channelProgrammeDetail(':channelSlug', ':programmeSlug')}>
        {({ channelSlug, programmeSlug }: ChannelProgrammeDetailParamsType) => (
          <>
            <ChannelPage slug={channelSlug} />
            <MobileLandingPageProgramDetail
              channelSlug={channelSlug}
              programmeSlug={programmeSlug}
            />
            <Helmet>
              <link
                rel={'canonical'}
                href={AppConfig.domain.concat(
                  AppConfig.routes.channelProgrammeDetail(channelSlug, programmeSlug)
                )}
              />
              <meta name='robots' content='noindex, nofollow' />
            </Helmet>
          </>
        )}
      </Route>
      <Route path={AppConfig.routes.channelGroup(':slug')}>
        {({ slug }: ChannelGroupParamsType) => (
          <>
            <ChannelGroupPage groupSlug={slug} />
            <Helmet>
              <link
                rel={'canonical'}
                href={AppConfig.domain.concat(AppConfig.routes.channelGroup(slug))}
              />
            </Helmet>
          </>
        )}
      </Route>
      <Route path={AppConfig.routes.channelGroupBroadcasting(':slug', ':year', ':month', ':day')}>
        {({ slug, year, month, day }: ChannelGroupBroadcastingParamsType) =>
          isBroadcastDateValid(broadcastingParamsToDate({ year, month, day })) ? (
            <>
              <ChannelGroupPage groupSlug={slug} date={{ year, month, day }} />
              <Helmet>
                <link
                  rel={'canonical'}
                  href={AppConfig.domain.concat(
                    AppConfig.routes.channelGroupBroadcasting(slug, year, month, day)
                  )}
                />
              </Helmet>
            </>
          ) : (
            <Redirect href={AppConfig.routes.channelGroup(slug)} replace />
          )
        }
      </Route>
      <Route
        path={AppConfig.routes.channelGroupProgrammeDetail(':channelGroupSlug', ':programmeSlug')}
      >
        {({ channelGroupSlug, programmeSlug }: ChannelGroupProgrammeDetailParamsType) => (
          <>
            <ChannelGroupPage groupSlug={channelGroupSlug} />
            <ChannelGroupProgrammeDetail
              channelGroupSlug={channelGroupSlug}
              programmeSlug={programmeSlug}
            />
            <Helmet>
              <link
                rel={'canonical'}
                href={AppConfig.domain.concat(
                  AppConfig.routes.channelGroupProgrammeDetail(channelGroupSlug, programmeSlug)
                )}
              />
              <meta name='robots' content='noindex, nofollow' />
            </Helmet>
          </>
        )}
      </Route>
    </Switch>
  )
}

export default AppRouter
