import {
  Container,
  MobileNav,
  StyledButton,
  StyledChevron,
  StyledCross,
  StyledItem
} from './StyledMobileNavigation'
import useMobileNavigation from './useMobileNavigation'
import { useRecoilValueLoadable } from 'recoil'
import { appState } from '../../store/app/state/appState.atom'
import moment from 'moment'
import 'moment/locale/cs'
import MobileDateSelect from './MobileDateSelect'
import { useState } from 'react'
import { StyledMobileDateSelectButton } from './StyledMobileDateSelectButton'
import { CentrumLogoNavMobile } from '../Logos/CentrumLogoNavMobile'

export const MobileNavigation = () => {
  const { ref, isOpen, setOpen, openSettingsModal } = useMobileNavigation()
  const [isDatePageOpen, setDatePageOpen] = useState(false)
  const {
    contents: { selectedDate }
  } = useRecoilValueLoadable(appState)

  return (
    <MobileNav ref={ref}>
      <StyledButton
        type='button'
        name='toggle'
        onClick={() => {
          isOpen && setDatePageOpen(false)
          setOpen(!isOpen)
        }}
        isOpen={isOpen}
      >
        <StyledCross />
      </StyledButton>
      <Container $isOpen={isOpen}>
        <StyledItem>
          <StyledMobileDateSelectButton onClick={() => setDatePageOpen(true)}>
            <span>Datum</span>
            <StyledChevron>{moment(selectedDate).locale('cs').format('dddd D.M.')}</StyledChevron>
          </StyledMobileDateSelectButton>
        </StyledItem>
        <MobileDateSelect
          isVisible={isOpen && isDatePageOpen}
          onClose={() => setDatePageOpen(false)}
          onSelect={() => {
            window.scrollTo({
              top: 0,
              left: 0
            })
            setDatePageOpen(false)
            setOpen(false)
          }}
        />
        <StyledItem>
          <button type='button' onClick={openSettingsModal}>
            <span>Nastavení stanic</span>
            <StyledChevron />
          </button>
        </StyledItem>
        <CentrumLogoNavMobile />
      </Container>
    </MobileNav>
  )
}
