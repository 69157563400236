import { useWrappedRecoilValue } from '../../hooks/useWrappedRecoilValue'
import { channelsSelector } from '../../store/channels/channels.selectors'
import { useRecoilStateLoadable, useResetRecoilState } from 'recoil'
import { userChannelsSelector } from '../../store/settings/settings.selectors'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { BroadcastingParamsType, IChannel } from '../../types/entities'
import debounce from 'lodash/debounce'
import uniq from 'lodash/uniq'
import { arrayMove } from '@dnd-kit/sortable'
import { DragEndEvent } from '@dnd-kit/core/dist/types'
import { webalize } from 'webalize'
import { broadcastsState } from '../../store/broadcasts/broadcasts.atom'
import { useRoute } from 'wouter'
import AppConfig from '../../config'

const useSettings = (closeModal: () => void) => {
  const { data: channels } = useWrappedRecoilValue(channelsSelector(undefined), [])
  const [{ contents: userChannels }, setUserChannels] = useRecoilStateLoadable(userChannelsSelector)
  const resetLoadedBroadcasts = useResetRecoilState(broadcastsState)

  const [selectedChannels, setSelectedChannels] = useState<IChannel['id'][]>([])
  const [visibleChannels, setVisibleChannels] = useState<IChannel[]>([])

  const [homepageMatch] = useRoute(AppConfig.routes.home)
  const [broadcastingMatch] = useRoute<BroadcastingParamsType>(
    AppConfig.routes.broadcasting(':year', ':month', ':day')
  )

  useEffect(() => {
    setVisibleChannels(channels)
  }, [channels])

  useEffect(() => {
    setSelectedChannels((previousSelectedChannels) =>
      uniq(previousSelectedChannels.concat(userChannels))
    )
  }, [userChannels])

  const handleCheckboxChange = useCallback((channelId: string) => {
    setSelectedChannels((oldChannels: string[]) => {
      const index = oldChannels.findIndex((id) => id === channelId)
      if (index > -1) {
        return oldChannels.filter((id) => id !== channelId)
      } else {
        return [...oldChannels, channelId]
      }
    })
  }, [])

  const handleOrderChange = useCallback((event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over?.id) {
      setSelectedChannels((channels) => {
        const oldIndex = channels.indexOf(active.id)
        const newIndex = channels.indexOf(over!.id)

        return arrayMove(channels, oldIndex, newIndex)
      })
    }
  }, [])

  const handleProgrammaticallyChange = useCallback(
    (id: IChannel['id'], oldIndex: number, newIndex: number) => {
      setSelectedChannels((channels) => arrayMove(channels, oldIndex, newIndex))
    },
    []
  )

  const webalizedChannels: Array<[string[], IChannel]> = useMemo(() => {
    return channels.map((channel) => {
      const webalizedName = webalize(channel.name).split('-')
      return [webalizedName, channel]
    })
  }, [channels])

  const handleSearch = useCallback(
    debounce((value: string) => {
      const searchValue: string[] = webalize(value).split('-').filter(Boolean)

      if (searchValue.length > 0) {
        const filteredChannels = webalizedChannels
          .filter(([webalizedName]) =>
            searchValue.every((partOfName) =>
              webalizedName.some((value) => value.indexOf(partOfName) === 0)
            )
          )
          .map(([, channel]) => channel)
        setVisibleChannels(filteredChannels)
      } else {
        setVisibleChannels(channels.slice())
      }
    }, 300),
    [webalizedChannels, channels]
  )

  const handleSave = () => {
    setUserChannels(selectedChannels)

    if (homepageMatch || broadcastingMatch) {
      resetLoadedBroadcasts()
    }

    closeModal()
  }

  return {
    channels: visibleChannels,
    selectedChannels,
    onSave: handleSave,
    onCheckboxChange: handleCheckboxChange,
    onOrderChange: handleOrderChange,
    onProgrammaticallyChange: handleProgrammaticallyChange,
    onSearch: handleSearch
  }
}

export default useSettings
