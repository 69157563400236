import { selectorFamily } from 'recoil'
import { StoreSelector } from '../storeStateName'
import { ChannelGroupResponse } from '../../types/entities'
import * as api from '../api'
import { channelGroupState } from './channelGroup.atom'

export const channelGroupSelector = selectorFamily<ChannelGroupResponse, string>({
  key: StoreSelector.CHANNELS_LIST,
  get:
    (slug) =>
    ({ get }) => {
      const channelGroup = get(channelGroupState)

      if (channelGroup) {
        return channelGroup
      }

      return api.fetchChannelGroup(slug)
    }
})
