import type { SVGProps } from 'react'

const CloseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect
        y='1.41431'
        width='2'
        height='22'
        rx='1'
        transform='rotate(-45 0 1.41431)'
        fill='currentColor'
      />
      <rect
        x='15.5564'
        width='2'
        height='22'
        rx='1'
        transform='rotate(45 15.5564 0)'
        fill='currentColor'
      />
    </svg>
  )
}

export default CloseIcon
