import theme from '../../client/theme'
import styled from 'styled-components'

export const Container = styled.div`
  background: ${theme.colors.default};
  width: 100%;
  height: 100%;
  max-width: 990px;
  max-height: 588px;
  border-radius: ${theme.radius.default};
  position: relative;
  overflow: auto;
`
