import { DefaultButton } from '../StyledButtons'
import { IProgramme } from '../../types/entities'
import config from '../../config'
import ShareIcon from '../Icons/ShareIcon'

interface ProgramShareButtonProps {
  programme: IProgramme
}

const ProgramShareButton = ({ programme }: ProgramShareButtonProps) => {
  const isSupported = 'share' in navigator
  if (!isSupported) {
    return null
  }

  const handleShare = () => {
    navigator
      .share({
        title: programme.title,
        text: programme.description,
        url: config.domain.concat(config.routes.detail(programme.slug))
      })
      .catch((err) => {
        console.error(`Program cannot be shared`, err)
      })
  }

  return (
    <DefaultButton onClick={handleShare}>
      <ShareIcon />
      Sdílet
    </DefaultButton>
  )
}

export default ProgramShareButton
