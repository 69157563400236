import styled from 'styled-components'

export const StyledBackToHomeButton = styled.button`
  color: ${({ theme }) => theme.colors.default};
  border: 1px solid ${({ theme }) => theme.colors.default};
  font-size: ${({ theme }) => theme.sizes.smaller};
  font-weight: 500;
  border-radius: 6px;
  padding: 8px 10px;
  line-height: 14px;

  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.channelTitleBackground};
    background: ${({ theme }) => theme.colors.default};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: ${({ theme }) => theme.sizes.medium};
    line-height: 16px;
  }
`
