import { MainLogo } from '../../Logos/MainLogo'
import { Menu } from '../../Menu'
import styled from 'styled-components'
import { StyledMainLogo } from '../../Logos/StyledLogos'
import { ReactNode } from 'react'

interface Props {
  onTimeSelectCallbacks: {
    onDateSelect: (date: string) => void
    onNowClick: () => void
  }
  logo?: ReactNode
}

export const Header = ({ onTimeSelectCallbacks, logo = <MainLogo /> }: Props) => {
  return (
    <StyledContainer>
      {logo}
      <Menu onTimeSelectCallbacks={onTimeSelectCallbacks} />
    </StyledContainer>
  )
}

export const StyledContainer = styled.header`
  background: ${({ theme }) => theme.colors.corporateBlue};
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 12px;
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zindex.header};
  gap: 32px;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    max-width: ${({ theme }) => theme.sizes.mainContent};
    padding: 22px 24px;
    gap: initial;
  }

  ${StyledMainLogo} {
    margin-right: 24px;
  }
`
