import { DefaultValue, selector } from 'recoil'
import { StoreSelector } from '../storeStateName'
import { IChannel } from '../../types/entities'
import { settingsState } from './settings.atom'
import { SettingsStateType } from '../types'

export const userChannelsSelector = selector<IChannel['id'][]>({
  key: StoreSelector.SETTINGS_CHANNELS,
  get: ({ get }) => {
    const settings = get(settingsState)
    return settings.channelIds
  },
  set: ({ set }, newValue) => {
    set(settingsState, (oldValues: SettingsStateType) => ({
      ...oldValues,
      channelIds: newValue instanceof DefaultValue ? oldValues.channelIds : newValue
    }))
  }
})
