import { breakpoints } from './breakpoints'

const COLORS = {
  default: '#fff',
  dark: '#050505',
  corporateBlue: '#033349',
  corporateColorDark: '#0d232e',
  buttonLightColor: '#f6941c',
  buttonColor: '#325d78',
  channelTitleBackground: '#0d232e',
  channelSelectedBackground: 'rgba(50, 93, 120, 0.1)',
  channelBorder: '#eaeff1',
  selectionFontColor: '#333',
  selectionFontGrey: '#6a747a',
  itemBackground: 'rgba(13, 35, 46, 0.5)',
  itemBackgroundLight: 'rgba(246, 148, 28, 0.62)',
  modal: 'rgba(0, 0, 0, 0.33)',
  searchBackground: 'rgba(13, 35, 46, 0.9)',
  searchBorder: '#d8e0e5',
  listItem: 'rgba(255, 255, 255, 0.8)',
  progressBarBackground: 'rgba(0, 0, 0, 0.25)',
  scrollbar: 'rgba(255, 255, 255, 0.4)',
  time: 'rgba(255, 255, 255, 0.66)',
  opacity8: 'rgba(255, 255, 255, 0.8)',
  iconOrange: '#f6941c',
  iconWhite: '#fff',
  iconSearch: '#6a747a'
}

const SIZES = {
  smallest: '10px',
  smaller: '12px',
  small: '13px',
  medium: '14px',
  big: '15px',
  bigger: '16px',
  biggest: '18px',
  Heading2: '20px',
  Heading1: '20px',
  mainContent: '1080px',
  mainContentVerticalPadding: '14px',

  headerHeightDesktop: 73,
  headerHeightMobile: 56,

  channelPage: {
    detailWidth: 300,
    columnGap: 8
  }
}

const FONTS = {
  default: '"Roboto", "Arial CE", Arial, sans-serif'
}

const RADIUS = {
  default: '6px'
}

const TRANSITIONS = {}

const SHADOWS = {}

const BREAKPOINTS = {
  xSmall: `${breakpoints.xSmall}px`,
  small: `${breakpoints.small}px`,
  medium: `${breakpoints.medium}px`,
  large: `${breakpoints.large}px`
}

const ZINDEX = {
  channel: '201',
  mobileNav: '301',
  header: '301',
  modal: '302'
}

const OPACITY = {
  disabled: 0.5,
  default: 1
}

export default {
  colors: COLORS,
  fonts: FONTS,
  sizes: SIZES,
  shadows: SHADOWS,
  radius: RADIUS,
  transitions: TRANSITIONS,
  breakpoints: BREAKPOINTS,
  zindex: ZINDEX,
  opacity: OPACITY
}
