import { BroadcastDisplayMode } from '../../constants'
import { HeaderIconGroupButton } from '../StyledButtons'
import { useRecoilStateLoadable } from 'recoil'
import { settingsState } from '../../store/settings/settings.atom'
import { useCallback } from 'react'
import { sendLayoutChangeEvent } from '../../analytics/ga4Events'
import Tooltip from '../Tooltip'
import LayoutIconRow from '../Icons/LayoutIconRow'
import LayoutIconColumn from '../Icons/LayoutIconColumn'
import styled from 'styled-components'

const DisplayModeButtons = () => {
  const [{ contents: settings }, setSettings] = useRecoilStateLoadable(settingsState)

  const setBroadcastingDisplayMode = useCallback((mode: BroadcastDisplayMode) => {
    setSettings((oldState) => ({
      ...oldState,
      broadcastDisplayMode: mode
    }))
  }, [])

  return (
    <DisplayModeButtonsContainer>
      <HeaderIconGroupButton
        $isActive={settings!.broadcastDisplayMode === BroadcastDisplayMode.HORIZONTAL}
        onClick={() => {
          sendLayoutChangeEvent('list')
          setBroadcastingDisplayMode(BroadcastDisplayMode.HORIZONTAL)
        }}
      >
        <Tooltip label={'Řádkové zobrazení'}>
          <LayoutIconRow />
        </Tooltip>
      </HeaderIconGroupButton>
      <HeaderIconGroupButton
        $isActive={settings!.broadcastDisplayMode === BroadcastDisplayMode.VERTICAL}
        onClick={() => {
          sendLayoutChangeEvent('column')
          setBroadcastingDisplayMode(BroadcastDisplayMode.VERTICAL)
        }}
      >
        <Tooltip label={'Sloupcové zobrazení'}>
          <LayoutIconColumn />
        </Tooltip>
      </HeaderIconGroupButton>
    </DisplayModeButtonsContainer>
  )
}

export const DisplayModeButtonsContainer = styled.div``

export default DisplayModeButtons
