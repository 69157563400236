import useWindowSize from '../../../hooks/useWindowSize'
import { StyledContainer } from './StyledContainer'
import { ChannelLogo } from '../../Logos/ChannelLogo'
import { Heading1 } from '../../StyledHeadings'
import { Link } from 'wouter'
import { StyledBackToHomeButton } from '../../Button/StyledBackToHomeButton'
import AppConfig from '../../../config'
import { StyledButtonContainer } from './StyledButtonContainer'
import { broadcastsState } from '../../../store/broadcasts/broadcasts.atom'
import { useResetRecoilState } from 'recoil'

interface Props {
  channelId: string
  name: string
}

export const ChannelHeader = ({ channelId, name }: Props) => {
  const isMobile = !useWindowSize('medium')
  const resetBroadcastState = useResetRecoilState(broadcastsState)

  return (
    <StyledContainer>
      <ChannelLogo channelId={channelId} alt={`Logo ${name}`} />
      <Heading1>{`TV program - ${name}`}</Heading1>
      <Link to={AppConfig.routes.home} onClick={resetBroadcastState}>
        <StyledButtonContainer>
          <StyledBackToHomeButton>
            {isMobile ? 'Seznam stanic' : 'Zpět na seznam stanic'}
          </StyledBackToHomeButton>
        </StyledButtonContainer>
      </Link>
    </StyledContainer>
  )
}
