import { Label, Checkbox } from '../FormElements/StyledCheckbox'
import { ChannelCategory, IChannel } from '../../types/entities'
import { Heading4Blue } from '../StyledHeadings'
import { ListChannel, ListChannelItem } from './Order/StyledListChannel'
import React from 'react'

export interface ChannelProgrammesProps {
  channels: IChannel[]
  selectedChannels: IChannel['id'][]
  onCheckboxChange: (id: IChannel['id']) => void
}

const CategoriesNames: Record<ChannelCategory, string> = {
  [ChannelCategory.CZECH]: 'Základní české',
  [ChannelCategory.SLOVAKIA]: 'Slovenské',
  [ChannelCategory.MOVIE_SERIALS]: 'Filmy a seriály',
  [ChannelCategory.KIDS]: 'Dětské',
  [ChannelCategory.NEWS]: 'Zpravodajství',
  [ChannelCategory.SPORT]: 'Sport',
  [ChannelCategory.MUSIC]: 'Hudba',
  [ChannelCategory.DOCUMENTS_STYLE]: 'Dokumenty a styl',
  [ChannelCategory.EROTIC]: 'Erotické'
}

const ChannelProgrammes = ({
  channels,
  selectedChannels,
  onCheckboxChange
}: ChannelProgrammesProps) => {
  return (
    <>
      {Object.entries(CategoriesNames)
        .filter(([category]) => channels.some((channel) => channel.category === category))
        .map(([category, name]) => (
          <React.Fragment key={category}>
            <Heading4Blue>{name}</Heading4Blue>
            <ListChannel>
              {channels
                .filter((channel: IChannel) => channel.category === category)
                .map((channel: IChannel) => (
                  <ListChannelItem key={channel.id}>
                    <Label>
                      <Checkbox
                        checked={selectedChannels.includes(channel.id)}
                        onChange={() => onCheckboxChange(channel.id)}
                      />
                      <span />
                      {channel.name}
                    </Label>
                  </ListChannelItem>
                ))}
            </ListChannel>
          </React.Fragment>
        ))}
    </>
  )
}

export default ChannelProgrammes
