import styled, { css } from 'styled-components'
import { StyledListLink, VerticalItemWrapper } from './StyledContainer'
import { Header } from './StyledHeader'
import { StyledVerticalLabel } from './StyledLabel'
import { VerticalContent } from './StyledContent'
import { ProgressBar } from './StyledProgressBar'
import { Heading4 } from '../../StyledHeadings'
import { IProgramme } from '../../../types/entities'
import { formatStartTime } from '../../../utils/date'
import { useListItem } from './useListItem'
import theme from '../../../client/theme'

interface Props {
  programme: Pick<
    IProgramme,
    'slug' | 'title' | 'titleOriginal' | 'description' | 'genre' | 'start' | 'stop'
  >
  className?: string
  onProgrammeClick: (slug: string) => void
}

const StyledHeading = styled(Heading4)`
  color: inherit;
  padding: 0 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  height: 30px;
  margin: 0;

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    height: 35px;
  }
`

const StyledParagraph = styled.p<{ $isActive: boolean }>`
  ${(props) =>
    props.$isActive &&
    css`
      padding: 0 10px;
      color: inherit;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
      height: 45px;
    `}
`

export const VerticalItem = ({ programme, className, onProgrammeClick }: Props) => {
  const { timeWatchedPercent, isRunning, genre } = useListItem(programme)

  return (
    <VerticalItemWrapper className={className} $isActive={isRunning}>
      <StyledListLink onClick={() => onProgrammeClick(programme.slug)}>
        <Header>
          {formatStartTime(programme.start)}&nbsp;
          {genre && <StyledVerticalLabel>{genre}</StyledVerticalLabel>}
        </Header>
        <VerticalContent>
          <StyledHeading>{programme.title || programme.titleOriginal}</StyledHeading>
          {isRunning && (
            <>
              <StyledParagraph $isActive={isRunning}>{programme.description}</StyledParagraph>
              <ProgressBar $progress={timeWatchedPercent} />
            </>
          )}
        </VerticalContent>
      </StyledListLink>
    </VerticalItemWrapper>
  )
}
