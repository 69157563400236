import React, { Component } from 'react'
import styled from 'styled-components'
import theme from '../../client/theme'

const { breakpoints } = theme

export default class AdvertSignWrapper extends Component {
  shouldComponentUpdate() {
    return false
  }

  render() {
    return <AdvertSign />
  }
}

const AdvertSign = styled.div`
  width: 35px;
  height: 7px;
  display: none;
  position: absolute;
  right: 20px;
  bottom: -10px;
  background: url('/static/images/backgrounds/add-sign-horizontal.png') center no-repeat;
  z-index: 1;

  @media screen and (max-width: ${breakpoints.medium}) {
    width: 35px;
    height: 7px;
    top: -10px;
    right: 1px;
    left: inherit;
    background: url('/static/images/backgrounds/add-sign-horizontal.png') center no-repeat;
    z-index: inherit;
  }
`
