import styled from 'styled-components'
import theme from '../../client/theme'

export const HeaderInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
`

export const HeaderInfoItem = styled.li`
  font-weight: 500;
  font-size: ${theme.sizes.medium};
  color: ${theme.colors.selectionFontGrey};
  display: flex;
  align-items: center;
  padding: 0 8px;

  &:first-child {
    padding-left: 0;
  }
`

export const ImageChannel = styled.img`
  height: 40px;
  width: auto;
`
