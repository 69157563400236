import ReactDOM from 'react-dom'
import App from './App'
import { RecoilRoot } from 'recoil'
import initStore from './store/initStore'
import { Router } from 'wouter'

const rootElement = document.getElementById('root')
const renderFn = rootElement?.hasChildNodes() ? ReactDOM.hydrate : ReactDOM.render

const data = JSON.parse(window.__PRELOADED_STATE__ || String(null))
delete window.__PRELOADED_STATE__

renderFn(
  <Router>
    <RecoilRoot initializeState={initStore(data)}>
      <App />
    </RecoilRoot>
  </Router>,
  rootElement
)
