import styled from 'styled-components'
import theme from '../../client/theme'

export const StyledMainLogo = styled.a`
  overflow: hidden;
  display: block;
  cursor: pointer;
  order: 1;
  font-size: 0; // font-size > 0 increases height of logo

  @media screen and (min-width: ${theme.breakpoints.small}) {
    width: auto;
  }
`

export const StyledImage = styled.img`
  width: 113px;
  height: 28px;
`

export const MobileNavLogo = styled.a`
  justify-content: center;
  display: flex;
  padding: 16px 0;
`
