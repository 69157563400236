import React, { useEffect, useRef } from 'react'

const useFreshRef = <T>(value: T): React.MutableRefObject<T> => {
  const ref = useRef<T>(value)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref
}

export default useFreshRef
