import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import {
  END_VISIBLE_DATE,
  START_VISIBLE_DATE,
  VISIBLE_HOURS
} from './HorizontalBroadcast.constants'
import { formatUtcDate } from '../../../utils/date'
import { useFetchBroadcasts } from '../../../store/hooks/useFetchBroadcast'
import { IChannel } from '../../../types/entities'

const useHorizontalRefresher = (channels: IChannel[], initialDate?: string) => {
  const [loadedDates, setLoadedDates] = useState<string[]>(() => (initialDate ? [initialDate] : []))

  const handleSetSelectedDate = (date?: string, force?: boolean) => {
    if (!date || (!force && date === initialDate)) {
      return false
    }
    if (loadedDates.includes(date)) {
      return false
    }
    if (date < START_VISIBLE_DATE && date > END_VISIBLE_DATE) {
      return false
    }

    setLoadedDates((dates) => {
      if (dates.includes(date) || !date) {
        return dates
      }
      return dates.concat(date)
    })
    return true
  }

  useEffect(() => {
    handleSetSelectedDate(initialDate, true)
  }, [initialDate])

  useFetchBroadcasts(loadedDates, channels)

  const handleBoundsChange = useCallback(
    (canvasTimeStart: number, canvasTimeEnd: number) => {
      const leftDay = formatUtcDate(moment.utc(canvasTimeStart))
      const rightDay = formatUtcDate(moment.utc(canvasTimeEnd))

      if (rightDay !== leftDay) {
        handleSetSelectedDate(leftDay)
        handleSetSelectedDate(rightDay)
        return
      }

      const rightUpcomingDay = formatUtcDate(moment.utc(canvasTimeEnd).add(VISIBLE_HOURS, 'h'))
      if (rightDay !== rightUpcomingDay && handleSetSelectedDate(rightUpcomingDay)) {
        return
      }

      const leftUpcomingDay = formatUtcDate(
        moment.utc(canvasTimeStart).subtract(VISIBLE_HOURS, 'h')
      )
      if (leftDay !== leftUpcomingDay && handleSetSelectedDate(leftUpcomingDay)) {
        return
      }
    },
    [handleSetSelectedDate]
  )

  return { handleBoundsChange }
}

export default useHorizontalRefresher
