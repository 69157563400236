import { FooterLink, FooterLinks, List, ListItem } from './StyledLinksList'
import CmpInvokerLink from './CmpInvokerLink'

export const LinksList = () => (
  <FooterLinks>
    <List>
      <ListItem>
        <FooterLink
          href='https://www.economia.cz/o-nas/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          O nás
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://www.centrum.cz/sluzby/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Všechny služby
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://economia.jobs.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Volná místa
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://www.centrum.cz/agentura-najisto/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Služby firmám
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://www.economia.cz/smluvni-podminky-sluzby-centrum-cz-e-mail/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Všeobecné podmínky
        </FooterLink>
      </ListItem>
      <ListItem>
        <CmpInvokerLink />
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://www.economia.cz/prohlaseni-o-cookies/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Cookies
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://www.economia.cz/ochrana-osobnich-udaju/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Ochrana osobních údajů
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://www.economia.cz/zpracovani-osobnich-udaju/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Zpracování osobních údajů
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://freemail.help.economia.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Nápověda
        </FooterLink>
      </ListItem>
    </List>
  </FooterLinks>
)
