import theme from '../../client/theme'
import styled from 'styled-components'

export const Search = styled.label`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    justify-content: flex-end;
    margin-top: -28px;
    margin-right: 20px;
  }
`

export const ButtonSearch = styled.button`
  border: 1px solid ${theme.colors.searchBorder};
  background: ${theme.colors.default};
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  border-right: none;
  padding: 5px 5px 2px;
  cursor: pointer;
`

export const InputSearch = styled.input`
  border: 1px solid ${theme.colors.searchBorder};
  background: ${theme.colors.default};
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  border-left: none;
  outline: none;
  font-style: italic;
  font-weight: 400;
  font-size: ${theme.sizes.medium};
  line-height: 14px;
  color: rgba(51, 51, 51, 0.5);
`
