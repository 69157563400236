import { Sign, SignLabel } from './StyledTimeSign'
import { useState } from 'react'
import { formatStartTime } from '../../utils/date'
import useInterval from '../../hooks/useInterval'
import { StyledComponentPropsWithRef } from 'styled-components'

export const ControlledTimeSign = ({
  date,
  ...props
}: StyledComponentPropsWithRef<typeof Sign> & { date: Date | number }) => {
  return (
    <Sign {...props}>
      <SignLabel>{formatStartTime(new Date(date))}</SignLabel>
    </Sign>
  )
}

export const TimeSign = (props: StyledComponentPropsWithRef<typeof Sign>) => {
  const [date, setDate] = useState<Date>(new Date())

  useInterval(() => {
    setDate(new Date())
  }, 60 * 1000)

  return <ControlledTimeSign {...props} date={date} />
}
