import styled, { css, StyledComponentPropsWithRef } from 'styled-components'
import theme from '../../client/theme'
import ArrowUpIcon from '../Icons/ArrowUp'
import ArrowDownIcon from '../Icons/ArrowDown'

export const ArrowUp = (
  props: StyledComponentPropsWithRef<typeof Arrow> & { $hidden?: boolean }
) => (
  <Arrow {...props}>
    <ArrowUpIcon />
  </Arrow>
)

export const ArrowDown = (
  props: StyledComponentPropsWithRef<typeof Arrow> & { $hidden?: boolean }
) => (
  <Arrow {...props}>
    <ArrowDownIcon />
  </Arrow>
)

const Arrow = styled.button<{ $hidden?: boolean }>`
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  cursor: pointer;
  color: ${(props) => props.theme.colors.iconOrange};

  &:hover {
    opacity: 0.75;
  }

  ${({ $hidden }) =>
    $hidden &&
    css`
      visibility: hidden;
      pointer-events: none;
    `}

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    height: 35px;
  }
`
