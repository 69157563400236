import AppConfig from '../config'
import type { BroadcastingParamsType } from '../types/entities'
import moment, { isMoment, Moment } from 'moment'
import {
  DATE_START_HOURS_OFFSET,
  MAX_DAYS_FORWARD,
  MINIMAL_POSSIBLE_DATE,
  VISIBLE_HOURS,
  VISIBLE_HOURS_TODAY_OFFSET
} from '../components/Broadcast/Horizontal/HorizontalBroadcast.constants'
import type { BroadcastDateType } from '../store/broadcasts/broadcasts.atom'
import min from 'lodash/min'

export const formatDate = (dateObj: Date | Moment): string => {
  const date = isMoment(dateObj) ? dateObj.toDate() : dateObj
  return [
    date.getFullYear(),
    String(date.getMonth() + 1).padStart(2, '0'),
    String(date.getDate()).padStart(2, '0')
  ].join('-')
}

export const formatUtcDate = (dateObj: Date | Moment): string => {
  const date = isMoment(dateObj) ? dateObj.toDate() : dateObj
  return [
    date.getUTCFullYear(),
    String(date.getUTCMonth() + 1).padStart(2, '0'),
    String(date.getUTCDate()).padStart(2, '0')
  ].join('-')
}

export const dateToHomePageBroadcastingLink = (date: Date | Moment): string => {
  const desiredDate = formatUtcDate(date)
  const [year, month, day] = desiredDate.split('-')

  return dateToBroadcastingLink(
    desiredDate,
    AppConfig.routes.home,
    AppConfig.routes.broadcasting(year, month, day)
  )
}

export const dateToChannelGroupPageBroadcastingLink = (
  date: Date | Moment,
  slug: string
): string => {
  const desiredDate = formatUtcDate(date)
  const [year, month, day] = desiredDate.split('-')

  return dateToBroadcastingLink(
    desiredDate,
    AppConfig.routes.channelGroup(slug),
    AppConfig.routes.channelGroupBroadcasting(slug, year, month, day)
  )
}

const dateToBroadcastingLink = (
  desiredDate: string,
  currentLocation: string,
  destination: string
) => {
  const todayDate = formatUtcDate(new Date())

  if (desiredDate === todayDate) {
    return currentLocation
  }

  return destination
}

export const getToday = (): string => {
  const date = new Date()
  return formatDate(date)
}

export const addDays = (n: number) => {
  const date = moment().add(String(n), 'd')
  return formatDate(date)
}

export const getTomorrow = () => addDays(1)

export const formatStartTime = (date: Date | string) => {
  const parsedDate = new Date(date)
  return [parsedDate.getHours(), parsedDate.getMinutes()]
    .map((time) => String(time).padStart(2, '0'))
    .join(':')
}

export const minimalDate = moment.utc(MINIMAL_POSSIBLE_DATE).startOf('day')

export const isBroadcastDateValid = (date: string | Moment | Date): boolean => {
  const maximalDate = moment()
    .add(MAX_DAYS_FORWARD + 1, 'd')
    .endOf('day')

  const dateMoment = moment(date)
  return moment(date).isValid() && dateMoment.isBetween(minimalDate, maximalDate, 'days', '[]')
}

export const broadcastingParamsToDate = <T extends BroadcastingParamsType>(obj: T): string => {
  return [obj.year, obj.month, obj.day].join('-')
}

export const getClientTimezoneOffset = () => {
  return new Date().getTimezoneOffset()
}

export const getTodayTimes = (items: BroadcastDateType = {}) => {
  const selectedDate = moment.utc()
  const selectedDateStr = selectedDate.format()

  const visibleTimeStart: Moment =
    min(
      Object.values(items)
        .flat()
        .filter((item) => item.start <= selectedDateStr && item.stop > selectedDateStr)
        .map((item) => moment(item.start))
    ) || selectedDate.clone().subtract(VISIBLE_HOURS_TODAY_OFFSET, 'h')

  return {
    selectedDate: formatUtcDate(selectedDate),
    visibleHorizontalTimeStart: moment().valueOf(),
    visibleTimeStart: visibleTimeStart.valueOf(),
    visibleTimeEnd: visibleTimeStart.clone().add(VISIBLE_HOURS, 'h').valueOf()
  }
}

export const getDefaultDateTimes = (date: Moment, offsetHour = 0) => {
  const visibleTimeStart = date.clone().startOf('day').add(offsetHour, 'h')
  const visibleTimeEnd = visibleTimeStart.clone().add(VISIBLE_HOURS, 'h')

  return {
    selectedDate: formatUtcDate(date),
    visibleTimeStart: visibleTimeStart.valueOf(),
    visibleHorizontalTimeStart: visibleTimeStart.valueOf(),
    visibleTimeEnd: visibleTimeEnd.valueOf()
  }
}

export const getStartWithOffset = (day: moment.Moment) => {
  return day.clone().startOf('d').add(DATE_START_HOURS_OFFSET, 'h')
}
