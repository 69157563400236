import { FooterLink } from './StyledLinksList'

const CmpInvokerLink = () => {
  const handleClick = () => window?.Didomi?.preferences?.show()
  return (
    <FooterLink className='eco-cmp-show-settings' onClick={handleClick}>
      Nastavení soukromí
    </FooterLink>
  )
}

export default CmpInvokerLink
