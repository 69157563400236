import { useEffect, useRef, useState } from 'react'
import useWindowSize from '../../hooks/useWindowSize'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useModal } from '../../hooks/useModal'
import { ModalNames } from '../../store/ui/modals/modals.constants'

export const ScrollableContainers = {
  MOBILE_DATE_SELECT: 'mobile_date_select',
  MODAL_WINDOW: 'modal_window'
}

const useMobileNavigation = () => {
  const ref = useRef<HTMLDivElement>(null)
  const isMobile = !useWindowSize('large')
  const [isOpen, setOpen] = useState(false)
  const { openModal: openSettingsModal } = useModal(ModalNames.SETTINGS)

  useEffect(() => {
    if (!isMobile && ref.current) {
      setOpen(false)
      enableBodyScroll(ref.current)
    }
  }, [isMobile])

  useEffect(() => {
    if (!ref.current) {
      return
    }

    if (isOpen) {
      const scrollableIds = Object.values(ScrollableContainers)
      disableBodyScroll(ref.current, {
        reserveScrollBarGap: true,
        allowTouchMove: (_el) => {
          let el: HTMLElement | Element | null = _el
          while (el && el !== document.body) {
            if (scrollableIds.includes(el.id)) {
              return true
            }
            el = el.parentElement
          }
          return false
        }
      })
    } else {
      enableBodyScroll(ref.current)
    }

    return () => {
      if (ref?.current) {
        enableBodyScroll(ref.current)
      }
    }
  }, [isOpen])

  return {
    ref,
    isOpen,
    setOpen,
    openSettingsModal
  }
}

export default useMobileNavigation
