import styled from 'styled-components'

const StyledMainContent = styled.main`
  position: relative;
  margin: 0 auto;
  background: url('/static/images/backgrounds/main_bg.jpg') left top;
  background-size: cover;
  color: ${({ theme }) => theme.colors.default};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    max-width: ${({ theme }) => theme.sizes.mainContent};
  }
`

export const StyledHomepageMainContent = styled(StyledMainContent)`
  padding: 12px 15px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 24px 9px;
  }
`

export const StyledChannelGroupMainContent = styled(StyledMainContent)`
  padding: 12px 15px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 14px 16px;
  }
`

export const StyledChannelMainContent = styled(StyledMainContent)`
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  margin: 0;
  height: ${({ theme }) => `calc(100% - ${theme.sizes.headerHeightMobile}px)`};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: ${({ theme }) => `${theme.sizes.mainContentVerticalPadding} 16px`};
    height: auto;
  }
`

export const StyledListContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding-left: 0;
    height: auto;
    min-height: 300px;
    align-self: flex-start;
    flex-direction: column;
  }

  &:empty {
    display: none;
  }
`
