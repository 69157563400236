import styled from 'styled-components'
import { ListHeader } from '../Programs/ListHeader'
import { DesktopList } from '../Programs/DesktopList'
import { useFetchChannelTomorrowBroadcast } from '../../../store/hooks/useFetchBroadcast'
import { Times } from '../../../constants/times'

interface Props {
  broadcastProps: {
    channelId: string
    channelSlug: string
    onShowDetails: (slug: string) => void
  }
}

export const TomorrowBroadcast = ({ broadcastProps: { channelId, ...broadcastProps } }: Props) => {
  const { programmes, date } = useFetchChannelTomorrowBroadcast(channelId)

  return (
    <StyledContainer>
      <ListHeader title={Times.TOMORROW} date={date} />
      <DesktopList listProps={{ programmes, ...broadcastProps }} />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  flex: 1;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    flex: 0 0 233px;
  }
`
