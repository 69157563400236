import { selector, selectorFamily } from 'recoil'
import { StoreSelector } from '../storeStateName'
import { IProgramme } from '../../types/entities'
import { programmesState } from './programmes.atom'
import * as api from '../api'
import { isRunningProgramme } from '../../utils/programme'

export const programmeSelector = selectorFamily<IProgramme, IProgramme['slug']>({
  key: StoreSelector.PROGRAMME_DETAIL,
  get:
    (slug) =>
    ({ get }) => {
      const programme = get(programmesState)[slug]

      if (programme) {
        return programme
      }

      return api.fetchProgramme<IProgramme>(slug)
    }
})
export const runningProgrammeSelector = selector<IProgramme>({
  key: StoreSelector.PROGRAMME_DETAIL,
  get: ({ get }) => {
    const programmeDetails = get(programmesState)
    const programme = Object.values(programmeDetails).find(isRunningProgramme)

    return programme!
  }
})
