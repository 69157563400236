import { atom } from 'recoil'
import { StoreStateName } from '../storeStateName'
import { SettingsStateType } from '../types'
import {
  getLocalStorageSettings,
  saveLocalStorageSettings,
  updateCookieSettings
} from './localStorage.settings'

export const settingsState = atom<SettingsStateType>({
  key: StoreStateName.SETTINGS,
  default: getLocalStorageSettings(),
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((settings: SettingsStateType) => {
        updateCookieSettings()
        saveLocalStorageSettings(settings)
      })
    }
  ]
})
