import { FooterSectionMagazines } from './StyledSection'
import { FirstCol } from './StyledFirstCol'
import { SectionCentrum } from './FirstCol'
import { SecondCol } from './StyledSecondCol'
import { SectionFooterNav } from './SecondCol'

export const Section = () => (
  <FooterSectionMagazines>
    <FirstCol>
      <SectionCentrum />
    </FirstCol>
    <SecondCol>
      <SectionFooterNav />
    </SecondCol>
  </FooterSectionMagazines>
)
