import styled from 'styled-components'
import theme from '../../client/theme'

export const FooterLinks = styled.div`
  margin: 0 auto;
`

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
`

export const ListItem = styled.li`
  color: ${theme.colors.default};
  line-height: 25px;

  &:after {
    content: '|';
    margin: 0px 8px;
  }

  &:last-child:after {
    content: '';
  }
`

export const FooterLink = styled.a`
  font-weight: 300;
  color: ${theme.colors.default};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.buttonLightColor};
  }
`
