import { atom, selector } from 'recoil'
import { StoreStateName } from '../storeStateName'
import { ChannelsResponse } from '../../types/entities'
import * as api from '../api'

export const channelsState = atom<ChannelsResponse>({
  key: StoreStateName.CHANNELS,
  default: selector<ChannelsResponse>({
    key: `_${StoreStateName.CHANNELS}`,
    get: api.fetchChannels,
    // https://github.com/facebookexperimental/Recoil/issues/1840
    cachePolicy_UNSTABLE: {
      eviction: 'most-recent'
    }
  })
})
