import theme from '../../client/theme'
import styled from 'styled-components'

const Channel = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.channelTitleBackground};
  padding: 8px 15px;
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    position: sticky;
    z-index: ${theme.zindex.channel};
  }
`
export const VerticalChannel = styled(Channel)`
  width: 100%;
  height: auto;
  cursor: pointer;

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    top: 0;
  }
`
export const HorizontalChannel = styled(Channel)`
  width: 80px;
  height: 80px;
  cursor: pointer;

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    left: 0;
  }
`
