import { appState } from '../store/app/state/appState.atom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  broadcastingParamsToDate,
  formatUtcDate,
  getDefaultDateTimes,
  isBroadcastDateValid
} from '../utils/date'
import type { BroadcastingParamsType, ChannelGroupBroadcastingParamsType } from '../types/entities'
import { useRoute } from 'wouter'
import { useEffect } from 'react'
import AppConfig from '../config'
import moment from 'moment'
import { DATE_START_HOURS_OFFSET } from '../components/Broadcast/Horizontal/HorizontalBroadcast.constants'
import useSetNowBroadcasting from './useSetNowBroadcasting'
import { useWrappedRecoilValue } from './useWrappedRecoilValue'
import { settingsState } from '../store/settings/settings.atom'
import useSelectedDate from './useSelectedDate'
import { RootPageContentState } from '../store/test/rootPageContentState.atom'
import { prepareTimes } from '../components/Menu/TimeSelect'

const useDetectBroadcastingDate = () => {
  const setBroadcasting = useSetRecoilState(appState)
  const setNowBroadcasting = useSetNowBroadcasting()
  const rootPageContentState = useRecoilValue(RootPageContentState)
  const { setSelectedDate } = useSelectedDate()
  const { data: settings } = useWrappedRecoilValue(settingsState)

  const dateToSelect = getDateToSelect()

  useEffect(() => {
    if (!dateToSelect || !isBroadcastDateValid(dateToSelect)) {
      return
    }

    const isToday = moment(dateToSelect).isSame(moment(), 'day')

    if (isToday && settings) {
      setNowBroadcasting(settings.channelIds).then(() => {
        if (rootPageContentState.loadToday) {
          const now = moment().locale('cs')
          const times = prepareTimes(now)

          setSelectedDate(times)
        }
      })
    } else {
      setBroadcasting((oldState) => ({
        ...oldState,
        ...getDefaultDateTimes(
          moment(dateToSelect).startOf('d').add(DATE_START_HOURS_OFFSET, 'h'),
          DATE_START_HOURS_OFFSET
        )
      }))
    }
  }, [dateToSelect, settings])
}

const getDateToSelect = () => {
  const [homepageMatch] = useRoute(AppConfig.routes.home)
  const [channelGroupMatch] = useRoute(AppConfig.routes.channelGroup(':slug'))
  const [broadcastingMatch, broadcastingParams] = useRoute<BroadcastingParamsType>(
    AppConfig.routes.broadcasting(':year', ':month', ':day')
  )
  const [channelGroupBroadcastingMatch, channelGroupBroadcastingParams] =
    useRoute<ChannelGroupBroadcastingParamsType>(
      AppConfig.routes.channelGroupBroadcasting(':slug', ':year', ':month', ':day')
    )

  return homepageMatch || channelGroupMatch
    ? formatUtcDate(new Date())
    : broadcastingMatch
    ? broadcastingParamsToDate(broadcastingParams!)
    : channelGroupBroadcastingMatch
    ? broadcastingParamsToDate(channelGroupBroadcastingParams)
    : null
}

export default useDetectBroadcastingDate
