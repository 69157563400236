import { HeaderGroupButton } from '../StyledButtons'
import useSelectedDate from '../../hooks/useSelectedDate'
import moment, { Moment } from 'moment'
import 'moment/locale/cs'
import { inHoursRange } from '../../utils/interval'
import { formatDate, getStartWithOffset } from '../../utils/date'
import useSetNowBroadcasting from '../../hooks/useSetNowBroadcasting'
import { sendClickDateTimeEvent } from '../../analytics/ga4Events'
import { VISIBLE_HOURS } from '../Broadcast/Horizontal/HorizontalBroadcast.constants'
import { Times } from '../../constants/times'
import { TimeSelectContainer } from './TimeSelectContainer'
import { useSetRecoilState } from 'recoil'
import { RootPageContentState } from '../../store/test/rootPageContentState.atom'
import { useWrappedRecoilValue } from '../../hooks/useWrappedRecoilValue'
import { settingsState } from '../../store/settings/settings.atom'

interface TimesProps {
  visibleTimeStart: number
  selectedDate: string
  visibleHorizontalTimeStart: number
}

interface TimeSelectProps {
  onTimeSelectCallbacks: {
    onDateSelect: (date: string) => void
    onNowClick: () => void
  }
}

export const prepareTimes = (refDate: Moment): TimesProps => {
  const refStart = getStartWithOffset(refDate)

  return {
    selectedDate: formatDate(refStart),
    visibleTimeStart: refStart.valueOf(),
    visibleHorizontalTimeStart: refDate.clone().startOf('day').valueOf()
  }
}

const TimeSelect = ({ onTimeSelectCallbacks: { onDateSelect, onNowClick } }: TimeSelectProps) => {
  const { selectedDate: selectedDateRaw, visibleTimeStart, setSelectedDate } = useSelectedDate()
  const setNowBroadcasting = useSetNowBroadcasting()
  const { data: settings } = useWrappedRecoilValue(settingsState)
  const setRootPageContentState = useSetRecoilState(RootPageContentState)

  const now = moment().locale('cs')
  const selectedDate = moment.utc(selectedDateRaw)
  const yesterday = now.clone().subtract('1', 'd')
  const tomorrow = now.clone().add('1', 'd')

  const isYesterday = selectedDate.isSame(yesterday, 'days')
  const isToday = selectedDate.isSame(now, 'days')
  const isRightNow =
    isToday &&
    inHoursRange(moment.utc(visibleTimeStart).hours(), now.hours() - VISIBLE_HOURS, now.hours())
  const isTomorrow = selectedDate.isSame(tomorrow, 'days')

  return (
    <TimeSelectContainer>
      <HeaderGroupButton
        $isActive={isYesterday}
        onClick={() => {
          const times = prepareTimes(yesterday)

          sendClickDateTimeEvent('vcera')
          onDateSelect(times.selectedDate)
          setSelectedDate(times)
        }}
      >
        <span>{Times.YESTERDAY}</span>
      </HeaderGroupButton>
      <HeaderGroupButton
        $isActive={isRightNow}
        onClick={() => {
          sendClickDateTimeEvent('prave_ted')

          if (settings) {
            setRootPageContentState({ loadToday: false })
            setNowBroadcasting(settings.channelIds)
            onNowClick()
          }
        }}
      >
        <span>{Times.NOW}</span>
      </HeaderGroupButton>
      <HeaderGroupButton
        $isActive={isToday && !isRightNow}
        onClick={() => {
          const times = prepareTimes(now)

          sendClickDateTimeEvent('dnes')

          setRootPageContentState({ loadToday: true })
          setSelectedDate(times)
          onDateSelect(times.selectedDate)
        }}
      >
        <span>{Times.TODAY}</span>
      </HeaderGroupButton>
      <HeaderGroupButton
        $isActive={isTomorrow}
        onClick={() => {
          const times = prepareTimes(tomorrow)

          sendClickDateTimeEvent('zitra')
          onDateSelect(times.selectedDate)
          setSelectedDate(times)
        }}
      >
        <span>{Times.TOMORROW}</span>
      </HeaderGroupButton>
    </TimeSelectContainer>
  )
}

export default TimeSelect
