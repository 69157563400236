import styled from 'styled-components'
import { ProgramDetailsWrapper } from '../ProgramDetails/ProgramDetailsWrapper'
import useWindowSize from '../../../hooks/useWindowSize'
import { MobileBroadcast } from '../ProgramDetails/MobileBroadcast'
import React, { useCallback, useRef, useState } from 'react'
import { DesktopBroadcast } from '../ProgramDetails/DesktopBroadcast'
import { useFetchRunningProgramme } from '../../../store/hooks/useFetchRunningPorgramme'
import useResizeObserver from 'use-resize-observer'
import StyledProgramDetailsContainer from '../ProgramDetails/StyledProgramDetailsContainer'
import debounce from 'lodash/debounce'

interface Props {
  channelId: string
  channelSlug: string
}

export const Programs = ({ channelId, channelSlug }: Props) => {
  const { data } = useFetchRunningProgramme()
  const [selectedProgrammeSlug, setSelectedProgrammeSlug] = useState<string>('')
  const isDesktop = useWindowSize('medium')

  const detailWrapperRef = useRef<HTMLDivElement | null>(null)

  const { ref } = useResizeObserver<HTMLDivElement>({
    round: Math.floor,
    onResize: useCallback(
      debounce(({ height }) => {
        if (height && detailWrapperRef?.current) {
          detailWrapperRef.current!.style.maxHeight = `${height}px`
        }
      }, 100),
      []
    )
  })

  const BroadcastComponent = isDesktop ? DesktopBroadcast : MobileBroadcast

  return (
    <StyledContainer>
      {isDesktop && (
        <>
          <StyledProgramDetailsContainer ref={detailWrapperRef}>
            <ProgramDetailsWrapper slug={selectedProgrammeSlug || data?.slug || ''} />
          </StyledProgramDetailsContainer>
          <StyledNextContainer />
        </>
      )}
      <StyledBroadcastContainer ref={ref}>
        <BroadcastComponent
          broadcastProps={{
            channelId,
            channelSlug,
            onShowDetails: (slug) => setSelectedProgrammeSlug(slug)
          }}
        />
      </StyledBroadcastContainer>
    </StyledContainer>
  )
}

const StyledNextContainer = styled.div`
  flex: ${({ theme }) => `0 0 ${theme.sizes.channelPage.detailWidth}px`};
  margin-left: ${({ theme }) =>
    `-${theme.sizes.channelPage.detailWidth + theme.sizes.channelPage.columnGap}px`};
  background: ${({ theme }) => theme.colors.default};
`

const StyledContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => `${theme.sizes.channelPage.columnGap}px`};
  margin-top: 8px;
  min-height: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    height: auto;
  }
`

const StyledBroadcastContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex: 1;
    flex-direction: row;
    gap: ${({ theme }) => `${theme.sizes.channelPage.columnGap}px`};
  }
`
