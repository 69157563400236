import { BroadcastDateType, broadcastsState } from '../broadcasts/broadcasts.atom'
import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

export const useBroadcasts = (data: BroadcastDateType[] | undefined, dates: string[]) => {
  const setBroadcasts = useSetRecoilState(broadcastsState)

  useEffect(() => {
    if (data && dates) {
      setBroadcasts((state) => {
        const newState = { ...state }
        dates.forEach((key, index) => {
          newState[key] = data[index]
        })
        return newState
      })
    }
  }, [data, dates])
}
