import { GenresTypes } from '../../../constants/genres'
import { useProgrammeListItem } from '../../../hooks/useProgrammeListItem'
import AppConfig from '../../../config'
import { useCallback } from 'react'
import { useMouseLinkHandlers, useTouchLinkHandlers } from '../../../hooks/useLinkHandlers'
import { IProgramme } from '../../../types/entities'
import useQueryLocation from '../../../hooks/useQueryLocation'

type Programme = Pick<
  IProgramme,
  'slug' | 'title' | 'titleOriginal' | 'description' | 'genre' | 'start' | 'stop'
>

export const useListItem = (programme: Programme) => {
  const genre = GenresTypes[programme.genre]
  const { timeWatchedPercent, isRunning, remainingMinutes } = useProgrammeListItem(programme)
  const [, setLocation] = useQueryLocation()

  const href = AppConfig.routes.detail(programme.slug)

  const handleRedirect = useCallback(() => {
    setLocation(href)
  }, [href])

  const mouseLinkHandlers = useMouseLinkHandlers(handleRedirect)
  const touchLinkHandlers = useTouchLinkHandlers(handleRedirect)

  return {
    mouseLinkHandlers,
    touchLinkHandlers,
    timeWatchedPercent,
    isRunning,
    remainingMinutes,
    genre,
    href
  }
}
