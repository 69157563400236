import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  gap: 8px;
`

export const ButtonHeaderWrapper = styled.div`
  position: absolute;
  right: 35px;
  top: 53px;
  gap: 8px;
  display: flex;
`
