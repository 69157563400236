import theme from './client/theme'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet'
import { Modal } from './components/Modal'
import { ModalNames } from './store/ui/modals/modals.constants'
import { Settings } from './components/Settings'
import AppRouter from './AppRouter'
import Didomi from './components/Settings/Didomi'
import { SASIC_STAGE } from './constants'

export const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center, main,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  body, html {
    line-height: 1;
    font-size: 14px;
    font-family: 'Roboto', 'Arial CE', Arial, sans-serif;
    background: url('/static/images/backgrounds/main_bg.jpg') left top no-repeat;
    background-attachment: fixed;
    background-color: ${theme.colors.corporateBlue};
    background-size: cover;
    scrollbar-width: thin;
    scrollbar-color: ${theme.colors.corporateColorDark} ${theme.colors.corporateBlue};
  }
  /* Works on Chrome, Edge, and Safari */
  html::-webkit-scrollbar,
  body::-webkit-scrollbar,
  div::-webkit-scrollbar {
    width: 12px;
  }
  html::-webkit-scrollbar-corner,
  body::-webkit-scrollbar-corner,
  div::-webkit-scrollbar-corner{
    background: ${theme.colors.corporateBlue};
  }
  html::-webkit-scrollbar-track,
  body::-webkit-scrollbar-track,
  div::-webkit-scrollbar-track {
    background: ${theme.colors.corporateBlue};
  }
  html::-webkit-scrollbar-thumb,
  body::-webkit-scrollbar-thumb,
  div::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.scrollbar};
    border-radius: 20px;
    border: 3px solid ${theme.colors.corporateBlue};
  }
  audio,
  video {
    display: inline-block;
  }
  img {
    border-style: none;
  }
  strong {
    font-weight: bold;
  }
  a {
    cursor: pointer;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  button {
    font-family: inherit;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
  }
  textarea,
  input[type="text"],
  input[type="button"],
  input[type="submit"] {
    font-family: inherit;
    -webkit-appearance: none;
    border-radius: 0;
    margin: 0;
  }

  body > #cpex-skin {
    display: flex;
    justify-content: center;
    align-items: center;
    position: static !important;

    & > iframe {
      height: 210px !important;
    }
  }
`

const sasicSourceScript = `https://${SASIC_STAGE}-snowly-sasic.stdout.cz/dist/economia/sasic.min.js`
const alsSourceScript = `https://${SASIC_STAGE}-snowly-als.stdout.cz/dist/als.min.js`

const App = () => (
  <>
    <Helmet>
      <title>TV Programy</title>
      <meta name='description' content='TV Programy' />
      <meta name='theme-color' content='#033349' />
      <link rel='shortcut icon' type='image/x-icon' href='/static/images/favicon/favicon.ico' />
      <link rel='manifest' href='/manifest.json' />
      <link rel='preconnect' href='https://fonts.googleapis.com' />
      <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin='anonymous' />
      <link
        href='https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap'
        rel='stylesheet'
      />
      <script>
        {`
        /* NETMONITOR */
        var pp_gemius_identifier = 'Ajrgkub7PxU7KwRfx3Gg3rd8zZawhE8X6GaD9RquSDT.T7';
        var pp_gemius_use_cmp = true;
        function gemius_pending (i) {
          window[i] = window[i] || function () {
            var x = window[i + '_pdata'] = window[i + '_pdata'] || [];
            x[x.length] = arguments;
          };
        };
        gemius_pending('gemius_hit');
        gemius_pending('gemius_event');
        gemius_pending('pp_gemius_hit');
        gemius_pending('pp_gemius_event');
        (function (d, t) {
          try {
            var gt = d.createElement(t), s = d.getElementsByTagName(t)[0],
              l = 'http' + ((location.protocol == 'https:') ? 's' : '');
            gt.setAttribute('async', 'async');
            gt.setAttribute('defer', 'defer');
            gt.src = l + '://spir.hit.gemius.pl/xgemius.js';
            s.parentNode.insertBefore(gt, s);
          } catch (e) {
          }
        })(document, 'script');
        `}
      </script>
      <script>
        {`
        window.didomiOnReady = window.didomiOnReady || [];
        window.dataLayer = window.dataLayer || [];

        window.AlsOptions = window.AlsOptions || { tag: 'tvprogram' };
        window._sasic_queue = window._sasic_queue || [];
        window._sasic_queue.push(['group', { groupId: 'default' }]);
        window._sasic_queue.push([
          "page",
            {
              options: {
                site: "hp",
                area: "tvprogram",
                targets: {
                  ap: "sasic"
                  }
              },
              sasUrl: "https://a.centrum.cz/cent"
            },
          ],
          [
            'adobetag',
            'auto',
            {
              async: true
              }
          ],
          [
            "deferAfter",
            {
              deferAfterAction: "euConsetReady",
              timeout: 3000,
            }
          ]);
      `}
      </script>
      <script id={'sasic'} src={sasicSourceScript} async={true} />
      <script>
        {`document.getElementById('sasic').onload = function() {
          _sasic.init()
        }
      `}
      </script>
      <script type={'text/javascript'} src={'https://cdn.cpex.cz/cmp/v2/cpex-cmp-upgrade.min.js'} />
      <script id={'als'} src={alsSourceScript} async={true} />
      <script>
        {`document.getElementById('als').onload = function() {
          _feLogging.initLogging()
        }
      `}
      </script>
    </Helmet>
    <Didomi />
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <AppRouter />
      <Modal id={ModalNames.SETTINGS}>
        <Settings />
      </Modal>
    </ThemeProvider>
  </>
)

export default App
