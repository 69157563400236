import { useWrappedRecoilValue } from '../../../hooks/useWrappedRecoilValue'
import { ReactCalendarTimelineProps } from 'react-calendar-timeline'
import { allBroadcastSelector } from '../../../store/broadcasts/broadcasts.selectors'
import { HorizontalProgramListHeader } from '../../ProgramsList/Header'
import React, { useCallback, useMemo } from 'react'
import { IChannel, IProgramme } from '../../../types/entities'
import { ROW_HEIGHT } from './HorizontalBroadcast.constants'
import { HorizontalItem } from '../../ProgramsList/ListItem'
import omit from 'lodash/omit'

const useHorizontalBroadcast = (channels: IChannel[], onProgrammeClick: (slug: string) => void) => {
  const {
    data: { broadcasts }
  } = useWrappedRecoilValue(allBroadcastSelector(channels), {
    broadcasts: []
  })

  const groups: ReactCalendarTimelineProps['groups'] = useMemo(
    () =>
      channels.map((channel) => ({
        id: channel.id,
        title: <HorizontalProgramListHeader channel={channel} />,
        height: ROW_HEIGHT
      })),
    [channels]
  )

  const items: ReactCalendarTimelineProps['items'] = useMemo(
    () =>
      broadcasts.map((programme: IProgramme) => ({
        programme,
        id: programme.id,
        group: programme.channelId,
        title: programme.title,
        start_time: new Date(programme.start).getTime(),
        end_time: new Date(programme.stop).getTime(),
        canChangeGroup: false,
        className: 'item'
      })),
    [broadcasts]
  )

  const handleItemRender = useCallback(({ item, itemContext, getItemProps, getResizeProps }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()

    const itemLibraryProps = omit(
      getItemProps(item.itemProps),
      'onMouseDown',
      'onMouseUp',
      'onTouchEnd',
      'onTouchStart'
    )

    return (
      <div {...itemLibraryProps}>
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}

        <div
          className='rct-item-content'
          style={{
            height: `${itemContext.dimensions.height}`
          }}
        >
          <HorizontalItem
            programme={item.programme as unknown as IProgramme}
            onProgrammeClick={onProgrammeClick}
          />
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
      </div>
    )
  }, [])

  return {
    groups,
    items,
    handleItemRender
  }
}

export default useHorizontalBroadcast
