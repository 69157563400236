import styled from 'styled-components'
import { StyledContainer } from './StyledContainer'
import { HeaderCheckBox } from '../StyledButtons'
import { MenuSelect } from './Select'
import { MobileNavigation } from '../MobileNav/MobileNavigation'
import { useModal } from '../../hooks/useModal'
import { ModalNames } from '../../store/ui/modals/modals.constants'
import TimeSelect from './TimeSelect'
import DisplayModeButtons from './DisplayModeButtons'
import { isHorizontalLayoutForbidden } from '../../utils/layout'
import Tooltip from '../Tooltip'
import ToolsIcon from '../Icons/ToolsIcon'
import { OnlyDesktopWrapper } from '../deviceWrappers'
import useWindowSize from '../../hooks/useWindowSize'
import { useLocation } from 'wouter'
import { isPageChannel } from '../../utils/url'

interface Props {
  onTimeSelectCallbacks: {
    onDateSelect: (date: string) => void
    onNowClick: () => void
  }
}

export const Menu = ({ onTimeSelectCallbacks, onTimeSelectCallbacks: { onDateSelect } }: Props) => {
  const {
    openModal,
    state: { isOpened }
  } = useModal(ModalNames.SETTINGS)
  const isDesktop = useWindowSize('medium')

  const [location] = useLocation()

  return (
    <>
      {(isDesktop || !isPageChannel(location)) && (
        <StyledContainer>
          <MenuSelect onDateSelect={onDateSelect} />
          <TimeSelect onTimeSelectCallbacks={onTimeSelectCallbacks} />
          {!isHorizontalLayoutForbidden() && (
            <OnlyDesktopWrapper>
              <DisplayModeButtons />
            </OnlyDesktopWrapper>
          )}
          <Tooltip label={'Nastavení'}>
            <HeaderCheckBox onClick={openModal} $isActive={isOpened}>
              <ToolsIcon />
            </HeaderCheckBox>
          </Tooltip>
        </StyledContainer>
      )}
      <HeaderSearchPlaceholder />
      <MobileNavigation />
    </>
  )
}

const HeaderSearchPlaceholder = styled.div`
  margin-left: auto;
  order: 2;
`
