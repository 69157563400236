import styled from 'styled-components'
import Image from '../Image'

interface Props {
  channelId: string
  alt?: string
}

export const ChannelLogo = ({ channelId, alt }: Props) => (
  <StyledImg src={`/static/images/channels/ch_${channelId}.png`} alt={alt} />
)

const StyledImg = styled(Image)`
  width: 42px;
  height: 42px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 48px;
    height: 48px;
  }
`
