import styled, { css } from 'styled-components'

interface LayoutIconColumnProps {
  isActive?: boolean
}

const LayoutIconColumn = ({ isActive = false, ...props }: LayoutIconColumnProps) => {
  return (
    <StyledWrapper $isActive={isActive}>
      <svg
        width='19'
        height='16'
        viewBox='0 0 19 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4 6H1V13H4V6ZM11 6H8V13H11V6ZM11 1H8V3H11V1ZM18 6H15V13H18V6ZM18 1H15V3H18V1ZM4 1H1V3H4V1ZM12 0V4H7V0H12ZM0 5V14H5V5H0ZM7 5V14H12V5H7ZM14 5V14H19V5H14ZM19 0V4H14V0H19ZM0 0V4H5V0H0Z'
          fill='currentColor'
        />
      </svg>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.span<{ $isActive?: boolean }>`
  display: inline-flex;
  color: ${(props) => props.theme.colors.iconWhite};
  padding: 6px 8px;
  cursor: pointer;
  border-top-right-radius: ${(props) => props.theme.radius.default};
  border-bottom-right-radius: ${(props) => props.theme.radius.default};

  &:hover {
    color: ${(props) => props.theme.colors.iconOrange};
    background: ${(props) => props.theme.colors.iconWhite};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      background: ${(props) => props.theme.colors.iconOrange};
      color: ${(props) => props.theme.colors.iconWhite};

      &:hover {
        background: ${(props) => props.theme.colors.iconOrange};
        color: ${(props) => props.theme.colors.iconWhite};
      }
    `}
`

export default LayoutIconColumn
