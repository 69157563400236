import styled from 'styled-components'
import ExternalImage from '../Image/ExternalImage'

const StyledImage = styled(ExternalImage)`
  width: 100%;
  height: auto;
  margin: 0 0 23px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 191px;
    margin: 0 0 24px;
  }
`

export default StyledImage
