import React, { ReactNode, useEffect, useState } from 'react'

export const ClientOnly = ({ children }: { children: ReactNode }) => {
  const [showContent, setShowContent] = useState(false)

  useEffect(() => {
    setShowContent(true)
  }, [])

  if (showContent) {
    return <>{children}</>
  }
  return null
}
