import styled from 'styled-components'
import { HorizontalItemWrapper, StyledAnchor } from './StyledContainer'
import { Header } from './StyledHeader'
import { HorizontalLabel } from './StyledLabel'
import { HorizontalContent } from './StyledContent'
import { RemainingTime } from './StyledRemainingTime'
import { Heading4 } from '../../StyledHeadings'
import { IProgramme } from '../../../types/entities'
import { formatStartTime } from '../../../utils/date'
import { useListItem } from './useListItem'

interface Props {
  programme: Pick<
    IProgramme,
    'slug' | 'title' | 'titleOriginal' | 'description' | 'genre' | 'start' | 'stop'
  >
  onProgrammeClick: (slug: string) => void
}

export const HorizontalItem = ({ programme, onProgrammeClick }: Props) => {
  const { isRunning, remainingMinutes, genre } = useListItem(programme)

  return (
    <HorizontalItemWrapper $isActive={isRunning}>
      <StyledAnchor onClick={() => onProgrammeClick(programme.slug)}>
        <Header>
          {formatStartTime(programme.start)}{' '}
          {isRunning && <RemainingTime>zbývá {remainingMinutes} min</RemainingTime>}
        </Header>
        <HorizontalContent>
          <Heading>{programme.title || programme.titleOriginal}</Heading>
          {genre && <HorizontalLabel>{genre}</HorizontalLabel>}
        </HorizontalContent>
      </StyledAnchor>
    </HorizontalItemWrapper>
  )
}

const Heading = styled(Heading4)`
  white-space: nowrap;
  color: inherit;
`
