import styled from 'styled-components'
import theme from '../../client/theme'

export const SecondCol = styled.div`
  display: none;

  @media screen and (min-width: ${theme.breakpoints.large}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 4.5em;
  }
`

export const FooterTitle = styled.h4`
  font-weight: 500;
  font-size: ${theme.sizes.big};
  color: ${theme.colors.default};
  line-height: 35px;
`
