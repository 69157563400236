import moment from 'moment'
import 'moment/locale/cs'

export const MINIMAL_POSSIBLE_DATE = '2022-02-01'

export const SIDEBAR_WIDTH = 80
export const ROW_HEIGHT = 80

export const VISIBLE_HOURS = (() => {
  const isDesktop = typeof window === 'undefined' || window.innerWidth >= 981
  return isDesktop ? 6 : 2
})()

// When equal, no zoom is allowed
export const MIN_ZOOM_TIME = 60 * 60 * 1000 * VISIBLE_HOURS
export const MAX_ZOOM_TIME = MIN_ZOOM_TIME

export const VISIBLE_HOURS_TODAY_OFFSET = 1
export const VISIBLE_SCROLL_OVERFLOW_HOURS_OFFSET = 3
export const DATE_START_HOURS_OFFSET = 7

export const HEADER_LABEL_FORMAT = 'HH:mm'

export const STICKY_HEADER_CLASS_NAME = 'sticky'

export const MAX_DAYS_FORWARD = 7
export const MAX_DAYS_BACKWARD = moment()
  .endOf('day')
  .diff(moment.utc(MINIMAL_POSSIBLE_DATE), 'day')

export const END_VISIBLE_DATE = moment()
  .add(MAX_DAYS_FORWARD, 'd')
  .endOf('day')
  .format('yyyy-MM-DD')
export const END_VISIBLE_TIME = moment()
  .add(MAX_DAYS_FORWARD, 'day')
  .endOf('day')
  .add(VISIBLE_SCROLL_OVERFLOW_HOURS_OFFSET, 'h')
  .valueOf()

export const START_VISIBLE_DATE = moment()
  .subtract(MAX_DAYS_BACKWARD, 'day')
  .startOf('day')
  .format('yyyy-MM-DD')

export const START_VISIBLE_TIME = moment()
  .subtract(MAX_DAYS_BACKWARD, 'day')
  .startOf('day')
  .subtract(new Date().getTimezoneOffset(), 'm')
  .valueOf()
