import React, { ReactNode, memo, useLayoutEffect } from 'react'
import { sasicQueuePush, detectSasicDevice } from '../../utils/sasic'
import { ClientOnly } from '../ClientOnly'

export interface SasicAdvertProps {
  adKey?: number | string,
  id: string,
  template?: string,
  groupId?: string,
  children?: ReactNode,
  className?: string,
  type?: string,
  pos?: string | number,
  supertag: string,
  ab?: string
}

  const SasicAdvert = ({
                       id,
                       groupId = 'default',
                       template = 'hp',
                       className,
                       children,
                       pos,
                       supertag,
                       ab = 'tvprog',
                       adKey
                     }: SasicAdvertProps) => {

  useLayoutEffect(() => {
    const callback = (event: any) => {
      if (event.type === 'insert' && !event.is_empty) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        const advertLabelContainerNode = document.getElementById(id).parentNode.firstChild
        if (advertLabelContainerNode) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /* @ts-ignore */
          advertLabelContainerNode['style'].display = 'block'
        }
      }
    }

    const options = {
      pos,
      targets: {
        supertag,
        template,
        ab,
        device: detectSasicDevice()
      },
      callback
    }

    sasicQueuePush(
      ['position', { elementId: id, groupId, options }]
    )

    return () => {
      sasicQueuePush(['removePosition', id])
    }
  }, [])

  useLayoutEffect(() => {
    sasicQueuePush(
      ['loadPosition', { elementId: id, groupId, template }]
    )
  }, [adKey])

  return (
    <div id={id} className={className}>
      {children}
    </div>
  )
}

const SasicAdvertWrapper = (props: SasicAdvertProps) => (
  <ClientOnly>
    <SasicAdvert {...props} />
  </ClientOnly>
)

export default memo(
  SasicAdvertWrapper, (prevProps, nextProps) => prevProps.adKey === nextProps.adKey
)
