import type { SVGProps } from 'react'
import styled from 'styled-components'

const SearchIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <StyledWrapper>
      <svg
        width='21'
        height='21'
        viewBox='0 0 21 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <circle cx='8.5' cy='8.5' r='7.75' stroke='currentColor' strokeWidth='1.5' />
        <path
          d='M19.8649 17.7435C20.4507 18.3293 20.4507 19.279 19.8649 19.8648C19.2791 20.4506 18.3293 20.4506 17.7436 19.8648L13.0001 15.1213L15.1214 13L19.8649 17.7435Z'
          fill='currentColor'
        />
      </svg>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.span`
  color: ${(props) => props.theme.colors.iconSearch};
`

export default SearchIcon
