import styled from 'styled-components'
import theme from '../../client/theme'

export const CopyrightList = () => (
  <StyledCopyright>
    <StyledList>
      <StyledListItem>
        <StyledFooterLink
          href='https://www.centrum.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Centrum.cz
        </StyledFooterLink>
      </StyledListItem>
      <StyledListItem>
        <StyledFooterLink
          href='https://atlas.centrum.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Atlas.cz
        </StyledFooterLink>
      </StyledListItem>
      <StyledListItem>
        <StyledFooterLink
          href='https://volny.centrum.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Volny.cz
        </StyledFooterLink>
      </StyledListItem>
      <StyledListItem>1999 – {new Date().getFullYear()} © Economia, a.s.</StyledListItem>
    </StyledList>
  </StyledCopyright>
)

const StyledCopyright = styled.div`
  margin: 20px auto 10px;
`

const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  list-style: none;
`

const StyledListItem = styled.li`
  font-weight: 300;
  font-size: ${theme.sizes.medium};
  color: ${theme.colors.default};
  line-height: 25px;

  &:after {
    content: '|';
    margin: 0px 8px;
  }

  &:last-child:after {
    content: '';
  }
`

const StyledFooterLink = styled.a`
  font-weight: 500;
  color: ${theme.colors.default};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.buttonLightColor};
  }
`
