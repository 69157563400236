import React, { useEffect, useRef } from 'react'
import { IChannel } from '../../types/entities'
import { StyledListContainer } from '../Layout/MainContent/StyledMainContent'
import useKeyboardScroll from '../../hooks/useKeyboardScroll'
import dragscroll from '../../scripts/dragscroll-hybrid'
import { useRecoilValueLoadable } from 'recoil'
import { appState } from '../../store/app/state/appState.atom'
import AdChannelsButton from '../AdChannelsButton'
import styled from 'styled-components'
import theme from './../../client/theme'
import { VerticalChannelList } from './VerticalChannelList'

interface Props {
  channels: IChannel[]
  onProgrammeClick: (slug: string) => void
}

const VerticalBroadcast = ({ channels, onProgrammeClick }: Props) => {
  const {
    contents: { selectedDate, visibleHorizontalTimeStart }
  } = useRecoilValueLoadable(appState)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  useKeyboardScroll(scrollContainerRef)

  useEffect(() => {
    dragscroll.reset()
    return () => dragscroll.reset()
  }, [])

  if (!selectedDate) {
    return null
  }

  return (
    <StyledWrapper>
      <StyledAddWrapper>
        <AdChannelsButton isVertical={true} />
      </StyledAddWrapper>
      <StyledListContainer className={'dragscroll'} ref={scrollContainerRef}>
        <VerticalChannelList
          date={selectedDate}
          channels={channels}
          visibleHorizontalTimeStart={visibleHorizontalTimeStart}
          onProgrammeClick={onProgrammeClick}
        />
      </StyledListContainer>
    </StyledWrapper>
  )
}

export const StyledWrapper = styled.div`
  margin-top: 8px;

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    display: flex;
    align-items: flex-start;
    padding-left: 0;
  }
`

export const StyledAddWrapper = styled.div`
  @media screen and (min-width: ${theme.breakpoints.medium}) {
    transform: rotate(-90deg);
    width: 25px;
    position: relative;
    top: 55px;
  }
`

export default VerticalBroadcast
