import React, { ReactNode } from 'react'
import { useTheme } from 'styled-components'

interface Props {
  children: ReactNode
}

interface IContext {
  onDesktopRefCallback: (el: HTMLDivElement) => void
  onMobileRefCallback: (listContainer: HTMLDivElement, runningProgramme: HTMLDivElement) => void
}

export const ScrollContext = React.createContext<IContext>({
  onDesktopRefCallback: () => {},
  onMobileRefCallback: () => {}
})

export const ScrollContextProvider = ({ children }: Props) => {
  const { sizes } = useTheme()

  const onDesktopRefCallback = (() => {
    let alreadyScrolled = false

    return (node: HTMLDivElement) => {
      if (!alreadyScrolled) {
        alreadyScrolled = true
        const { top } = node.getBoundingClientRect()

        window.scrollTo({
          top: top + window.scrollY - sizes.headerHeightDesktop,
          behavior: 'smooth'
        })
      }
    }
  })()

  const onMobileRefCallback = (() => {
    let alreadyScrolled = false

    return (listContainer: HTMLDivElement, runningProgramme: HTMLDivElement) => {
      if (!alreadyScrolled) {
        alreadyScrolled = true

        listContainer.scrollTo({
          top: runningProgramme.offsetTop,
          behavior: 'smooth'
        })
      }
    }
  })()

  return (
    <ScrollContext.Provider value={{ onDesktopRefCallback, onMobileRefCallback }}>
      {children}
    </ScrollContext.Provider>
  )
}
