import theme from '../../../client/theme'
import styled from 'styled-components'

export const OrderContainer = styled.div`
  width: 100%;
  touch-action: none;
  scroll-margin-top: 110px;

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    scroll-margin-top: 0;
    width: 40%;
  }
`
