import styled from 'styled-components'
import { Moment } from 'moment'

interface Props {
  title: string
  date: Moment
}

export const ListHeader = ({ title, date }: Props) => (
  <StyledContainer>
    <StyledTitle>{title}</StyledTitle>
    <StyledDate>{date.format('D. M.')}</StyledDate>
  </StyledContainer>
)

const StyledContainer = styled.div`
  background: ${({ theme }) => theme.colors.corporateBlue};
  padding: 5px 8px;
`

const StyledTitle = styled.span`
  font-size: ${({ theme }) => theme.sizes.medium};
  font-weight: 500;
  line-height: 16px;
  margin-right: 5px;
`

const StyledDate = styled.span`
  font-size: ${({ theme }) => theme.sizes.medium};
  font-weight: 300;
  line-height: 16px;
`
