import { Programme } from './Programme'
import { IProgramme } from '../../../types/entities'

interface Props {
  listProps: {
    programmes: IProgramme[]
    channelSlug: string
    onShowDetails: (slug: string) => void
  }
  programmeCallbacks: {
    onFindRunningProgramme: (programme: HTMLDivElement) => void
    onFindFirstTomorrowProgramme: (programme: HTMLDivElement) => void
  }
}

export const List = ({ listProps: { programmes, ...listProps }, programmeCallbacks }: Props) => {
  return (
    <>
      {programmes.map((programme) => (
        <Programme
          key={programme.id}
          programmeProps={{
            programme,
            ...listProps
          }}
          programmeCallbacks={programmeCallbacks}
        />
      ))}
    </>
  )
}
