import { selector, selectorFamily, waitForAll } from 'recoil'
import { StoreSelector } from '../storeStateName'
import { BroadcastDateChannelType, BroadcastDateType, broadcastsState } from './broadcasts.atom'
import * as api from '../api'
import { IChannel, IProgramme } from '../../types/entities'

type ChannelBroadcastsDateSelectorProps = {
  date: string
  channelId: string
}

const sortedBroadcastsSelector = selector<BroadcastDateType[]>({
  key: StoreSelector.BROADCAST_LIST_SORTED,
  get: ({ get }) => {
    const byDays = get(broadcastsState)
    return Object.keys(byDays)
      .sort()
      .map((key) => byDays[key])
  }
})

export const broadcastSelector = selectorFamily<
  BroadcastDateType,
  { date: string; channelIds: string[] }
>({
  key: StoreSelector.BROADCAST_DATE,
  get:
    ({ date, channelIds }) =>
    ({ get }) => {
      const broadcasts = get(broadcastsState)
      const broadcast = broadcasts[date]

      if (broadcast) {
        return broadcast
      }

      return api.fetchBroadcasting(date, { channels: channelIds })
    }
})

export const broadcastsSelector = selectorFamily<
  BroadcastDateType[],
  { dates: string[]; channelIds: string[] }
>({
  key: StoreSelector.BROADCAST_LIST,
  get:
    ({ dates, channelIds }) =>
    ({ get }) =>
      get(waitForAll(dates.map((date) => broadcastSelector({ date, channelIds }))))
})

export const channelBroadcastsSelector = selectorFamily<
  BroadcastDateType[],
  ChannelBroadcastsDateSelectorProps[]
>({
  key: StoreSelector.BROADCAST_LIST,
  get:
    (props) =>
    ({ get }) =>
      get(waitForAll(props.map(channelBroadcastSelector)))
})

const channelBroadcastSelector = selectorFamily<
  BroadcastDateType,
  ChannelBroadcastsDateSelectorProps
>({
  key: StoreSelector.BROADCAST_DATE,
  get:
    ({ date, channelId }) =>
    ({ get }) => {
      const broadcasts = get(broadcastsState)

      if (broadcasts?.[date]?.[channelId]) {
        return broadcasts[date]
      }

      return api.fetchBroadcasting(date, {
        channels: [channelId]
      })
    }
})

export const allBroadcastSelector = selectorFamily<{ broadcasts: IProgramme[] }, IChannel[]>({
  key: StoreSelector.BROADCAST_ALL,
  get:
    (channels) =>
    ({ get }) => {
      const sortedBroadcastingDays = get(sortedBroadcastsSelector)

      const broadcastsWithChannels: [IChannel, BroadcastDateChannelType][] = channels.map(
        (channel) => [
          channel,
          sortedBroadcastingDays.flatMap((day) => day[channel.id]).filter(Boolean)
        ]
      )

      return {
        broadcasts: broadcastsWithChannels.flatMap(([, programmes]) => programmes)
      }
    }
})
