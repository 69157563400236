import styled from 'styled-components'
import theme from '../../client/theme'

interface Props {
  isLoading?: boolean
}

const Container = styled.div<Props>`
  margin: 0 4px 4px 0;
  flex-grow: 0;
  flex-shrink: 0;

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    width: 150px;
  }
`
export const VerticalLayoutContainer = styled(Container)`
  display: block;
`
export const HorizontalLayoutContainer = styled(Container)`
  display: inline-flex;
`

const Content = styled.ul<Props>`
  flex-wrap: nowrap;
  overflow: hidden;
  width: ${(props) => (props.isLoading ? '100%' : 'auto')};
  padding: 0;
  margin: 0;
`
export const VerticalContent = styled(Content)`
  display: flex;
  flex-direction: column;
`
export const HorizontalContent = styled(Content)`
  display: inline-flex;
  flex-direction: row;
`
