import theme from '../../client/theme'
import styled from 'styled-components'

export const Label = styled.label`
  position: relative;
  padding-left: 20px;
  cursor: pointer;
`

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  width: 0;
  height: 0;
  line-height: 0;
  cursor: pointer;
  display: none;

  & ~ span {
    position: absolute;
    display: block;
    left: -10px;
    top: -1px;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    border: 1px solid ${theme.colors.buttonLightColor};
    background: ${theme.colors.default};
    visibility: visible;
  }

  &:checked {
    position: relative;

    & ~ span {
      border-color: ${theme.colors.buttonLightColor};
      background: ${theme.colors.buttonLightColor};
    }
  }

  &:checked + span:before {
    content: '';
    background: url('/static/images/icons/checked_white.svg') no-repeat center center;
    height: 15px;
    width: 15px;
    position: absolute;
    top: 0;
    left: 0;
  }
}
`
