import styled from 'styled-components'

const StyledChannelList = styled.div`
  position: relative;
  display: flex;
`

export const StyledVerticalChannelList = styled(StyledChannelList)`
  flex-direction: row;
`
