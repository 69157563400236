import theme from '../../client/theme'
import styled from 'styled-components'

export const Overlay = styled.div`
  width: 100%;
  height: calc(100% - 1px);
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  z-index: ${theme.zindex.modal};
  -webkit-overflow-scrolling: touch;
`
