import Config, { ConfigType } from '../config'
import { ApiError } from './api.error'

export type EndpointType = ConfigType['endpoints'][keyof ConfigType['endpoints']]
export type EndpointFnType = (...args: any[]) => any

export const jsonFetchFactory = <G extends EndpointType>(endpoint: G) => {
  return async function <V>(
    ...params: G extends EndpointFnType ? Parameters<G> : unknown[]
  ): Promise<V> {
    const path = typeof endpoint === 'function' ? (endpoint as EndpointFnType)(...params) : endpoint

    const response = await fetch(`${Config.apiBaseUrl}${path}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })

    if (!response.ok) {
      const errText = await response.text()
      throw new ApiError(errText, response.status)
    }

    return response.json()
  }
}

export const fetchChannels = jsonFetchFactory(Config.endpoints.channels)
export const fetchChannelById = jsonFetchFactory(Config.endpoints.channelById)
export const fetchChannelBySlug = jsonFetchFactory(Config.endpoints.channelBySlug)
export const fetchChannelGroup = jsonFetchFactory(Config.endpoints.channelGroup)
export const fetchProgramme = jsonFetchFactory(Config.endpoints.programme)
export const fetchBroadcasting = jsonFetchFactory(Config.endpoints.broadcasting)
