import { Heading4, InfoItemContainer, InfoItemText, InfoItemTitle } from './StyledInfo'
import { IProgramme } from '../../types/entities'

interface Props {
  directors: Required<IProgramme>['director']
  className?: string
}

export const Directors = ({ directors, className }: Props) => (
  <InfoItemContainer className={className}>
    <InfoItemTitle>
      <Heading4>Režie:</Heading4>
    </InfoItemTitle>
    <InfoItemText>
      {directors.map((director) => `${director.firstName} ${director.lastName}`).join(', ')}
    </InfoItemText>
  </InfoItemContainer>
)
