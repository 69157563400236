import { IDidomiObject } from '@didomi/react'

export interface DidomiConsent {
  contentPersonalization?: boolean
  adsPersonalization?: boolean
  analytics?: boolean
  productResearch?: boolean
  hash?: string
}

// todo: change config for tv-programy purposes (discuss it with the analytics team)
export const didomiConfig = {
  vendorId: 'c:economiaa-ndEUAX3g',
  contentPersonalization: 'publishers-XWZ2b3AK',
  adsPersonalization: 'publishers-UPqtcgaE',
  analytics: 'publishers-Kqq3iPge',
  productResearch: 'publishers-jRQF2F4U',
  tagManager: {
    provider: 'gtm'
  }
} as const

const getDidomi = (): IDidomiObject | undefined => {
  if (window?.Didomi) {
    return window.Didomi
  }
}

export const getConsent = (): Omit<DidomiConsent, 'hash'> => {
  const didomi = getDidomi()
  const { contentPersonalization, adsPersonalization, productResearch, analytics } =
    didomiConfig
  return {
    contentPersonalization:
      didomi &&
      didomi
        .getUserStatus()
        .purposes.consent.enabled.includes(contentPersonalization as never),
    adsPersonalization:
      didomi &&
      didomi
        .getUserStatus()
        .purposes.consent.enabled.includes(adsPersonalization as never),
    analytics:
      didomi &&
      didomi
        .getUserStatus()
        .purposes.consent.enabled.includes(analytics as never),
    productResearch:
      didomi &&
      didomi
        .getUserStatus()
        .purposes.consent.enabled.includes(productResearch as never)
  }
}
