import theme from '../../../client/theme'
import styled from 'styled-components'

export const HeaderModal = styled.div`
  width: 100%;
  text-align: center;
  padding: 15px 0;
  position: sticky;
  top: 0;

  background: ${theme.colors.default};
  z-index: ${theme.zindex.header};

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    margin: -20px 0 20px;
  }
}
`

export const HeaderModalDetail = styled.div`
  width: 100%;
  text-align: left;
  padding: 15px 0 0;
  position: sticky;
  top: 0;

  background: ${theme.colors.default};
  z-index: ${theme.zindex.header};

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    margin: -20px 0 20px;
  }
}
`
