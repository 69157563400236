import theme from '../../../client/theme'
import styled from 'styled-components'

export const ListChannel = styled.ul`
  columns: 2;
  margin-top: 20px;

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    columns: 3;
    column-span: none;
  }
`

export const ListChannelItem = styled.li`
  padding: 5px 0;
  font-weight: normal;
  font-size: ${theme.sizes.medium};
  line-height: 20px;
  color: ${theme.colors.selectionFontColor};
  margin-left: 10px;

  &:only-child {
    display: inline-flex;
    width: 100%;
  }
`
