import { IProgramme } from '../../../types/entities'
import styled from 'styled-components'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { List } from './List'
import { useChangeLabelByScrollPosition } from '../../../hooks/useChangeLabelByScrollPosition'
import { ScrollContext } from '../../../context/Scroll.context'

export interface OnDayChangeCallbacks {
  onChangeToTomorrow: () => void
  onChangeToToday: () => void
}

interface Props {
  listProps: {
    programmes: IProgramme[]
    channelSlug: string
    onShowDetails: (slug: string) => void
  }
  onDayChangeCallbacks: OnDayChangeCallbacks
}

export const MobileList = ({ listProps, onDayChangeCallbacks }: Props) => {
  const [listContainer, setListContainer] = useState<HTMLDivElement>()
  const [runningProgramme, setRunningProgramme] = useState<HTMLDivElement>()
  const [firstTomorrowProgramme, setFirstTomorrowProgramme] = useState<HTMLDivElement>()

  const removeEventListenerRef = useRef<ReturnType<typeof useChangeLabelByScrollPosition>>()
  const { onMobileRefCallback } = useContext(ScrollContext)

  const containerRef = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setListContainer(node)
    }
  }, [])

  useEffect(() => {
    return () => {
      removeEventListenerRef.current?.()
    }
  }, [])

  useEffect(() => {
    if (listContainer && runningProgramme && firstTomorrowProgramme) {
      onMobileRefCallback(listContainer, runningProgramme)

      removeEventListenerRef.current = useChangeLabelByScrollPosition(
        listContainer,
        firstTomorrowProgramme,
        onDayChangeCallbacks
      )
    }
  }, [listContainer, runningProgramme, firstTomorrowProgramme])

  return (
    <Container ref={containerRef}>
      <List
        listProps={listProps}
        programmeCallbacks={{
          onFindRunningProgramme: (programme) => setRunningProgramme(programme),
          onFindFirstTomorrowProgramme: (programme) => setFirstTomorrowProgramme(programme)
        }}
      />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    overflow-y: auto;
  }
`
