export enum StoreStateName {
  CHANNELS = 'channels',
  CHANNEL = 'channel',
  CHANNEL_GROUP = 'channel/group',
  PROGRAMMES = 'programmes',
  SETTINGS = 'settings',
  BROADCAST = 'broadcast',

  UI_MODALS = 'ui/modals',
  APP_STATE = 'app/state'
}

export enum StoreSelector {
  CHANNELS_LIST = `channels/list`,
  CHANNEL = `channel`,

  PROGRAMME_LIST = `programme/list`,
  PROGRAMME_DETAIL = `programme/detail`,

  BROADCAST_DATE = `broadcast/date`,
  BROADCAST_LIST = `broadcast/list`,
  BROADCAST_LIST_SORTED = `broadcast/sorted`,
  BROADCAST_ALL = `broadcast/all`,

  SETTINGS_CHANNELS = `settings/channels`,

  UI_MODALS_SETTINGS = 'ui/modals/settings',
  UI_MODALS_ANY_OPENED = 'ui/modals/any-opened'
}
