import { SecondCol } from './StyledSecondCol'
import { NewsList } from './News'
import { MagazinesList } from './Magazines'
import { ServicesList } from './Services'
import { CatalogsList } from './Catalogs'
import { CommunicationList } from './Communication'

export const SectionFooterNav = () => (
  <SecondCol>
    <NewsList />
    <MagazinesList />
    <ServicesList />
    <CatalogsList />
    <CommunicationList />
  </SecondCol>
)
