import Select from 'react-select'
import { StyledSelectWrapper } from './StyledSelect'
import useMenuOptions, { IDateMenuOption } from './useMenuOptions'
import 'moment/locale/cs'
import { sendSelectDateMenuEvent } from '../../analytics/ga4Events'

interface Props {
  onDateSelect: (date: string) => void
}

export const MenuSelect = ({ onDateSelect }: Props) => {
  const { options, selectedOption, setSelectedDate } = useMenuOptions()

  return (
    <StyledSelectWrapper>
      <Select<IDateMenuOption>
        isSearchable={false}
        classNamePrefix={'react-select'}
        options={options}
        value={selectedOption}
        onChange={(option) => {
          if (option) {
            sendSelectDateMenuEvent(option.offsetFromToday)
            setSelectedDate({
              selectedDate: option.value
            })
            onDateSelect(option.value)
          }
        }}
      />
    </StyledSelectWrapper>
  )
}
