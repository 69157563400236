import { IProgramme } from '../../../types/entities'
import useWindowSize from '../../../hooks/useWindowSize'
import { useListItem } from '../../ProgramsList/ListItem/useListItem'
import styled from 'styled-components'
import { Paragraph } from '../../ProgramDetail/StyledContainer'
import { formatStartTime } from '../../../utils/date'
import { StyledVerticalLabel } from '../../ProgramsList/ListItem/StyledLabel'
import { Header } from '../../ProgramsList/ListItem/StyledHeader'
import { ProgressBar } from '../../ProgramsList/ListItem/StyledProgressBar'

interface Props {
  programme: IProgramme
}

export const ProgramContent = ({ programme }: Props) => {
  const { timeWatchedPercent, isRunning, genre } = useListItem(programme)

  const isMobile = !useWindowSize('medium')

  return (
    <>
      <StyledContainer>
        <StyledTime>{formatStartTime(programme.start)}</StyledTime>
        {genre && <StyledVerticalLabel>{genre}</StyledVerticalLabel>}
      </StyledContainer>
      <StyledTitle>{programme.title || programme.titleOriginal}</StyledTitle>
      {isRunning && isMobile && (
        <>
          <StyledDescription>{programme.description}</StyledDescription>
          <StyledLandingPageProgressBar $progress={timeWatchedPercent} />
        </>
      )}
    </>
  )
}

export const StyledTitle = styled.div`
  font-size: ${({ theme }) => theme.sizes.small};
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 5px 8px 0;
  line-height: 15px;
`

const StyledContainer = styled(Header)`
  align-items: center;
  padding: 12px 8px 0;
`

const StyledTime = styled.span`
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.smallest};
  line-height: 12px;
`

const StyledDescription = styled(Paragraph)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 42px;
  margin: 5px 0 10px;
  padding: 0 8px;
`

export const StyledLandingPageProgressBar = styled(ProgressBar)`
  &:before {
    height: ${({ $progress }) => ($progress > 0 ? '6px' : '0')};
  }
`
