import { IProgramme } from '../types/entities'
import moment, { Moment } from 'moment'
import useInterval from './useInterval'
import { useCallback, useEffect, useState } from 'react'
import useWindowFocus from './useWindowFocus'
import { isRunningProgramme } from '../utils/programme'

const INITIAL_STATE = {
  isRunning: false,
  timeWatchedPercent: 0,
  remainingMinutes: 0,
  elapsedMinutes: 0
}

const calculateRemainingTime = (now: Moment, start: Moment, stop: Moment): typeof INITIAL_STATE => {
  const totalMinutes = stop.diff(start, 'm')
  const remainingMinutes = stop.diff(now, 'm')
  const elapsedMinutes = totalMinutes - remainingMinutes
  const timeWatchedPercent = (elapsedMinutes / totalMinutes) * 100

  return {
    isRunning: remainingMinutes > 0,
    remainingMinutes,
    elapsedMinutes,
    timeWatchedPercent
  }
}

export const useProgrammeListItem = (programme: Pick<IProgramme, 'start' | 'stop'>) => {
  const [refreshInterval, setRefreshInterval] = useState<number | null>(1)
  const [timeState, setTimeState] = useState<typeof INITIAL_STATE>(INITIAL_STATE)

  const updateTimeState = useCallback(() => {
    const now = moment()
    const stop = moment(programme.stop)
    const hasPassed = now.isSameOrAfter(stop)

    if (hasPassed) {
      setRefreshInterval(null)
      return
    }

    const start = moment(programme.start)
    const willStart = now.isBefore(start)
    if (willStart) {
      setRefreshInterval(start.diff(now, 'ms'))
      return
    }

    if (isRunningProgramme(programme)) {
      setTimeState(calculateRemainingTime(now, start, stop))
      setRefreshInterval(60 * 1000)
    }
  }, [programme])

  const windowFocused = useWindowFocus()
  useEffect(() => {
    if (windowFocused) {
      updateTimeState()
    }
  }, [windowFocused])

  useEffect(() => {
    if (refreshInterval === null) {
      setTimeState(INITIAL_STATE)
    }
  }, [refreshInterval])

  useInterval(updateTimeState, refreshInterval)

  return timeState
}
