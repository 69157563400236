import styled from 'styled-components'

export interface ImageProps {
  src: string
  alt?: string
  className?: string
}

const Image = (props: ImageProps) => {
  const handleError = () => {
    // TODO: add logging
    // TODO: add fallback
    console.error(`Error loading image "${props.src}"`)
  }
  return <StyledImage {...props} onError={handleError} />
}

const StyledImage = styled.img`
  width: 100%;
  height: auto;
`

export default Image
