import React, { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'

interface TooltipProps {
  label: string
  children: ReactNode | ReactElement
}

const Tooltip = ({ label, children }: TooltipProps) => {
  return <StyledWrapper $label={label}>{children}</StyledWrapper>
}

const StyledWrapper = styled.div<{ $label: TooltipProps['label'] }>`
  position: relative;

  &:hover::before {
    font-family: ${({ theme }) => theme.fonts.default};
    display: inline-block;
    border-radius: 6px;
    padding: 8px;
    background-color: ${({ theme }) => theme.colors.corporateColorDark};
    color: ${({ theme }) => theme.colors.default};
    content: '${({ $label }) => $label}';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    position: absolute;
    width: auto;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;

    margin-bottom: 0;
    bottom: auto;
    top: 100%;
    content: '${({ $label }) => $label}';
    margin-top: 11px;
  }

  &:hover::after {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${({ theme }) => theme.colors.corporateColorDark};
    position: absolute;
    left: 50%;

    margin-bottom: 0;
    bottom: auto;
    transform: rotate(180deg) translate(-50%, 0);
    top: 100%;
    margin-left: -16px;
    margin-top: 4px;
    content: ' ';
  }
`

export default Tooltip
