import useQueryLocation from '../../hooks/useQueryLocation'
import { ProgramDetailWrapper } from './ProgramDetailWrapper'

interface Props {
  slug: string
}

export const HomepageProgramDetail = ({ slug }: Props) => {
  const [, setLocation] = useQueryLocation()

  return (
    <ProgramDetailWrapper
      slug={slug}
      onClose={(programmeDetail) => setLocation(programmeDetail.broadcastingLink)}
    />
  )
}
