import { useRecoilCallback } from 'recoil'
import { broadcastSelector } from '../store/broadcasts/broadcasts.selectors'
import { appState } from '../store/app/state/appState.atom'
import { formatUtcDate, getTodayTimes } from '../utils/date'
import moment from 'moment'

const useSetNowBroadcasting = () => {
  return useRecoilCallback(
    ({ snapshot, set }) =>
      async (channelIds: string[]) => {
        const release = snapshot.retain()
        const today = formatUtcDate(moment.utc())

        await snapshot
          .getPromise(broadcastSelector({ date: today, channelIds }))
          .then((data) =>
            set(appState, (oldState) => ({
              ...oldState,
              ...getTodayTimes(data)
            }))
          )
          .finally(release)
      },
    []
  )
}

export default useSetNowBroadcasting
