import { memo } from 'react'
import { ProgramDetails } from './ProgramDetails'
import { Heading2 } from '../../StyledHeadings'
import styled from 'styled-components'
import useProgramDetail from '../../ProgramDetail/useProgramDetail'

interface Props {
  slug: string
}

export const ProgramDetailsWrapper = memo(({ slug }: Props) => {
  const { data, statusMessage } = useProgramDetail(slug)

  if (statusMessage) {
    return (
      <StyledStatusMessageContainer>
        <Heading2>{statusMessage}</Heading2>
      </StyledStatusMessageContainer>
    )
  }

  return <ProgramDetails programme={data!} />
})

const StyledStatusMessageContainer = styled.div`
  padding: 16px 18px;
`
