import styled, { css } from 'styled-components'

interface ToolsIconProps {
  isActive?: boolean
}

const ToolsIcon = ({ isActive = false, ...props }: ToolsIconProps) => {
  return (
    <StyledWrapper $isActive={isActive}>
      <svg
        width='20'
        height='17'
        viewBox='0 0 20 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5 2V5.76389C5.61375 6.31321 6 7.1115 6 8C6 8.8885 5.61375 9.68679 5 10.2361V15C5 16.1046 4.10457 17 3 17C1.89543 17 1 16.1046 1 15V10.2361C0.386246 9.68679 0 8.8885 0 8C0 7.1115 0.386246 6.31321 1 5.76389V2C1 0.89543 1.89543 0 3 0C4.10457 0 5 0.895432 5 2ZM4 10.8293C3.68723 10.9398 3.35063 11 3 11C2.64937 11 2.31277 10.9398 2 10.8293V15C2 15.5523 2.44771 16 3 16C3.55229 16 4 15.5523 4 15V10.8293ZM4 5.17071C3.68723 5.06016 3.35063 5 3 5C2.64937 5 2.31277 5.06016 2 5.17071V2C2 1.44772 2.44771 1 3 1C3.55229 1 4 1.44772 4 2V5.17071ZM3 10C4.10457 10 5 9.10457 5 8C5 6.89543 4.10457 6 3 6C1.89543 6 1 6.89543 1 8C1 9.10457 1.89543 10 3 10Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12 13.2361V15C12 16.1046 11.1046 17 10 17C8.89543 17 8 16.1046 8 15V13.2361C7.38625 12.6868 7 11.8885 7 11C7 10.1115 7.38625 9.31321 8 8.76389V2C8 0.89543 8.89543 0 10 0C11.1046 0 12 0.895432 12 2V8.76389C12.6138 9.31321 13 10.1115 13 11C13 11.8885 12.6138 12.6868 12 13.2361ZM10 16C10.5523 16 11 15.5523 11 15V13.8293C10.6872 13.9398 10.3506 14 10 14C9.64937 14 9.31277 13.9398 9 13.8293V15C9 15.5523 9.44772 16 10 16ZM11 2C11 1.44772 10.5523 1 10 1C9.44771 1 9 1.44772 9 2V8.17071C9.31277 8.06016 9.64937 8 10 8C10.3506 8 10.6872 8.06016 11 8.17071V2ZM12 11C12 12.1046 11.1046 13 10 13C8.89543 13 8 12.1046 8 11C8 9.89543 8.89543 9 10 9C11.1046 9 12 9.89543 12 11Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19 15C19 16.1046 18.1046 17 17 17C15.8954 17 15 16.1046 15 15V7.23611C14.3862 6.68679 14 5.8885 14 5C14 4.1115 14.3862 3.31321 15 2.76389V2C15 0.89543 15.8954 0 17 0C18.1046 0 19 0.895432 19 2V2.76389C19.6138 3.31321 20 4.1115 20 5C20 5.8885 19.6138 6.68679 19 7.23611V15ZM17 8C17.3506 8 17.6872 7.93984 18 7.82929V15C18 15.5523 17.5523 16 17 16C16.4477 16 16 15.5523 16 15V7.82929C16.3128 7.93984 16.6494 8 17 8ZM18 2.17071V2C18 1.44772 17.5523 1 17 1C16.4477 1 16 1.44772 16 2V2.17071C16.3128 2.06015 16.6494 2 17 2C17.3506 2 17.6872 2.06015 18 2.17071ZM17 7C18.1046 7 19 6.10457 19 5C19 3.89543 18.1046 3 17 3C15.8954 3 15 3.89543 15 5C15 6.10457 15.8954 7 17 7Z'
          fill='currentColor'
        />
      </svg>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.span<{ $isActive?: boolean }>`
  display: inline-flex;
  color: ${(props) => props.theme.colors.iconWhite};
  padding: 6px 8px;
  cursor: pointer;
  border-radius: ${(props) => props.theme.radius.default};

  &:hover {
    color: ${(props) => props.theme.colors.iconOrange};
    background: ${(props) => props.theme.colors.iconWhite};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      background: ${(props) => props.theme.colors.iconOrange};
      color: ${(props) => props.theme.colors.iconWhite};

      &:hover {
        background: ${(props) => props.theme.colors.iconOrange};
        color: ${(props) => props.theme.colors.iconWhite};
      }
    `}
`

export default ToolsIcon
