import { useWrappedRecoilValue } from '../../hooks/useWrappedRecoilValue'
import { AdsWrapper } from '../../components/Layout/AdsWrapper'
import { Header } from '../../components/Layout/Header/Header'
import { StyledChannelGroupMainContent } from '../../components/Layout/MainContent/StyledMainContent'
import { Footer } from '../../components/Layout/Footer'
import { settingsState } from '../../store/settings/settings.atom'
import { BroadcastDisplayMode } from '../../constants'
import VerticalBroadcast, {
  StyledAddWrapper as VerticalStyledAddWrapper
} from '../../components/Broadcast/VerticalBroadcast'
import HorizontalBroadcast from '../../components/Broadcast/Horizontal/HorizontalBroadcast'
import { StyledAddWrapper as HorizontalStyledAddWrapper } from '../../components/Broadcast/Horizontal/HorizontalBroadcast.styles'
import Config from '../../config'
import useQueryLocation from '../../hooks/useQueryLocation'
import { Path } from 'wouter/types/use-location'
import { dateToChannelGroupPageBroadcastingLink } from '../../utils/date'
import moment from 'moment/moment'
import { channelGroupSelector } from '../../store/channels/channelGroup.selectors'
import styled from 'styled-components'
import { ChannelGroupHeader } from '../../components/LandingPage/Header/ChannelGroupHeader'
import { Helmet } from 'react-helmet'
import { BroadcastingParamsType, ChannelGroupResponse } from '../../types/entities'
import { removeLeadingZero } from '../../utils/string'
import { getFormattedChannelNames } from '../../utils/channel'

interface Props {
  groupSlug: string
  date?: BroadcastingParamsType
}

export const ChannelGroupPage = ({ groupSlug, date }: Props) => {
  const { data: channelGroup } = useWrappedRecoilValue(channelGroupSelector(groupSlug))
  const { data: settings } = useWrappedRecoilValue(settingsState)
  const [location, setLocation] = useQueryLocation()

  if (!channelGroup || !settings) {
    return null
  }

  const handleProgrammeClick = (programmeSlug: string) => {
    const path = Config.routes.channelGroupProgrammeDetail(groupSlug, programmeSlug)
    setLocation(path)
  }

  const navigateToDate = (
    date: string,
    location: string,
    setLocation: (path: Path, ...args: any[]) => any,
    slug: string
  ) => {
    const link = dateToChannelGroupPageBroadcastingLink(moment.utc(date), slug)

    if (location !== link) {
      setLocation(link)
    }
  }

  return (
    <>
      <AdsWrapper>
        <Header
          onTimeSelectCallbacks={{
            onDateSelect: (date) => navigateToDate(date, location, setLocation, groupSlug),
            onNowClick: () => setLocation(Config.routes.channelGroup(groupSlug))
          }}
        />
        <StyledChannelGroupMainContent>
          <ChannelGroupHeader name={channelGroup.name} />
          <Container>
            {settings.broadcastDisplayMode === BroadcastDisplayMode.VERTICAL ? (
              <VerticalBroadcast
                channels={channelGroup.channels}
                onProgrammeClick={handleProgrammeClick}
              />
            ) : (
              <HorizontalBroadcast
                channels={channelGroup.channels}
                onProgrammeClick={handleProgrammeClick}
              />
            )}
          </Container>
        </StyledChannelGroupMainContent>
        <Footer />
      </AdsWrapper>
      <ChannelGroupMetaTags date={date} channelGroup={channelGroup} />
    </>
  )
}

function ChannelGroupMetaTags({
  date,
  channelGroup
}: {
  date?: BroadcastingParamsType
  channelGroup: ChannelGroupResponse
}) {
  if (date) {
    const dayNoLeadingZero = removeLeadingZero(date.day)
    const monthNoLeadingZero = removeLeadingZero(date.month)
    const year = date.year

    return (
      <Helmet>
        <title>
          {`TV Program ${channelGroup.name} na ${dayNoLeadingZero}.${monthNoLeadingZero}.${year}`}
        </title>
        <meta
          name={'description'}
          content={`Televizní program ${
            channelGroup.name
          } na ${dayNoLeadingZero}. ${monthNoLeadingZero}. ${year}. Přehledný televizní program pro stanice ${getFormattedChannelNames(
            channelGroup.channels
          )}.`}
        />
      </Helmet>
    )
  } else {
    return (
      <Helmet>
        <title>{`${channelGroup.name} - TV Program`}</title>
        <meta
          name={'description'}
          content={`Televizní program ${
            channelGroup.name
          }. Přehledný televizní program pro stanice ${getFormattedChannelNames(
            channelGroup.channels
          )} na týden dopředu i zpětně.`}
        />
      </Helmet>
    )
  }
}

const Container = styled.div`
  ${VerticalStyledAddWrapper}, ${HorizontalStyledAddWrapper} {
    display: none;
  }
`
