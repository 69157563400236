import noop from 'lodash/noop'

let dragscroll = {
  reset: noop
}
if (typeof window !== 'undefined') {
  dragscroll = require('dragscroll')
}

export default dragscroll
