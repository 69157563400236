import { useFetchBroadcastWithOffset } from '../../store/hooks/useFetchBroadcast'
import { IChannel } from '../../types/entities'
import { StyledVerticalChannelList } from '../ProgramsList/StyledChannelList'
import { VerticalProgramList } from '../ProgramsList'
import React from 'react'

interface Props {
  date: string
  channels: IChannel[]
  visibleHorizontalTimeStart: number
  onProgrammeClick: (slug: string) => void
}

export function VerticalChannelList({
  date,
  channels,
  visibleHorizontalTimeStart,
  onProgrammeClick
}: Props) {
  const { channelProgrammes, statusMessage, loading } = useFetchBroadcastWithOffset(
    date,
    channels,
    visibleHorizontalTimeStart
  )

  return (
    <StyledVerticalChannelList>
      {channels.map((channel) => (
        <VerticalProgramList
          key={channel.id}
          broadcast={{ channel, programmes: channelProgrammes[channel.id] }}
          status={{ statusMessage, loading }}
          onProgrammeClick={onProgrammeClick}
        />
      ))}
    </StyledVerticalChannelList>
  )
}
