export const isMobileUserAgent = (userAgent: string) =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)

export const isHorizontalLayoutForbidden = () => {
  if (typeof navigator !== 'undefined') {
    const { userAgent } = navigator
    return isMobileUserAgent(userAgent)
  }

  return false
}
