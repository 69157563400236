import { Container, Content, Paragraph } from './StyledContainer'
import { Heading1 } from '../StyledHeadings'
import { HeaderInfo, HeaderInfoItem, ImageChannel } from './StyledHeaderInfo'
import { Info } from './StyledInfo'
import { ClosingCross } from '../Modal/ClosingModal'
import styled from 'styled-components'
import { IProgramme } from '../../types/entities'
import { OnlyDesktopWrapper, OnlyMobileWrapper } from '../deviceWrappers'
import SasSpecial2 from '../Ads/SasSpecial2'
import { HeaderModalDetail } from '../Settings/Order/StyledHeaderModal'
import { ButtonContainer, ButtonHeaderWrapper } from './StyledButtonContainer'
import { DefaultButton } from '../StyledButtons'
import { isNonEmptyArray } from '../../utils/array'
import ProgramShareButton from './ProgramShareButton'
import { ClientOnly } from '../ClientOnly'
import Image from './StyledImage'
import { GenresTypes } from '../../constants/genres'
import CalendarIcon from '../Icons/CalendarIcon'
import { Actors } from './Actors'
import { Directors } from './Directors'

export interface ProgramDetailProps {
  programme: IProgramme
  addToCalendarLink: string
  onClose: () => void
}

export const ProgramDetail = ({ programme, addToCalendarLink, onClose }: ProgramDetailProps) => (
  <Container>
    <HeaderModalDetail>
      <ClosingCross onClick={onClose} />
      <Heading>{programme.title}</Heading>
      <HeaderInfo>
        <HeaderInfoItem>
          <ImageChannel
            src={`/static/images/channels/ch_${programme.channelId}_white.png`}
            alt='Logo'
          />
        </HeaderInfoItem>
        <HeaderInfoItem>
          {[`${programme.durationMin} minut`, GenresTypes[programme.genre]]
            .filter(Boolean)
            .join(' | ')}
        </HeaderInfoItem>
      </HeaderInfo>
      <OnlyDesktopWrapper>
        <ButtonHeaderWrapper>
          <DefaultButton
            as={'a'}
            href={addToCalendarLink}
            target={'_blank'}
            rel={'noreferrer noopener'}
          >
            <CalendarIcon />
            Do kalendáře
          </DefaultButton>
        </ButtonHeaderWrapper>
      </OnlyDesktopWrapper>
    </HeaderModalDetail>
    <Content>
      <div>
        {programme.description && <Paragraph>{programme.description}</Paragraph>}
        {programme.descriptionLong && <Paragraph>{programme.descriptionLong}</Paragraph>}
        {!programme.description && !programme.descriptionLong && (
          <Paragraph>Žádné další informace nejsou k dispozici</Paragraph>
        )}
        <Info>
          {isNonEmptyArray(programme.director) && <Directors directors={programme.director} />}
          {isNonEmptyArray(programme.actor) && <Actors actors={programme.actor} />}
        </Info>
      </div>
      <div>
        {programme.images.slice(0, 1).map((name: string) => (
          <Image key={name} name={name} />
        ))}
        <OnlyMobileWrapper>
          <ButtonContainer>
            <ClientOnly>
              <ProgramShareButton programme={programme} />
            </ClientOnly>
            <DefaultButton
              as={'a'}
              href={addToCalendarLink}
              target={'_blank'}
              rel={'noreferrer noopener'}
            >
              <CalendarIcon />
              Do kalendáře
            </DefaultButton>
          </ButtonContainer>
        </OnlyMobileWrapper>
      </div>
    </Content>
    <OnlyDesktopWrapper>
      <SasSpecial2 targetId='sas-special2' />
    </OnlyDesktopWrapper>
  </Container>
)

export const Heading = styled(Heading1)`
  margin-top: 40px;
  color: ${({ theme }) => theme.colors.corporateBlue};
`
