import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react'
import { isMobile, isDesktop } from '../utils/sasic'

interface DeviceWrapperProps {
  check: () => boolean
}

const DeviceWrapper: FunctionComponent<DeviceWrapperProps> = ({ children, check }) => {
  const [willRender, setWillRender] = useState<boolean>(false)

  useEffect(() => {
    const hasPassed = typeof window !== 'undefined' && check()
    setWillRender(hasPassed)
  }, [])

  return willRender ? (children as ReactElement) : null
}

export const OnlyMobileWrapper: FunctionComponent = (props) => (
  <DeviceWrapper check={isMobile} {...props} />
)
export const OnlyDesktopWrapper: FunctionComponent = (props) => (
  <DeviceWrapper check={isDesktop} {...props} />
)
