import styled from 'styled-components'
import theme from '../../client/theme'

export const FooterLink = styled.a`
  font-weight: 400;
  color: ${theme.colors.default};
  font-size: ${theme.sizes.medium};
  text-decoration: none;
  line-height: 25px;

  &:hover {
    color: ${theme.colors.buttonLightColor};
  }
`
