import theme from '../../../client/theme'
import styled from 'styled-components'

export const FooterModal = styled.div`
  margin: 26px auto 0;
  padding-top: 15px;
  position: sticky;
  align-self: flex-end;
  bottom: 0;
  background: linear-gradient(0deg, ${theme.colors.default} 80%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`
