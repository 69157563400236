export const GenresTypes = {
  '1': 'zábava',
  '2': 'sport',
  '3': 'film',
  '4': 'seriál',
  '5': 'dokument',
  '7': 'hudba',
  '8': 'pořad pro děti',
  '9': 'zprávy'
} as const
