import { selectorFamily } from 'recoil'
import { StoreSelector } from '../storeStateName'
import { IChannel } from '../../types/entities'
import { channelsState } from './channels.atom'
import { isNotUndefined } from '../../utils/type'

export const channelsSelector = selectorFamily<IChannel[], string[] | undefined>({
  key: StoreSelector.CHANNELS_LIST,
  get:
    (channelIds) =>
    ({ get }) => {
      const channels = Object.values(get(channelsState))

      if (!channelIds) {
        return channels
      }

      return channelIds.map((id) => channels.find((c) => c.id === id)).filter(isNotUndefined)
    }
})
