import { HorizontalChannel, VerticalChannel } from './StyledChannel'
import { IChannel } from '../../types/entities'
import styled from 'styled-components'
import { Link } from 'wouter'
import AppConfig from '../../config'
import { ChannelLogo } from '../Logos/ChannelLogo'

interface Props {
  channel: IChannel
}

export const VerticalProgramListHeader = ({ channel }: Props) => {
  return (
    <Link to={AppConfig.routes.channel(channel.slug)}>
      <VerticalChannel>
        <Logo channelId={channel.id} alt={'Program logo'} />
      </VerticalChannel>
    </Link>
  )
}

export const HorizontalProgramListHeader = ({ channel }: Props) => {
  return (
    <Link to={AppConfig.routes.channel(channel.slug)}>
      <HorizontalChannel>
        <Logo channelId={channel.id} alt={'Program logo'} />
      </HorizontalChannel>
    </Link>
  )
}

const Logo = styled(ChannelLogo)`
  &&& {
    width: 48px;
    height: 48px;
  }
`
