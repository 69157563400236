import styled from 'styled-components'
import theme from '../../../client/theme'

export const StyledWrapper = styled.div`
  & .react-calendar-timeline {
    & .rct-sidebar {
      border: none;

      & .rct-sidebar-row {
        padding: 0;
        border-color: transparent;
        border-width: 5px;
        height: 80px!important;
        line-height: normal!important;
      }
    }
    & .rct-header-root,
    & .rct-calendar-header {
      border-color: transparent;
      background: none;
      color: ${theme.colors.time};
      font-weight: 500;
      align-items: center;
      height: 30px;
    }

    & .rct-calendar-header {
      pointer-events: none;
    }

    & .rct-dateHeader {
      background-color: transparent;
      border: none;
      justify-content: flex-start;
    }

    & .rct-vertical-lines,
    & .rct-horizontal-lines {
      display: none;
    }

    & .rct-scroll {
      touch-action: manipulation;

      & .rct-hl {
        background: none !important;
        border: none!important;
        height: 80px!important;
      }

      & .rct-item {
        background: none !important;
        border: none!important;
        border-bottom: 5px solid transparent!important;
        height: 80px!important;

        &:hover {
          z-index: 100!important;
        }
      }
    }

    & .react-calendar-timeline .rct-dateHeader {
      justify-content: flex-start;
    }

    & .rct-item .rct-item-content {
      width: 100%;
      padding: 0 3px;

      &:hover {
        width: auto;
        min-width: 100%;
      }
    }
  }
`

export const StyledAddWrapper = styled.div`
  align-self: flex-end;
`
