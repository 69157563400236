import styled, { css } from 'styled-components'

const button = css`
  font-weight: 500;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.sizes.medium};
  line-height: 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.default};
  background: ${({ theme }) => theme.colors.buttonLightColor};
  padding: 5px 10px;
  border-radius: ${({ theme }) => theme.radius.default};
  cursor: pointer;
  text-decoration: none;
`

export const DefaultButton = styled.button`
  ${button};
`

export const ButtonLink = styled.a`
  ${button};
`

export const GroupButtonAnchor = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 0;
  }
`

export const Anchor = styled.a`
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.medium};
  line-height: 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.buttonLightColor};
  background: ${({ theme }) => theme.colors.default};
  padding: 5px 10px;
  border: 1px solid ${({ theme }) => theme.colors.buttonLightColor};
  border-radius: ${({ theme }) => theme.radius.default};
  cursor: pointer;
  text-decoration: none;
  display: block;

  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

export const GroupButton = styled.button<{ $isActive?: boolean }>`
  position: relative;
  margin-right: 2px;

  ${button};
  border-radius: 0;
  background: ${({ $isActive, theme }) => ($isActive ? theme.colors.buttonLightColor : 'inherit')};
  color: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.buttonLightColor : theme.colors.default};

  &:first-child {
    border-top-left-radius: ${({ theme }) => theme.radius.default};
    border-bottom-left-radius: ${({ theme }) => theme.radius.default};
  }

  &:last-child {
    margin-right: 0;
    border-top-right-radius: ${({ theme }) => theme.radius.default};
    border-bottom-right-radius: ${({ theme }) => theme.radius.default};
  }
`

export const HeaderGroupButton = styled(GroupButton)<{ $isActive?: boolean }>`
  width: 74px;
  padding: 5px;
  background: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.buttonLightColor : theme.colors.buttonColor};
  border: ${({ $isActive, theme }) =>
    $isActive
      ? `1px solid ${theme.colors.buttonLightColor}`
      : `1px solid ${theme.colors.buttonColor}`};
  color: ${({ theme }) => theme.colors.default};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xSmall}) {
    width: 82px;
  }
`

export const HeaderIconGroupButton = styled(GroupButton)<{ $isActive?: boolean }>`
  display: none;
  position: relative;
  padding: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    display: block;
    background: ${({ $isActive, theme }) =>
      $isActive ? theme.colors.buttonLightColor : theme.colors.buttonColor};
    color: ${({ theme }) => theme.colors.default};
  }
`

export const HeaderCheckBox = styled(HeaderIconGroupButton)`
  display: none;
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    display: block;
    margin-right: 15px;
  }
`

export const AddButton = styled.button<{ isVertical?: boolean }>`
  border-top-left-radius: ${(props) => (props.isVertical ? '4px' : '0px')};
  border-top-right-radius: 4px;
  background: ${({ theme }) => theme.colors.corporateBlue};
  color: ${({ theme }) => theme.colors.buttonLightColor};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 80px;
  height: 21px;
  margin: 0 0 4px;
  border: none;
`
