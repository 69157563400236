import { GenresTypes } from '../constants/genres'

export type GenreMap = Record<number, string>

export interface IProgramme {
  id: string
  slug: string
  channelId: string
  start: string
  stop: string
  premiere: boolean
  tip: boolean
  title: string
  titleOriginal?: string
  description?: string
  descriptionLong?: string
  durationMin: number
  genre: keyof typeof GenresTypes
  seriesId?: number
  soundId?: number
  director?: Array<{
    firstName: string
    lastName: string
  }>
  actor: Array<{
    firstName: string
    lastName: string
  }>
  year?: number
  country?: Array<string>
  images: Array<string>
  originalImage?: string
}

export interface IChannelRaw {
  id: string
  name: string
  shortcut: string
}

export enum ChannelCategory {
  CZECH = 'zakladni-ceske',
  MOVIE_SERIALS = 'filmy-a-serialy',
  DOCUMENTS_STYLE = 'dokumenty-a-styl',
  SPORT = 'sport',
  NEWS = 'zpravodajstvi',
  KIDS = 'detske',
  MUSIC = 'hudba',
  SLOVAKIA = 'slovenske',
  EROTIC = 'eroticke'
}

export type IChannel = {
  id: IChannelRaw['id']
  name: IChannelRaw['id']
  category: ChannelCategory
  slug: string
}

export type ChannelsResponse = Record<IChannelRaw['id'], IChannel>

export type ChannelResponse = IChannel

export interface IChannelGroup {
  name: string
  channelIds: string[]
}

export type ChannelGroupResponse = {
  name: string
  channels: IChannel[]
}

export interface EDPOutput {
  channels: IChannelRaw[]
  programmes: Record<IProgramme['id'], Array<IProgramme>>
}

export type MArray<T> = Array<T> | ReadonlyArray<T>

export type BroadcastingParamsType = { year: string; month: string; day: string }

export type ChannelGroupBroadcastingParamsType = BroadcastingParamsType & { slug: string }

export type ChannelGroupProgrammeDetailParamsType = {
  channelGroupSlug: string
  programmeSlug: string
}

export type ChannelGroupParamsType = {
  slug: string
}

export type ChannelProgrammeDetailParamsType = {
  channelSlug: string
  programmeSlug: string
}

export type ChannelParamsType = {
  slug: string
}

export type ProgrammeDetailParamsType = {
  slug: string
}
