import React, { ReactNode } from 'react'
import {
  HorizontalContent,
  HorizontalLayoutContainer,
  VerticalContent,
  VerticalLayoutContainer
} from './StyledContainer'
import { HorizontalProgramListHeader, VerticalProgramListHeader } from './Header'
import {
  HorizontalPlaceholderListItem,
  VerticalPlaceholderListItem
} from './ListItem/StyledContainer'
import { IChannel } from '../../types/entities'
import { WrappedRecoilValueReturnType } from '../../hooks/useWrappedRecoilValue'

interface Props {
  channel: IChannel
  loading: WrappedRecoilValueReturnType<null>['loading']
  statusMessage: WrappedRecoilValueReturnType<null>['statusMessage']
  children: ReactNode
}

export const VerticalContainer = ({ channel, loading, statusMessage, children }: Props) => {
  return (
    <VerticalLayoutContainer>
      <VerticalProgramListHeader channel={channel} />
      <VerticalContent isLoading={loading}>
        {children}
        {statusMessage && (
          <VerticalPlaceholderListItem>
            <span>{statusMessage}</span>
          </VerticalPlaceholderListItem>
        )}
      </VerticalContent>
    </VerticalLayoutContainer>
  )
}

export const HorizontalContainer = ({ channel, loading, statusMessage, children }: Props) => {
  return (
    <HorizontalLayoutContainer>
      <HorizontalProgramListHeader channel={channel} />
      <HorizontalContent isLoading={loading}>
        {children}
        {statusMessage && (
          <HorizontalPlaceholderListItem>
            <span>{statusMessage}</span>
          </HorizontalPlaceholderListItem>
        )}
      </HorizontalContent>
    </HorizontalLayoutContainer>
  )
}
