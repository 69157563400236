import { FooterContainer } from './StyledFooter'
import { CopyrightList } from '../../Copyright/Copyright'
import { FooterLinks } from '../../FooterLinks/StyledLinksList'
import { LinksList } from '../../FooterLinks/LinksList'
import { Section } from '../../FooterSectionMagazines/Section'
import { FooterSectionMagazines } from '../../FooterSectionMagazines/StyledSection'
import SasSpecial4 from '../../Ads/SasSpecial4'
import { OnlyMobileWrapper } from '../../deviceWrappers'
import styled from 'styled-components'

export const Footer = () => (
  <>
    <OnlyMobileWrapper>
      <SasSpecial4 targetId='sas-special' />
    </OnlyMobileWrapper>
    <FooterContainer>
      <FooterSectionMagazines>
        <Section />
      </FooterSectionMagazines>
      <StyledCopyright>
        <CopyrightList />
      </StyledCopyright>
      <FooterLinks>
        <LinksList />
      </FooterLinks>
    </FooterContainer>
  </>
)

const StyledCopyright = styled.div`
  margin: 20px auto 10px;
`
