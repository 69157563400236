export const isEmpty = (el: unknown) => {
  if (el === null || el === undefined || el === '') {
    return true
  }

  if (Array.isArray(el) && el.length === 0) {
    return true
  }

  if (typeof el === 'object' && Object.keys(el).length === 0) {
    return true
  }

  return false
}
