import type { StyledComponentPropsWithRef } from 'styled-components'
import useEscapeKey from '../../hooks/useEscapeKey'
import { useCallback } from 'react'
import { CloseModal, WrapperClose } from './StyledCloseModal'
import CloseIcon from '../Icons/CloseIcon'
import { useCurrentRef } from '../../hooks/useCurrentRef'

export interface ClosingCrossProps
  extends Omit<StyledComponentPropsWithRef<typeof CloseModal>, 'ref'> {
  triggerOnEscape?: boolean
  onClick: () => void
}

export const ClosingCross = ({ triggerOnEscape = true, onClick, ...props }: ClosingCrossProps) => {
  const onClickRef = useCurrentRef(onClick)

  const handleEscapeKey = useCallback(() => {
    if (triggerOnEscape) {
      onClickRef.current()
    }
  }, [triggerOnEscape])

  useEscapeKey(handleEscapeKey)

  return (
    <CloseModal onClick={onClick} {...props}>
      <WrapperClose>
        <CloseIcon />
      </WrapperClose>
    </CloseModal>
  )
}
