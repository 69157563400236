import { TodayBroadcast } from './TodayBroadcast'
import { TomorrowBroadcast } from './TomorrowBroadcast'

interface Props {
  broadcastProps: {
    channelId: string
    channelSlug: string
    onShowDetails: (slug: string) => void
  }
}

export const DesktopBroadcast = ({ broadcastProps }: Props) => (
  <>
    <TodayBroadcast broadcastProps={broadcastProps} />
    <TomorrowBroadcast broadcastProps={broadcastProps} />
  </>
)
