import styled from 'styled-components'

export const StyledVerticalLabel = styled.div`
  text-transform: uppercase;
  color: inherit;
  font-weight: 400;
  opacity: 0.8;
`

export const HorizontalLabel = styled.div`
  text-align: left;
  color: inherit;
  font-weight: 400;
`
