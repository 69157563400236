import theme from '../../../client/theme'
import styled from 'styled-components'

export const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: ${theme.colors.channelSelectedBackground};
  height: 42px;
  width: 42px;

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    height: 35px;
  }
`
