import styled from 'styled-components'
import theme from '../../client/theme'

export const BackLink = styled.button`
  transition: all 100ms;
  text-decoration: none;
  color: ${theme.colors.default};
  font-weight: normal;
  font-size: ${theme.sizes.bigger};
  position: relative;
  margin-left: 20px;
  width: 10%;

  &:before {
    content: '';
    background: url('/static/images/icons/chevron_left.svg') no-repeat;
    position: absolute;
    top: 0;
    left: -20px;
    height: 20px;
    width: 20px;
    z-index: ${theme.zindex.mobileNav};
    transform: scale(0.9);
  }

  &:hover:before {
    background: url('/static/images/icons/chevron_left_orange.svg') no-repeat;
  }

  &:hover {
    color: ${theme.colors.buttonLightColor};
  }
`
