import theme from '../../client/theme'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 24px;

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    padding: 32px;
  }
`


