import styled from 'styled-components'
import theme from '../../client/theme'

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const ListItem = styled.li`
  color: ${theme.colors.default};
`
