import { AdsWrapper } from '../../components/Layout/AdsWrapper'
import { StyledChannelMainContent } from '../../components/Layout/MainContent/StyledMainContent'
import { Header, StyledContainer } from '../../components/Layout/Header/Header'
import { Programs } from '../../components/LandingPage/Programs/Programs'
import { Footer } from '../../components/Layout/Footer'
import { useFetchChannelBySlug } from '../../store/hooks/useFetchChannel'
import useWindowSize from '../../hooks/useWindowSize'
import styled from 'styled-components'
import { StyledMainLogo } from '../../components/Logos/StyledLogos'
import { HeaderIconGroupButton } from '../../components/StyledButtons'
import { StyledSelectWrapper } from '../../components/Menu/StyledSelect'
import { TimeSelectContainer } from '../../components/Menu/TimeSelectContainer'
import { StyledMobileDateSelectButton } from '../../components/MobileNav/StyledMobileDateSelectButton'
import { DisplayModeButtonsContainer } from '../../components/Menu/DisplayModeButtons'
import noop from 'lodash/noop'
import { ChannelHeader } from '../../components/LandingPage/Header/ChannelHeader'
import { ScrollContextProvider } from '../../context/Scroll.context'
import { Helmet } from 'react-helmet'

interface Props {
  slug: string
}

const ChannelPage = ({ slug }: Props) => {
  const { data } = useFetchChannelBySlug(slug)
  const isDesktop = useWindowSize('medium')

  if (!data) {
    return null
  }

  return (
    <>
      <AdsWrapper>
        <Container $isScrollable={isDesktop}>
          <Header
            onTimeSelectCallbacks={{
              onNowClick: noop,
              onDateSelect: noop
            }}
          />
          <StyledChannelMainContent>
            <ChannelHeader channelId={data.id} name={data.name} />
            <ScrollContextProvider>
              <Programs channelId={data.id} channelSlug={slug} />
            </ScrollContextProvider>
          </StyledChannelMainContent>
          {isDesktop && <Footer />}
        </Container>
      </AdsWrapper>
      <Helmet>
        <title>{`${data.name} - TV Program`}</title>
        <meta
          name={'description'}
          content={`Televizní program stanice ${data.name} s detaily pořadů přehledně na jednom místě.`}
        />
      </Helmet>
    </>
  )
}

const Container = styled.div<{ $isScrollable: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ $isScrollable }) => ($isScrollable ? 'auto' : '100vh')};
  overflow-y: ${({ $isScrollable }) => ($isScrollable ? 'scroll' : 'hidden')};
  margin: 0 auto;

  ${StyledContainer} {
    padding: 16px 24px 12px;

    @media screen and (width >= ${({ theme }) => theme.breakpoints.large}) {
      padding: 22px 24px;
    }
  }

  ${StyledMainLogo} {
    margin-left: 0;
  }

  ${DisplayModeButtonsContainer} ${HeaderIconGroupButton},
  ${StyledSelectWrapper}, ${TimeSelectContainer}, ${StyledMobileDateSelectButton} {
    opacity: ${({ theme }) => theme.opacity.disabled};
    pointer-events: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    max-width: ${({ theme }) => theme.sizes.mainContent};
    height: auto;
    overflow-y: initial;
  }
`

export default ChannelPage
