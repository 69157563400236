import { selectorFamily } from 'recoil'
import { StoreSelector } from '../storeStateName'
import { IChannel } from '../../types/entities'
import { channelState } from './channel.atom'
import * as api from '../api'

export const channelSelectorById = selectorFamily<IChannel, string>({
  key: StoreSelector.CHANNEL,
  get:
    (id) =>
    ({ get }) => {
      const channel = get(channelState)

      if (channel?.id === id) {
        return channel
      }

      return api.fetchChannelById(id)
    }
})

export const channelSelectorBySlug = selectorFamily<IChannel, string>({
  key: StoreSelector.CHANNEL,
  get:
    (slug) =>
    ({ get }) => {
      const channel = get(channelState)

      if (channel?.slug === slug) {
        return channel
      }

      return api.fetchChannelBySlug(slug)
    }
})
