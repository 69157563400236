import { useCallback } from 'react'
import { useLocation } from 'wouter'
import { BaseLocationHook, HookReturnValue, Path } from 'wouter/types/use-location'

const useQueryLocation = (): HookReturnValue<BaseLocationHook> => {
  const [location, setLocation] = useLocation()

  const customSetLocation: (path: Path, ...args: any[]) => any = useCallback((href: string) => {
    const search = window.location.search
    const fullHref = `${href}${search}`
    setLocation(fullHref)
  }, [])

  return [location, customSetLocation]
}

export default useQueryLocation
