import { useRecoilStateLoadable } from 'recoil'
import { modalStateSelector } from '../store/ui/modals/modals.selectors'
import { ModalState } from '../store/ui/modals/modals.atom'
import { useCallback, useMemo } from 'react'
import { ModalNames } from '../store/ui/modals/modals.constants'
import useFreshRef from './useFreshRef'

export interface UseModalType {
  state: ModalState
  openModal: () => void
  closeModal: () => void
}

export const useModal = (modalId: ModalNames): UseModalType => {
  const [{ contents: state }, setState] = useRecoilStateLoadable(modalStateSelector(modalId))
  const stateRef = useFreshRef(state)

  const partialStateUpdate = useCallback(
    (newValues: Partial<typeof state>) => {
      setState(newValues as typeof state)
    },
    [setState]
  )

  const showModal = useCallback(() => {
    partialStateUpdate({
      isOpened: true
    })
  }, [partialStateUpdate])

  const hideModal = useCallback(() => {
    if (stateRef.current.inProgress) {
      return
    }

    partialStateUpdate({
      isOpened: false
    })
  }, [partialStateUpdate])

  return useMemo(
    () => ({
      state,
      openModal: showModal,
      closeModal: hideModal
    }),
    [state, showModal, hideModal]
  )
}
