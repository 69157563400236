import { StyledImage } from './StyledLogos'

export const CentrumLogo = () => (
  <a
    href='https://www.centrum.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
    target='_blank'
  >
    <StyledImage src='/static/images/logos/centrum_logo.svg' alt='Centrum.cz' />
  </a>
)
