import styled from 'styled-components'
import { useListItem } from '../../ProgramsList/ListItem/useListItem'
import { IProgramme } from '../../../types/entities'
import ExternalImage from '../../Image/ExternalImage'
import { Heading2 } from '../../StyledHeadings'
import { Paragraph } from '../../ProgramDetail/StyledContainer'
import { SecondaryDetailInfo } from './SecondaryDetailInfo'
import { StyledLandingPageProgressBar } from '../Programs/ProgramContent'
import { isNonEmptyArray } from '../../../utils/array'
import { Actors } from '../../ProgramDetail/Actors'
import { Directors } from '../../ProgramDetail/Directors'

interface Props {
  programme: IProgramme
}

export const ProgramDetails = ({
  programme,
  programme: { images, title, description, descriptionLong, actor, director }
}: Props) => {
  const { timeWatchedPercent, isRunning } = useListItem(programme)

  return (
    <>
      {images.length > 0 && <StyledImage name={images[0]} />}
      {isRunning && <StyledLandingPageProgressBar $progress={timeWatchedPercent} />}{' '}
      <StyledContentContainer>
        <StyledTitle>{title}</StyledTitle>
        <SecondaryDetailInfo programme={programme} />
        {description && <StyledDescription>{description}</StyledDescription>}
        {descriptionLong && <StyledDescription>{descriptionLong}</StyledDescription>}
        {isNonEmptyArray(director) && <LandingPageDirectors directors={director} />}
        {isNonEmptyArray(actor) && <Actors actors={actor} />}
        {!description && !descriptionLong && (
          <StyledDescription>Žádné další informace nejsou k dispozici </StyledDescription>
        )}
      </StyledContentContainer>
    </>
  )
}

const LandingPageDirectors = styled(Directors)`
  margin-bottom: 3px;
`

const StyledDescription = styled(Paragraph)`
  margin-bottom: 16px;
`

const StyledImage = styled(ExternalImage)`
  display: block;
`

const StyledTitle = styled(Heading2)`
  font-size: ${({ theme }) => theme.sizes.big};
  margin-bottom: 8px;
`

const StyledContentContainer = styled.div`
  padding: 16px 18px;
`
