import { IProgramme } from '../../types/entities'
import { Heading4, InfoItemContainer, InfoItemText, InfoItemTitle } from './StyledInfo'
import styled from 'styled-components'

interface Props {
  actors: IProgramme['actor']
}

export const Actors = ({ actors }: Props) => (
  <InfoItemContainer>
    <InfoItemTitle>
      <Heading4>Hrají:</Heading4>
    </InfoItemTitle>
    <ActorsInfoItemText>
      {actors.map((actors) => `${actors.firstName} ${actors.lastName}`).join(', ')}
    </ActorsInfoItemText>
  </InfoItemContainer>
)

const ActorsInfoItemText = styled(InfoItemText)`
  width: 100%;
`
