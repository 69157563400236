import { useRecoilStateLoadable } from 'recoil'
import { appState } from '../store/app/state/appState.atom'
import { NoUndefined } from '../types/helpers'
import { AppStateType } from '../store/types'
import { useCallback } from 'react'
import { formatUtcDate } from '../utils/date'
import moment, { isMoment, Moment } from 'moment'
import { VISIBLE_HOURS } from '../components/Broadcast/Horizontal/HorizontalBroadcast.constants'

export type SetSelectedDateCallback = (times: {
  selectedDate?: NoUndefined<AppStateType['selectedDate']> | Moment
  visibleTimeStart?: AppStateType['visibleTimeStart']
  visibleTimeEnd?: AppStateType['visibleTimeEnd']
  visibleHorizontalTimeStart?: AppStateType['visibleHorizontalTimeStart']
}) => void

interface UseSelectedDateType
  extends Pick<AppStateType, 'visibleTimeStart' | 'visibleTimeEnd' | 'visibleHorizontalTimeStart'> {
  selectedDate: NoUndefined<AppStateType['selectedDate']>
  setSelectedDate: SetSelectedDateCallback
}

const useSelectedDate = (): UseSelectedDateType => {
  const [
    {
      contents: { selectedDate, visibleTimeStart, visibleTimeEnd, visibleHorizontalTimeStart }
    },
    setState
  ] = useRecoilStateLoadable(appState)

  const setSelectedDate: UseSelectedDateType['setSelectedDate'] = useCallback(
    ({
      selectedDate: rawSelectedDate,
      visibleTimeStart: startTime,
      visibleTimeEnd: endTime,
      visibleHorizontalTimeStart: horizontalTimeStart
    }) => {
      const selectedDate = isMoment(rawSelectedDate)
        ? formatUtcDate(rawSelectedDate)
        : rawSelectedDate

      const visibleTimeStart = startTime || moment(selectedDate).startOf('day').valueOf()
      const visibleHorizontalTimeStart =
        horizontalTimeStart || moment(selectedDate).startOf('day').valueOf()
      const visibleTimeEnd =
        endTime || moment(visibleTimeStart).add(VISIBLE_HOURS, 'hours').valueOf()

      setState((oldState: any) => {
        return {
          ...oldState,
          selectedDate: selectedDate || oldState.selectedDate,
          visibleTimeStart: visibleTimeStart || oldState.visibleTimeStart,
          visibleHorizontalTimeStart:
            visibleHorizontalTimeStart || oldState.visibleHorizontalTimeStart,
          visibleTimeEnd: visibleTimeEnd || oldState.visibleTimeEnd
        }
      })
    },
    []
  )

  return {
    selectedDate,
    visibleTimeStart,
    visibleTimeEnd,
    visibleHorizontalTimeStart,
    setSelectedDate
  }
}

export default useSelectedDate
