import styled from 'styled-components'
import theme from '../../client/theme'

export const FooterSectionMagazines = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media screen and (min-width: ${theme.breakpoints.medium}) {
  }
`
