import { atom } from 'recoil'
import { StoreStateName } from '../storeStateName'
import { IProgramme } from '../../types/entities'

export type ProgrammeStateType = Record<IProgramme['id'], IProgramme>

export const programmesState = atom<ProgrammeStateType>({
  key: StoreStateName.PROGRAMMES,
  default: {}
})
