import { SasicAction } from '../types/ads'
import theme from '../client/theme'

const { breakpoints } = theme

declare global {
  interface Window {
    _sasic: any
    _sasic_queue: any
  }
}

const isWindow = () => typeof window !== 'undefined'

const getWidth = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0)

export const isDesktop = () => {
  return getWidth() > parseInt(breakpoints.small)
  }

export const isMobile = () => !isDesktop()

export const detectSasicDevice = () => {
  if (isWindow()) {
    if (isMobile()) {
      return 'm'
    }
  }

  return 'd'
}

export const sasicQueuePush = (...payload: SasicAction[]) => {
  if (isWindow()) {
    window._sasic_queue.push(...payload)
  }
}

export const sasicChangeOptions = (area: string, template: string) => {
  sasicQueuePush(['group', { options: { area, targets: { template } } }])
}

export const purgeSasicPositions = () => {
  if (isWindow()) {
    Object.values(window._sasic.get(['groups'])).forEach((group : any) => {
      Object.values(group.positions as any).forEach(({ elementId } : any) => {
        sasicQueuePush(['removePosition', elementId])
      })
    })
  }
}

export const sasicReloadAll = () => {
  sasicQueuePush(['loadAll'])
}
