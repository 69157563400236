import { FooterTitle } from './StyledSecondCol'
import { FooterLink } from './StyledFooterLink'
import { List, ListItem } from './StyledFooterList'

export const CatalogsList = () => (
  <div>
    <FooterTitle>Inzerce a katalogy</FooterTitle>
    <List>
      <ListItem>
        <FooterLink
          href='https://najisto.centrum.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Firmy
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://www.cars.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Autobazary
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://realitymix.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Reality
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://www.levnezajezdy.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Dovolená
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://prace.centrum.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Práce
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://www.vareni.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Recepty
        </FooterLink>
      </ListItem>
    </List>
  </div>
)
