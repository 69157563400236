import type { SVGProps } from 'react'

const ShareIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3 6.99999H0V14H12V6.99999H9V8.49999H10.5V12.5H1.5V8.49999H3V6.99999Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.75 2.99999V9.99999H5.25V2.99999H6.75Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 0.939331L9.53033 4.46966L8.46967 5.53032L6 3.06065L3.53033 5.53032L2.46967 4.46966L6 0.939331Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ShareIcon
