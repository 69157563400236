import AppConfig from '../../config'
import Image from './index'
import { StyledComponentPropsWithRef } from 'styled-components'

export interface ExternalImageProps
  extends Omit<StyledComponentPropsWithRef<typeof Image>, 'src' | 'ref'> {
  name: string
}

const ExternalImage = ({ name, ...props }: ExternalImageProps) => {
  const src = `${AppConfig.externalImageBaseUrl}/?name=${name}`

  return <Image {...props} src={src} />
}

export default ExternalImage
