import React, { ReactNode, useMemo } from 'react'
import moment from 'moment'
import { END_VISIBLE_DATE } from '../Broadcast/Horizontal/HorizontalBroadcast.constants'
import { formatDate } from '../../utils/date'
import { StyledDayName } from './StyledSelect'
import useSelectedDate from '../../hooks/useSelectedDate'
import { VISIBLE_DATE_OFFSETS } from '../../helpers/visibleDates'

export interface IDateMenuOption {
  isSelected: boolean
  value: string
  label: ReactNode
  offsetFromToday: number
}

const useMenuOptions = () => {
  const { selectedDate, setSelectedDate } = useSelectedDate()

  return useMemo(() => {
    const selectedDateObj = moment(selectedDate).startOf('day').locale('cs')
    const today = moment().startOf('day').locale('cs')
    const offsetFromToday = selectedDateObj.diff(today, 'day')

    const options: IDateMenuOption[] = VISIBLE_DATE_OFFSETS.map((count) => ({
      offsetFromToday: count + offsetFromToday,
      date: selectedDateObj.clone().add(count, 'day')
    }))
      .filter((value) => value.date.isSameOrBefore(END_VISIBLE_DATE))
      .map(({ date, offsetFromToday }) => ({
        offsetFromToday,
        isSelected: date.isSame(selectedDateObj),
        value: formatDate(date.toDate()),
        label: (
          <>
            <StyledDayName>{date.format('dddd')}</StyledDayName>
            &nbsp;
            {date.format('D. M.')}
          </>
        )
      }))

    return {
      setSelectedDate,
      options,
      selectedOption: options.find((option) => option.isSelected)
    }
  }, [selectedDate])
}

export default useMenuOptions
