import { AdsWrapper, Overlay } from '../../components/Layout/AdsWrapper'
import { StyledHomepageMainContent } from '../../components/Layout/MainContent/StyledMainContent'
import VerticalBroadcast from '../../components/Broadcast/VerticalBroadcast'
import HorizontalBroadcast from '../../components/Broadcast/Horizontal/HorizontalBroadcast'
import { BroadcastDisplayMode } from '../../constants'
import { useWrappedRecoilValue } from '../../hooks/useWrappedRecoilValue'
import { settingsState } from '../../store/settings/settings.atom'
import { Header } from '../../components/Layout/Header/Header'
import { Footer } from '../../components/Layout/Footer'
import moment from 'moment'
import { dateToHomePageBroadcastingLink } from '../../utils/date'
import useQueryLocation from '../../hooks/useQueryLocation'
import Config from '../../config'
import AppConfig from '../../config'
import { SettingsStateType } from '../../store/types'
import { useFetchChannels } from '../../store/hooks/useFetchChannels'
import { MainLogo } from '../../components/Logos/MainLogo'
import { useRoute } from 'wouter'

interface Props {
  settings: SettingsStateType
}

const HomepageWrapper = () => {
  const { data } = useWrappedRecoilValue(settingsState)

  if (!data) {
    return null
  }

  return <Homepage settings={data} />
}

const Homepage = ({ settings }: Props) => {
  const [location, setLocation] = useQueryLocation()
  const { data } = useFetchChannels(settings.channelIds)
  const handleProgrammeClick = (slug: string) => setLocation(Config.routes.detail(slug))
  const [programmeDetailsMatch] = useRoute(AppConfig.routes.detail(':id'))

  if (!data) {
    return null
  }

  const navigateToDate = (date: string, location: string) => {
    const link = dateToHomePageBroadcastingLink(moment.utc(date))

    if (location !== link) {
      setLocation(link)
    }
  }

  return (
    <AdsWrapper>
      <Header
        onTimeSelectCallbacks={{
          onDateSelect: (date) => navigateToDate(date, location),
          onNowClick: () => setLocation(Config.routes.home)
        }}
        logo={
          programmeDetailsMatch ? (
            <MainLogo />
          ) : (
            <h1>
              <MainLogo />
            </h1>
          )
        }
      />
      <StyledHomepageMainContent>
        <Overlay>
          {settings.broadcastDisplayMode === BroadcastDisplayMode.VERTICAL ? (
            <VerticalBroadcast channels={data} onProgrammeClick={handleProgrammeClick} />
          ) : (
            <HorizontalBroadcast channels={data} onProgrammeClick={handleProgrammeClick} />
          )}
        </Overlay>
      </StyledHomepageMainContent>
      <Footer />
    </AdsWrapper>
  )
}

export default HomepageWrapper
