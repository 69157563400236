import theme from '../../client/theme'
import styled from 'styled-components'

export const CloseModal = styled.a`
  text-align: right;
  cursor: pointer;
  border: none;
  background: none;
  display: inline-block;
  float: right;
  width: 70px;
  height: 42px;
  position: relative;
  top: -5px;

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    width: 38px;
    height: 38px;
    top: -15px;
    left: 10px;
   }
}
`

export const WrapperClose = styled.span`
  position: relative;
  top: 10px;
  color: ${(props) => props.theme.colors.iconOrange};

  &:before {
    content: 'Zavřít';
    position: absolute;
    left: -50px;
    top: -3px;
    color: ${theme.colors.buttonLightColor};
    font-weight: 500;
    font-size: ${theme.sizes.bigger};
    line-height: 16px;
  }

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    top: 20px;
    left: -10px;

    &:before {
      display: none;
    }
  }
`
