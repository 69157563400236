import { StoreStateName } from './storeStateName'
import { RecoilState } from 'recoil'
import { channelsState } from './channels/channels.atom'
import { programmesState } from './programmes/programmes.atom'
import { broadcastsState } from './broadcasts/broadcasts.atom'
import { settingsState } from './settings/settings.atom'
import { HasSameKeys } from '../types/helpers'
import { modalsState } from './ui/modals/modals.atom'
import { appState } from './app/state/appState.atom'
import { channelState } from './channels/channel.atom'
import { channelGroupState } from './channels/channelGroup.atom'

export const StatesByNames = (<T extends Record<StoreStateName, RecoilState<any>>>(
  channels: HasSameKeys<T, (typeof StoreStateName)[keyof typeof StoreStateName]>
) => channels)({
  [StoreStateName.CHANNELS]: channelsState,
  [StoreStateName.CHANNEL]: channelState,
  [StoreStateName.CHANNEL_GROUP]: channelGroupState,
  [StoreStateName.PROGRAMMES]: programmesState,
  [StoreStateName.BROADCAST]: broadcastsState,
  [StoreStateName.SETTINGS]: settingsState,
  [StoreStateName.UI_MODALS]: modalsState,
  [StoreStateName.APP_STATE]: appState
})

export type StatesByNamesType = typeof StatesByNames

export type StatesInitValuesType = {
  [key in StoreStateName]: StatesByNamesType[key] extends RecoilState<infer L> ? L : never
}
