import { RecoilState, RecoilValue, RecoilValueReadOnly, useRecoilValueLoadable } from 'recoil'
import { useMemo } from 'react'
import { NoUndefined } from '../types/helpers'

export interface WrappedRecoilValueReturnType<T> {
  loading: boolean
  error: unknown | undefined
  data: T | undefined
  notFound: boolean
  statusMessage: string | null
}

export interface WrappedRecoilStaticValueReturnType<T> {
  loading: boolean
  error: unknown | undefined
  data: NoUndefined<T>
  notFound: boolean
  statusMessage: string | null
}

function useWrappedRecoilValue<T>(
  state: RecoilState<T> | RecoilValue<T> | RecoilValueReadOnly<T>
): WrappedRecoilValueReturnType<T>
function useWrappedRecoilValue<T>(
  state: RecoilState<T> | RecoilValue<T> | RecoilValueReadOnly<T>,
  fallback: T
): WrappedRecoilStaticValueReturnType<T>
function useWrappedRecoilValue<T>(
  selector: RecoilState<T> | RecoilValue<T> | RecoilValueReadOnly<T>,
  fallback?: T
): WrappedRecoilStaticValueReturnType<T> | WrappedRecoilValueReturnType<T> {
  const recoilRes = useRecoilValueLoadable(selector)

  return useMemo(() => {
    const loading = recoilRes.state === 'loading'
    const error = recoilRes.state === 'hasError' ? recoilRes.contents : undefined
    const data = recoilRes.state === 'hasValue' ? recoilRes.contents ?? fallback : fallback

    let notFound = false
    if (error) {
      notFound = error.statusCode === 404
      console.error('Error loading content: ', error?.message, JSON.stringify(error, null, 2))
    }

    return {
      loading,
      error,
      data,
      notFound,
      statusMessage: loading
        ? 'Načítání...'
        : notFound
        ? 'Obsah který hledáte již neexistuje'
        : error
        ? 'Vyskytla se chyba'
        : null
    }
  }, [recoilRes])
}

export { useWrappedRecoilValue }
