import styled, { css } from 'styled-components'

interface Props {
  $isActive: boolean
}

const ListItem = styled.li`
  height: 100%;
  font-size: ${({ theme }) => theme.sizes.smaller};
  color: ${({ theme }) => theme.colors.listItem};
  font-weight: 500;
  line-height: 15px;
  background: ${({ theme }) => theme.colors.itemBackground};
  position: relative;
  list-style: none;
  overflow: hidden;
  padding: 0;
  letter-spacing: 0.04em;

  &:hover {
    background: ${({ theme }) => theme.colors.default};
    color: ${({ theme }) => theme.colors.buttonColor};
    z-index: 200;
  }
`

export const StyledListLink = styled.a`
  display: block;
  text-decoration: none;
  color: inherit;
`

export const StyledAnchor = styled(StyledListLink)`
  user-drag: none;
  user-select: none;
`

export const VerticalItemWrapper = styled(ListItem)<Props>`
  margin-bottom: 4px;
  max-width: 124px;
  height: ${(props) => (props.$isActive ? '118px' : '65px')};
  color: ${({ theme }) => theme.colors.default};

  &:first-child {
    margin-bottom: 7px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.buttonColor};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    max-width: 150px;
  }

  ${({ theme, $isActive }) =>
    $isActive &&
    css`
      background: ${theme.colors.default};
      color: ${theme.colors.buttonColor};
    `}
`

export const HorizontalItemWrapper = styled(ListItem)<Props>`
  margin-bottom: 0;
  max-width: 100%;
  color: ${({ theme }) => theme.colors.default};

  &:hover {
    min-width: 124px;
    background: ${({ theme }) => theme.colors.buttonLightColor};
    color: ${({ theme }) => theme.colors.opacity8};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    &:hover {
      min-width: 150px;
    }
  }

  ${({ $isActive, theme }) =>
    $isActive &&
    `
    background: ${theme.colors.default};
    color: ${theme.colors.buttonColor};

    &:hover {
      background: ${theme.colors.buttonLightColor};
      color: ${theme.colors.listItem};
    }
  `}
`

const PlaceholderListItem = styled(ListItem)`
  max-width: 100%;
  min-width: 100%;
  font-size: ${({ theme }) => theme.sizes.big};
  display: flex;
  align-items: center;

  &:hover {
    min-width: 100%;
    font-size: ${({ theme }) => theme.sizes.big};
    color: ${({ theme }) => theme.colors.listItem};
    background: ${({ theme }) => theme.colors.itemBackground};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    max-width: 100%;
    min-width: 100%;
  }
`
export const VerticalPlaceholderListItem = styled(PlaceholderListItem)`
  padding: 12px 6px;
  line-height: 1.25;
`
export const HorizontalPlaceholderListItem = styled(PlaceholderListItem)`
  padding: 20px;
  line-height: 1;
`
