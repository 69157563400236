import { MutableSnapshot, RecoilState } from 'recoil'
import { StatesByNames, StatesInitValuesType } from './index'
import { StoreStateName } from './storeStateName'
import { InferRecoilStateParam } from './types'
import { isEmpty } from './utils'

function initStore(data?: Partial<StatesInitValuesType>) {
  if (!data || isEmpty(data)) {
    return () => {}
  }

  return ({ set }: MutableSnapshot): void => {
    ;(Object.entries(StatesByNames) as [StoreStateName, RecoilState<any>][])
      .map(([name, state]) => [state, data[name]])
      .filter(([, data]) => data !== undefined && data !== null)
      .forEach(([state, data]) => set(state as InferRecoilStateParam<typeof state>, data))
  }
}

export default initStore
