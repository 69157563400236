import { IChannel } from '../types/entities'

export function getFormattedChannelNames(channels: IChannel[]) {
  const names = channels.map((c) => c.name)

  if (names.length <= 1) {
    return names.join('')
  }

  const lastName = names.pop()

  return `${names.join(', ')} a ${lastName}`
}
