import type { SVGProps } from 'react'

const CalendarIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='13'
      height='13'
      viewBox='0 0 13 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='0.75' y='1.75' width='11.5' height='10.5' stroke='currentColor' strokeWidth='1.5' />
      <path d='M1 5L12 5' stroke='currentColor' strokeWidth='1.5' />
      <path d='M4 0L4 3' stroke='currentColor' strokeWidth='1.5' />
      <path d='M9 0L9 3' stroke='currentColor' strokeWidth='1.5' />
    </svg>
  )
}

export default CalendarIcon
