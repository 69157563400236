import styled from 'styled-components'

export interface ProgressBarProps {
  $progress: number
}

export const ProgressBar = styled.div<ProgressBarProps>`
  background: ${({ theme }) => theme.colors.progressBarBackground};

  &:before {
    content: '';
    display: block;
    width: ${({ $progress }) => $progress}%;
    height: ${({ $progress }) => ($progress > 0 ? '3px' : '0')};
    background: ${({ theme }) => theme.colors.buttonLightColor};
`
