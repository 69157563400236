import { atom } from 'recoil'

type RootPageContentStateType = {
  loadToday: boolean
}

export const RootPageContentState = atom<RootPageContentStateType>({
  key: 'root_page_content_key',
  default: {
    loadToday: false
  }
})
