import { FooterTitle } from './StyledSecondCol'
import { FooterLink } from './StyledFooterLink'
import { List, ListItem } from './StyledFooterList'

export const CommunicationList = () => (
  <div>
    <FooterTitle>Komunikace</FooterTitle>
    <List>
      <ListItem>
        <FooterLink
          href='https://user.centrum.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          E-mail
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://www.xchat.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Chat
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://magazin.aktualne.cz/kuriozity/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Zábava
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='http://gamescafe.centrum.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Hry
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='http://svatky.centrum.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Svátky
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://fotoalba.xchat.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Fotoalba
        </FooterLink>
      </ListItem>
    </List>
  </div>
)
