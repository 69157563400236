import { getConsent } from './didomiConsent'

const sendEvent = (data: object) => {
  if (typeof window === 'undefined') {
    return
  }
  window.didomiOnReady.push(function () {
    const { analytics } = getConsent()
    if (!analytics) {
      console.debug(`Prevent sending GA event, not allowed`, data)
      return
    }

    console.debug(`Sending GA event`, data)
    window.dataLayer.push({
      ...data,
      _clear: true
    })
  })
}

export const sendSelectDateMenuEvent = (offset: number) => {
  const sign = offset >= 0 ? '+' : '-'

  sendEvent({
    event: 'tv_date_menu',
    date: {
      value: `t${sign}${Math.abs(offset)}`
    }
  })
}

export const sendClickDateTimeEvent = (datetime: `vcera` | `prave_ted` | `dnes` | `zitra`) => {
  sendEvent({
    event: 'tv_date_pick',
    item: {
      click: datetime
    }
  })
}

export const sendLayoutChangeEvent = (layout: `list` | `column`) => {
  sendEvent({
    event: 'tv_layout_change',
    layout: {
      value: layout
    }
  })
}

export const sendSettingsSaveEvent = () => {
  sendEvent({
    event: 'tv_preferences_save'
  })
}
