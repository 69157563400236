import range from 'lodash/range'
import moment from 'moment/moment'

export const FIRST_VISIBLE_DATE_OFFSET = -7
export const LAST_VISIBLE_DATE_OFFSET = 7
export const VISIBLE_DATE_OFFSETS = range(FIRST_VISIBLE_DATE_OFFSET, LAST_VISIBLE_DATE_OFFSET + 1)

export function getVisibleDates() {
  return VISIBLE_DATE_OFFSETS.map((count) => moment().add(count, 'day'))
}
