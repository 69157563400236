import { DidomiSDK } from '@didomi/react'
import { didomiConfig } from '../../analytics/didomiConsent'

const Didomi = () => {
  return (
    <DidomiSDK
      apiKey={'9a8e2159-3781-4da1-9590-fbf86806f86e'}
      iabVersion={2}
      gdprAppliesGlobally={true}
      config={didomiConfig}
    />
  )
}

export default Didomi
