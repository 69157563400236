import { MouseEvent, useCallback, useRef } from 'react'

export const useMouseLinkHandlers = (onClickParent: () => void) => {
  const isDragged = useRef(false)

  const onClick = useCallback((e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
  }, [])

  const onMouseMove = useCallback(() => {
    isDragged.current = true
  }, [])

  const onMouseDown = useCallback(() => {
    isDragged.current = false
  }, [])

  const onMouseUp = useCallback(() => {
    if (!isDragged.current) {
      onClickParent()
    }
    isDragged.current = false
  }, [onClickParent])

  return {
    onClick,
    onMouseUp,
    onMouseDown,
    onMouseMove
  }
}

const THROTTLE_TIME_MS = 250

export const useTouchLinkHandlers = (onTap: () => void) => {
  const timeRef = useRef(0)

  const onTouchStart = useCallback(() => {
    timeRef.current = Date.now()
  }, [])

  const onTouchEnd = useCallback(() => {
    const diff = Date.now() - timeRef.current
    timeRef.current = 0

    if (diff < THROTTLE_TIME_MS) {
      // @hotfix: does not work for mobile view, if you decrease THROTTLE_TIME_MS, the onTap is not working correctly
      // there are multiple redirects from /detail/:id -> / -> "current / chosen date" the function is ?triggered
      // multiple-times? (The detail / modal is lets say opened and closed at the same time)
      // onTap()
    }
  }, [onTap])

  const onDragStart = useCallback((e) => {
    e.preventDefault()
  }, [])

  return { onTouchStart, onTouchEnd, onDragStart }
}
