import React, { Component } from 'react'
import AdvertSignWrapper from './AdvertSignWrapper'
import SasicAdvert from './SasicAdvert'

interface Props {
  targetId: string,
}

export default class SasSpecial4 extends Component<Props> {
  shouldComponentUpdate () {
    return false
  }

  render () {
    const {
      targetId
    } = this.props

    const supertag = 'popup'

    return (
      <div id={`${targetId}-wrapper`}>
        <AdvertSignWrapper/>
        <SasicAdvert id={targetId} supertag={supertag} pos='1'/>
      </div>
    )
  }
}
