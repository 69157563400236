import theme from '../../client/theme'
import styled, { css } from 'styled-components'

export const StyledChevron = styled.span`
  font-weight: 400;
  position: relative;
  margin-right: 52px;
  text-transform: capitalize;

  &:before {
    content: '';
    background: url('/static/images/icons/chevron_right.svg') no-repeat;
    position: absolute;
    top: 0;
    right: -30px;
    height: 20px;
    width: 20px;
    transform: scale(1.1);
  }

  &:hover {
    color: ${theme.colors.buttonLightColor};
  }
`

export const StyledCross = styled.span`
  background: #fff;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 25px;

  &:before,
  &:after {
    background: #fff;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }

  &:before {
    top: 7px;
  }

  &:after {
    top: -7px;
  }
`

export const StyledButton = styled.button<{ isOpen: boolean }>`
  cursor: pointer;
  position: relative;
  user-select: none;
  width: 20px;
  height: 14px;
  display: flex;

  align-items: center;
  justify-content: center;

  ${(props) =>
    props.isOpen &&
    css`
      & > span {
        background-color: transparent;
      }

      & > span:before {
        transform: rotate(-45deg);
      }

      & > span:after {
        transform: rotate(45deg);
      }

      & > span:before,
      & > span:after {
        top: 0;
      }
    `}
  }
`

export const StyledItem = styled.div`
  position: relative;
  font-size: ${theme.sizes.bigger};
  font-weight: 500;

  & > button {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 18px 0 18px 24px;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-out;
    background-color: transparent;
    border: none;
    width: 100%;
    font-size: ${theme.sizes.bigger};
    font-weight: 500;
  }

  &:before {
    display: block;
    content: '';
    background: rgba(255, 255, 255, 0.25);
    height: 1px;
    width: 100%;
    position: relative;
    bottom: 0;
    left: 24px;
  }
`

export const Container = styled.div<{ $isOpen: boolean }>`
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #033349;
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
  position: absolute;
  left: 0;
  display: block;
  transform: translateX(0%);
  width: 100%;
  min-width: 320px;
  top: 72px;
  z-index: ${theme.zindex.mobileNav};

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      min-height: 100vh;
      overflow-y: auto;
    `}
`

export const MobileNav = styled.div`
  order: 3;

  @media screen and (min-width: ${theme.breakpoints.small}) {
    order: 4;
  }

  @media screen and (min-width: ${theme.breakpoints.large}) {
    display: none;
  }
`
