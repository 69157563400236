import { RefObject, useEffect } from 'react'
import { isAnyModalOpenedSelector } from '../store/ui/modals/modals.selectors'
import { useRecoilValueLoadable } from 'recoil'

const useKeyboardScroll = (ref: RefObject<HTMLElement>, stopEventPropagation = true) => {
  const { contents: isModalOpened } = useRecoilValueLoadable(isAnyModalOpenedSelector)

  useEffect(() => {
    const handlerFactory = (
      keyCode: string,
      offset: number,
      direction: 'scrollTop' | 'scrollLeft'
    ) => {
      return (e: KeyboardEvent) => {
        if (!ref?.current || isModalOpened || e.key !== keyCode) {
          return
        }

        const scrollBefore = ref.current[direction]
        ref.current[direction] += offset

        if (stopEventPropagation && scrollBefore !== ref.current[direction]) {
          e.preventDefault()
          e.stopPropagation()
        }
      }
    }

    const handlers = [
      handlerFactory('ArrowDown', 20, 'scrollTop'),
      handlerFactory('ArrowUp', -20, 'scrollTop'),
      handlerFactory('ArrowLeft', -20, 'scrollLeft'),
      handlerFactory('ArrowRight', 20, 'scrollLeft')
    ]

    handlers.forEach((handler) => window.addEventListener('keydown', handler))

    return () => {
      handlers.forEach((handler) => window.removeEventListener('keydown', handler))
    }
  }, [stopEventPropagation, isModalOpened])
}

export default useKeyboardScroll
