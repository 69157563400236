import styled from 'styled-components'

export const Heading4 = styled.h4`
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
`

export const Info = styled.div`
  margin-bottom: 28px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`

export const InfoItemTitle = styled.span`
  display: inline-block;
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.small};
  color: ${({ theme }) => theme.colors.selectionFontGrey};
  margin-right: 3px;
  width: 40px;

  @media screen and (min-width: ${({ theme }) => theme.sizes.small}) {
    font-size: ${({ theme }) => theme.sizes.smaller};
    line-height: 14px;
    width: 35px;
  }
`

export const InfoItemText = styled(InfoItemTitle)`
  flex: 1;
  font-weight: 400;
`

export const InfoItemContainer = styled.div`
  display: flex;
`

export const InfoItem = styled.div<{ $bold?: boolean }>`
  margin-bottom: 9px;
  font-weight: ${({ $bold }) => ($bold ? 500 : 400)};
  color: ${({ theme }) => theme.colors.selectionFontGrey};
  font-size: ${({ theme }) => theme.sizes.small};
  line-height: 14px;

  &:nth-child(odd) {
    font-weight: 500;
    width: 40px;
    max-width: 40px;
    margin-right: 3px;
  }

  &:nth-child(even) {
    width: calc(100% - 43px);
    max-width: calc(100% - 43px);
  }
`

export const DateInfoItem = styled(InfoItem)`
  margin-bottom: 0;
  font-weight: 400;

  &:nth-child(odd) {
    width: 81px;
    max-width: 81px;
    margin-right: 15px;
    font-size: ${({ theme }) => theme.sizes.medium};
  }

  &:nth-child(even) {
    width: calc(100% - 96px);
    max-width: calc(100% - 96px);
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    &:nth-child(odd) {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 7px;
    }

    &:nth-child(even) {
      width: 100%;
      max-width: 100%;
    }
  }
`
