import styled, { css } from 'styled-components'

interface LayoutIconRowProps {
  isActive?: boolean
}

const LayoutIconRow = ({ isActive = false, ...props }: LayoutIconRowProps) => {
  return (
    <StyledWrapper $isActive={isActive}>
      <svg
        width='21'
        height='16'
        viewBox='0 0 21 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M20 13H7V15H20V13ZM4 13H1V15H4V13ZM20 7H7V9H20V7ZM4 7H1V9H4V7ZM4 1H1V3H4V1ZM20 1H7V3H20V1ZM5 0V4H0V0H5ZM21 6V10H6V6H21ZM0 12V16H5V12H0ZM6 12V16H21V12H6ZM0 6V10H5V6H0ZM6 0V4H21V0H6Z'
          fill='currentColor'
        />
      </svg>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.span<{ $isActive?: boolean }>`
  display: inline-flex;
  color: ${(props) => props.theme.colors.iconWhite};
  padding: 6px 8px;
  cursor: pointer;
  border-top-left-radius: ${(props) => props.theme.radius.default};
  border-bottom-left-radius: ${(props) => props.theme.radius.default};

  &:hover {
    color: ${(props) => props.theme.colors.iconOrange};
    background: ${(props) => props.theme.colors.iconWhite};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      background: ${(props) => props.theme.colors.iconOrange};
      color: ${(props) => props.theme.colors.iconWhite};

      &:hover {
        background: ${(props) => props.theme.colors.iconOrange};
        color: ${(props) => props.theme.colors.iconWhite};
      }
    `}
`

export default LayoutIconRow
