import theme from '../../client/theme'
import styled from 'styled-components'

export const Sign = styled.div`
  background-color: ${theme.colors.buttonLightColor} !important;
  width: 2px;
  z-index: 120;
`

export const SignLabel = styled.div`
  color: ${theme.colors.default};
  font-size: ${theme.sizes.small};
  background: ${theme.colors.buttonLightColor};
  width: 46px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -22px;
  top: 0;
  border-radius: ${theme.radius.default};
`
