import { useSetRecoilState } from 'recoil'
import { appState } from '../../store/app/state/appState.atom'
import {
  dateToHomePageBroadcastingLink,
  getDefaultDateTimes,
  getTodayTimes
} from '../../utils/date'
import { useEffect, useMemo } from 'react'
import moment from 'moment'
import { useWrappedRecoilValue } from '../../hooks/useWrappedRecoilValue'
import { programmeSelector } from '../../store/programmes/programmes.selectors'
import { IProgramme } from '../../types/entities'
import config from '../../config'

const useProgramDetail = (slug: IProgramme['slug']) => {
  const { data, statusMessage, error } = useWrappedRecoilValue(programmeSelector(slug))
  const updateAppState = useSetRecoilState(appState)
  const startDate = data?.start && moment(data.start)
  const broadcastingLink = startDate
    ? dateToHomePageBroadcastingLink(startDate)
    : config.routes.home

  const addToCalendarLink = useMemo(() => {
    if (data) {
      const start = moment(data.start).format('YYYYMMDDTHHmmSSZ')
      const end = moment(data.stop).format('YYYYMMDDTHHmmSSZ')
      const fullUrl = config.domain.concat(config.routes.detail(data.slug))

      const searchParams = new URLSearchParams({
        action: 'TEMPLATE',
        dates: `${start}/${end}`,
        text: data.title,
        details: `Zdroj: ${fullUrl}`,
        ctz: 'Europe/Prague'
      })
      return `https://calendar.google.com/calendar/event?${searchParams.toString()}`
    }
    return ''
  }, [data])

  useEffect(() => {
    if (error) {
      updateAppState((oldState) => ({
        ...oldState,
        ...getTodayTimes()
      }))
    }
  }, [error])

  useEffect(() => {
    if (!startDate) {
      return
    }

    updateAppState((oldState) => {
      const newDates = getDefaultDateTimes(startDate, startDate.hours())

      if (oldState.selectedDate === newDates.selectedDate) {
        return oldState
      }

      return {
        ...oldState,
        ...newDates
      }
    })
  }, [startDate])

  return {
    data,
    addToCalendarLink,
    statusMessage,
    broadcastingLink
  }
}

export default useProgramDetail
