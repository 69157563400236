import type { SVGProps } from 'react'

const ArrowUpIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='6.10376' y='2.90918' width='2.90909' height='13.0909' fill='currentColor' />
      <path
        d='M7.55804 0L15.1161 8.72727L7.55804 7.45863L0 8.72727L7.55804 0Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ArrowUpIcon
