import { IChannel } from '../types/entities'

export const DefaultChannelsIds: readonly IChannel['id'][] = [
  '1',
  '2',
  '24',
  '18',
  '3',
  '78',
  '4',
  '92',
  '333',
  '89',
  '19',
  '804',
  '357',
  '358'
] as const
