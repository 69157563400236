import OrderListItem from './OrderListItem'
import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { IChannel } from '../../../types/entities'
import { useWrappedRecoilValue } from '../../../hooks/useWrappedRecoilValue'
import { channelsState } from '../../../store/channels/channels.atom'
import type { DragEndEvent } from '@dnd-kit/core/dist/types'
import { restrictToParentElement } from '@dnd-kit/modifiers'

export interface OrderProps {
  selectedChannelsIds: IChannel['id'][]
  onOrderChange: (event: DragEndEvent) => void
  onProgrammaticallyChange: (id: IChannel['id'], oldIndex: number, newIndex: number) => void
}

export const Order = ({
  selectedChannelsIds,
  onOrderChange,
  onProgrammaticallyChange
}: OrderProps) => {
  const { data: channels } = useWrappedRecoilValue(channelsState, {})
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 10
      }
    }),
    useSensor(TouchSensor, {
      // Should disable dragging on touch devices
      // but must be used to make arrows working
      activationConstraint: {
        tolerance: 0,
        delay: 0,
        distance: 0
      }
    })
  )

  return (
    <ul>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={onOrderChange}
        modifiers={[restrictToParentElement]}
      >
        <SortableContext items={selectedChannelsIds} strategy={verticalListSortingStrategy}>
          {selectedChannelsIds
            .map((id) => channels[id])
            .filter(Boolean)
            .map((channel: IChannel) => (
              <OrderListItem
                key={channel.id}
                channel={channel}
                onProgrammaticallyChange={onProgrammaticallyChange}
              />
            ))}
        </SortableContext>
      </DndContext>
    </ul>
  )
}
