import styled from 'styled-components'

export const VerticalContent = styled.div`
  height: 93px;
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const HorizontalContent = styled.div`
  height: auto;
  padding: 0 10px;
  color: inherit;
`
