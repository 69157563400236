import { IProgramme } from '../../../types/entities'
import styled from 'styled-components'
import { ProgramContent } from './ProgramContent'
import { useCallback, useEffect } from 'react'
import useWindowSize from '../../../hooks/useWindowSize'
import { StyledListLink } from '../../ProgramsList/ListItem/StyledContainer'
import { useNavigateToChannelProgrammeDetail } from '../../../hooks/useNavigateToProgrammeDetail'
import { isFirstTomorrowProgramme, isRunningProgramme } from '../../../utils/programme'
import { useCurrentRef } from '../../../hooks/useCurrentRef'

interface Props {
  programmeProps: {
    programme: IProgramme
    channelSlug: string
    onShowDetails: (slug: string) => void
  }
  programmeCallbacks: {
    onFindRunningProgramme: (programme: HTMLDivElement) => void
    onFindFirstTomorrowProgramme: (programme: HTMLDivElement) => void
  }
}

export const Programme = ({
  programmeProps: { programme, channelSlug, onShowDetails },
  programmeCallbacks: { onFindRunningProgramme, onFindFirstTomorrowProgramme }
}: Props) => {
  const { href, mouseLinkHandlers, touchLinkHandlers } = useNavigateToChannelProgrammeDetail(
    channelSlug,
    programme.slug
  )
  const isDesktop = useWindowSize('medium')
  const isRunning = isRunningProgramme(programme)

  const onShowDetailsRef = useCurrentRef(onShowDetails)

  let timeoutId: number

  const programmeRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node) {
        if (isRunning) {
          timeoutId = window.setTimeout(() => {
            onFindRunningProgramme(node)
          }, 100)
        } else if (isFirstTomorrowProgramme(programme)) {
          onFindFirstTomorrowProgramme(node)
        }
      }
    },
    [isRunning]
  )

  useEffect(() => {
    return () => window.clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    if (isRunning) {
      onShowDetailsRef.current(programme.slug)
    }
  }, [isRunning, programme])

  return (
    <StyledProgramContainer ref={programmeRef} $isActive={isRunning}>
      {isDesktop ? (
        <div onClick={() => onShowDetails(programme.slug)}>
          <ProgramContent programme={programme} />
        </div>
      ) : (
        <StyledListLink href={href} {...mouseLinkHandlers} {...touchLinkHandlers}>
          <ProgramContent programme={programme} />
        </StyledListLink>
      )}
    </StyledProgramContainer>
  )
}

const StyledProgramContainer = styled.div<{ $isActive: boolean }>`
  background: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.default : theme.colors.itemBackground};
  color: ${({ $isActive, theme }) => ($isActive ? theme.colors.buttonColor : theme.colors.default)};
  margin-bottom: 4px;
  padding: ${({ $isActive }) => ($isActive ? '' : '0 0 12px')};

  :last-child {
    margin-bottom: 0;
  }

  :hover {
    background: ${({ theme }) => theme.colors.default};
    color: ${({ theme }) => theme.colors.buttonColor};
    cursor: pointer;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 0 0 12px;
  }
`
