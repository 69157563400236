import AppConfig from '../../../config'
import { Link } from 'wouter'
import { StyledBackToHomeButton } from '../../Button/StyledBackToHomeButton'
import useWindowSize from '../../../hooks/useWindowSize'
import styled from 'styled-components'
import { Heading1 } from '../../StyledHeadings'
import { StyledContainer as Container } from './StyledContainer'
import { StyledButtonContainer } from './StyledButtonContainer'
import { useResetRecoilState } from 'recoil'
import { broadcastsState } from '../../../store/broadcasts/broadcasts.atom'

interface Props {
  name: string
}

export const ChannelGroupHeader = ({ name }: Props) => {
  const isMobile = !useWindowSize('medium')
  const resetBroadcastState = useResetRecoilState(broadcastsState)

  return (
    <StyledContainer>
      <Heading1>{`TV program - ${name}`}</Heading1>
      <Link to={AppConfig.routes.home} onClick={resetBroadcastState}>
        <StyledButtonContainer>
          <StyledBackToHomeButton>
            {isMobile ? 'Seznam stanic' : 'Zpět na seznam stanic'}
          </StyledBackToHomeButton>
        </StyledButtonContainer>
      </Link>
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)`
  margin-bottom: 14px;
`
