import { atom } from 'recoil'
import { ModalNames } from './modals.constants'
import { StoreStateName } from '../../storeStateName'

export interface ModalState {
  isOpened: boolean
  inProgress: boolean
}

type ModalStateType = Record<ModalNames, ModalState>

export const modalsState = atom<ModalStateType>({
  key: StoreStateName.UI_MODALS,
  default: {
    [ModalNames.SETTINGS]: {
      isOpened: false,
      inProgress: false
    }
  }
})
