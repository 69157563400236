import theme from '../../../client/theme'
import styled from 'styled-components'
import { Number } from './StyledNumber'
import { Channel } from './StyledChannel'
import { ArrowDown, ArrowUp } from '../../Arrows/OrderArrows'
import { IChannel } from '../../../types/entities'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

interface OrderListItemProps {
  channel: IChannel
  onProgrammaticallyChange: (id: IChannel['id'], oldIndex: number, newIndex: number) => void
}

const OrderListItem = ({ channel, onProgrammaticallyChange }: OrderListItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, active, index, items } =
    useSortable({
      id: channel.id
    })

  return (
    <StyledListItem
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: active ? 'grabbing' : 'grab'
      }}
      {...attributes}
      {...listeners}
    >
      <Number>{index + 1}</Number>
      <Channel>{channel.name}</Channel>
      <ArrowDown
        $hidden={index === items.length - 1}
        onClick={() =>
          onProgrammaticallyChange(channel.id, index, Math.min(index + 1, items.length - 1))
        }
      />
      <ArrowUp
        $hidden={index === 0}
        onClick={() => onProgrammaticallyChange(channel.id, index, Math.max(index - 1, 0))}
      />
    </StyledListItem>
  )
}

export const StyledListItem = styled.li`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: ${theme.sizes.bigger};
  line-height: normal;
  background: ${theme.colors.channelSelectedBackground};
  color: ${theme.colors.selectionFontColor};
  overflow-y: auto;
  margin: 4px 0;
  height: 42px;

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    font-size: ${theme.sizes.medium};
    height: 35px;
  }
`

export default OrderListItem
