import styled from 'styled-components'
import theme from '../client/theme'

export const AddCross = styled.div`
  position: relative;
  width: 8px;
  height: 8px;

  &:before {
    content: '';
    display: block;
    width: 2px;
    height: 100%;
    background: ${theme.colors.buttonLightColor};
    position: absolute;
    left: 3px;
    top: 0;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: ${theme.colors.buttonLightColor};
    position: absolute;
    left: 0;
    top: 3px;
  }
`
