import { IProgramme } from '../../../types/entities'
import { List } from './List'
import { useContext } from 'react'
import { ScrollContext } from '../../../context/Scroll.context'
import noop from 'lodash/noop'

interface Props {
  listProps: {
    programmes: IProgramme[]
    channelSlug: string
    onShowDetails: (slug: string) => void
  }
}

export const DesktopList = ({ listProps }: Props) => {
  const { onDesktopRefCallback } = useContext(ScrollContext)

  return (
    <List
      listProps={listProps}
      programmeCallbacks={{
        onFindRunningProgramme: (programme) => onDesktopRefCallback(programme),
        onFindFirstTomorrowProgramme: noop
      }}
    />
  )
}
