import styled from 'styled-components'

export const StyledContainer = styled.nav`
  color: ${({ theme }) => theme.colors.default};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  order: 4;
  margin: 0 auto;

  > div:nth-child(2) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
      width: auto;
    }
  }

  > div:nth-child(3) {
    display: flex;
    flex-wrap: nowrap;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    order: 2;
    width: initial;
    gap: 0 20px;
    margin: 0;
  }
`
