import { FooterTitle } from './StyledSecondCol'
import { FooterLink } from './StyledFooterLink'
import { List, ListItem } from './StyledFooterList'

export const NewsList = () => (
  <div>
    <FooterTitle>Zpravodajství</FooterTitle>
    <List>
      <ListItem>
        <FooterLink
          href='https://hn.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Zprávy
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://sport.aktualne.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Sport
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://video.aktualne.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Videa
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://zpravy.aktualne.cz/ekonomika/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Ekonomika
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://zpravy.aktualne.cz/ekonomika/auto/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Auto
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://www.aktualne.cz/wiki/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Wiki
        </FooterLink>
      </ListItem>
    </List>
  </div>
)
