import styled from 'styled-components'
import LeaderBoard from '../Ads/LeaderBoard'
import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

export const AdsWrapper = ({ children }: Props) => (
  <StyledContainer>
    <div id='brand-a' className='brand-a'>
      <div id='brand-b' className='brand-b'>
        <div id='brand-c' className='brand-c'>
          <LeaderBoard
            targetId='reklama-leader'
            supertag={'leader'}
          />
          {children}
        </div>
      </div>
    </div>
  </StyledContainer>
)

const StyledContainer = styled.div`
  font-family: ${({ theme }) => theme.fonts.default};
`

export const Overlay = styled.div`
  width: 100%;
  position: relative;
`
