import styled from 'styled-components'
import theme from '../../client/theme'

export const StyledSubNavHeader = styled.div`
  height: auto !important;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Title = styled.h3`
  width: 65%;
  text-align: center;
  font-weight: 500;
  font-size: ${theme.sizes.biggest};
`
