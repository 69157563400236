import theme from '../../client/theme'
import styled from 'styled-components'

export const Programs = styled.div`
  width: 100%;
  margin-bottom: 30px;
  scroll-margin-top: 70px;
  overflow: hidden;

  @media screen and (min-width: ${theme.breakpoints.large}) {
    width: 57%;
    padding-top: 10px;
    border-right: 1px solid ${theme.colors.channelBorder};
    margin-right: 20px;
    margin-bottom: 0;
    scroll-margin-top: 0;
  }
`
