import { SettingsStateType } from '../types'
import { BroadcastDisplayMode, COOKIE_SETTINGS_NAME } from '../../constants'
import { DefaultChannelsIds } from '../../constants/channels'
import store from 'store2'
import { isHorizontalLayoutForbidden } from '../../utils/layout'
import Cookies from 'universal-cookie'

export const LOCAL_STORAGE_USER_SETTINGS_KEY = 'userSettings'

const cookies = new Cookies()

const defaultSettings: SettingsStateType = {
  channelIds: DefaultChannelsIds.slice(),
  broadcastDisplayMode: isHorizontalLayoutForbidden()
    ? BroadcastDisplayMode.VERTICAL
    : BroadcastDisplayMode.HORIZONTAL
}

export const updateCookieSettings = () => {
  if (typeof document === 'undefined') {
    return
  }

  cookies.set(COOKIE_SETTINGS_NAME, 1, {
    httpOnly: false,
    secure: false,
    // https://stackoverflow.com/questions/532635/javascript-cookie-with-no-expiration-date
    expires: new Date(2147483647 * 1000)
  })
}

export const saveLocalStorageSettings = (settings: SettingsStateType) => {
  store.set(LOCAL_STORAGE_USER_SETTINGS_KEY, settings, true)
}

export const getLocalStorageSettings = (): SettingsStateType => {
  const settings = store.get(LOCAL_STORAGE_USER_SETTINGS_KEY)
  if (!settings || !(typeof settings === 'object')) {
    return defaultSettings
  }

  try {
    const missingKeys = Object.keys(defaultSettings).filter(
      (key) => !Object.prototype.hasOwnProperty.call(settings, key)
    ) as unknown as Array<keyof typeof defaultSettings>

    missingKeys.forEach((key) => {
      settings[key] = defaultSettings[key]
    })

    if (missingKeys.length > 0) {
      saveLocalStorageSettings(settings)
    }

    return settings
  } catch (e) {
    store.remove(LOCAL_STORAGE_USER_SETTINGS_KEY)
    return defaultSettings
  }
}
