import { modalsState, ModalState } from './modals.atom'
import { selector, selectorFamily } from 'recoil'
import { StoreSelector } from '../../storeStateName'
import { ModalNames } from './modals.constants'

export const modalStateSelector = selectorFamily<ModalState, ModalNames>({
  key: StoreSelector.UI_MODALS_SETTINGS,
  set:
    (modalId) =>
    ({ set, get }, updatedProperties) => {
      const modals = get(modalsState)

      const oldState: ModalState = modals[modalId]
      const newState: ModalState = {
        ...oldState,
        ...updatedProperties
      }

      set(
        modalsState,
        Object.assign({}, modals, {
          [modalId]: newState
        })
      )
    },
  get:
    (modalId) =>
    ({ get }) =>
      get(modalsState)[modalId]
})

export const isAnyModalOpenedSelector = selector<boolean>({
  key: StoreSelector.UI_MODALS_ANY_OPENED,
  get: ({ get }) => Object.keys(get(modalsState)).length > 0
})
