import { atom } from 'recoil'
import { StoreStateName } from '../../storeStateName'
import { AppStateType } from '../../types'

export const appState = atom<AppStateType>({
  key: StoreStateName.APP_STATE,
  default: {
    selectedDate: undefined,
    visibleTimeStart: undefined,
    visibleTimeEnd: undefined,
    visibleHorizontalTimeStart: undefined
  }
})
