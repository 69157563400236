import { ReactCalendarTimelineProps } from 'react-calendar-timeline'
import { useCallback } from 'react'
import { END_VISIBLE_TIME, START_VISIBLE_TIME } from './HorizontalBroadcast.constants'
import moment from 'moment'
import { formatDate } from '../../../utils/date'
import useSelectedDate from '../../../hooks/useSelectedDate'

const useHorizontalScroller = () => {
  const {
    selectedDate,
    visibleTimeStart: startTime,
    visibleTimeEnd: endTime,
    setSelectedDate,
    visibleHorizontalTimeStart
  } = useSelectedDate()

  const handleUpdateScrollCanvas = useCallback(
    (
      startTime: number,
      endTime: number,
      updateScrollCanvas: (start: number, end: number) => void
    ) => {
      let newSelectedDate: string | undefined = undefined

      if (moment(startTime).isSame(moment(endTime), 'day')) {
        const startDate = moment(startTime)
        newSelectedDate = formatDate(startDate)
      }

      setSelectedDate({
        selectedDate: newSelectedDate,
        visibleTimeStart: startTime,
        visibleTimeEnd: endTime,
        visibleHorizontalTimeStart
      })
      updateScrollCanvas(startTime, endTime)
    },
    []
  )

  const handleTimeChange: ReactCalendarTimelineProps['onTimeChange'] = useCallback(
    (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
      // https://github.com/namespace-ee/react-calendar-timeline#ontimechangevisibletimestart-visibletimeend-updatescrollcanvas-unit
      if (visibleTimeStart < START_VISIBLE_TIME && visibleTimeEnd > END_VISIBLE_TIME) {
        handleUpdateScrollCanvas(START_VISIBLE_TIME, END_VISIBLE_TIME, updateScrollCanvas)
      } else if (visibleTimeStart < START_VISIBLE_TIME) {
        handleUpdateScrollCanvas(
          START_VISIBLE_TIME,
          START_VISIBLE_TIME + (visibleTimeEnd - visibleTimeStart),
          updateScrollCanvas
        )
      } else if (visibleTimeEnd > END_VISIBLE_TIME) {
        handleUpdateScrollCanvas(
          END_VISIBLE_TIME - (visibleTimeEnd - visibleTimeStart),
          END_VISIBLE_TIME,
          updateScrollCanvas
        )
      } else {
        handleUpdateScrollCanvas(visibleTimeStart, visibleTimeEnd, updateScrollCanvas)
      }
    },
    []
  )

  return {
    handleTimeChange,
    selectedDate,
    endTime,
    startTime
  }
}

export default useHorizontalScroller
