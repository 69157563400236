import styled from 'styled-components'

export const Header = styled.div`
  color: inherit;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 5px 10px;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.sizes.smallest};
`
