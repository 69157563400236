import { OnDayChangeCallbacks } from '../components/LandingPage/Programs/MobileList'

enum Section {
  Today,
  Tomorrow
}

export const useChangeLabelByScrollPosition = (
  listContainer: HTMLDivElement,
  firstTomorrowProgramme: HTMLDivElement,
  onDayChangeCallbacks: OnDayChangeCallbacks
) => {
  listContainer.addEventListener(
    'scroll',
    handleScrollChange(firstTomorrowProgramme, listContainer, onDayChangeCallbacks)
  )

  return () => {
    listContainer.removeEventListener(
      'scroll',
      handleScrollChange(firstTomorrowProgramme!, listContainer, onDayChangeCallbacks)
    )
  }
}

function handleScrollChange(
  element: HTMLDivElement,
  listContainer: HTMLDivElement,
  { onChangeToToday, onChangeToTomorrow }: OnDayChangeCallbacks
) {
  let currentSection = Section.Today

  return () => {
    if (listContainer.scrollTop >= element.offsetTop && currentSection === Section.Today) {
      onChangeToTomorrow()
      currentSection = Section.Tomorrow
    } else if (listContainer.scrollTop < element.offsetTop && currentSection === Section.Tomorrow) {
      onChangeToToday()
      currentSection = Section.Today
    }
  }
}
