import styled from 'styled-components'

export const StyledDayName = styled.strong`
  text-transform: capitalize;
  font-weight: 500;
`

export const StyledSelectWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    display: block;

    & .react-select__menu {
      border: 1px solid ${({ theme }) => theme.colors.default};
      border-radius: 0;
      border-bottom-left-radius: ${({ theme }) => theme.radius.default};
      border-bottom-right-radius: ${({ theme }) => theme.radius.default};
      background-color: ${({ theme }) => theme.colors.corporateBlue};
      margin-top: -6px;
      border-top: none;
      z-index: ${({ theme }) => theme.zindex.header};
    }

    & .react-select__menu-list {
      margin-top: 4px;
      border-top: 1px solid ${({ theme }) => theme.colors.default};
      padding: 0;
    }

    & .react-select__option {
      padding: 6px 12px;
      cursor: pointer;

      &.react-select__option--is-focused {
        background-color: ${({ theme }) => theme.colors.corporateBlue};
        color: ${({ theme }) => theme.colors.buttonLightColor};
      }

      &.react-select__option--is-selected {
        background-color: ${({ theme }) => theme.colors.buttonLightColor};
      }

      &.react-select__option--is-selected.react-select__option--is-focused {
        color: ${({ theme }) => theme.colors.default};
      }

      &:last-child {
        border-bottom-left-radius: ${({ theme }) => theme.radius.default};
        border-bottom-right-radius: ${({ theme }) => theme.radius.default};
      }
    }

    & .react-select__indicator-separator {
      display: none;
    }

    & .react-select__indicator {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      color: ${({ theme }) => theme.colors.default};

      &:hover {
        color: ${({ theme }) => theme.colors.default};
      }
    }

    & .react-select__value-container {
      padding-right: 0;
    }

    & .react-select__control {
      width: 135px;
      height: 28px;
      position: relative;
      border-color: ${({ theme }) => theme.colors.default};
      border-radius: ${({ theme }) => theme.radius.default};
      background-color: ${({ theme }) => theme.colors.corporateBlue};
      box-shadow: none;
      min-height: 0;

      &:hover {
        border-color: ${({ theme }) => theme.colors.default};
      }

      & .react-select__placeholder,
      & .react-select__single-value {
        color: ${({ theme }) => theme.colors.default};
        font-size: ${({ theme }) => theme.sizes.medium};
        font-weight: 300;
      }

      cursor: pointer;
      box-sizing: border-box;
    }
  }
`
