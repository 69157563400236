import styled from 'styled-components'
import { HeaderInfoItem } from '../../ProgramDetail/StyledHeaderInfo'
import { IProgramme } from '../../../types/entities'
import { GenresTypes } from '../../../constants/genres'

interface Props {
  programme: IProgramme
}

export const SecondaryDetailInfo = ({ programme }: Props) => (
  <StyledContainer>
    {[`${programme.durationMin} minut`, GenresTypes[programme.genre]].filter(Boolean).join(' | ')}
  </StyledContainer>
)

const StyledContainer = styled(HeaderInfoItem)`
  padding: 0;
  margin-bottom: 8px;
`
