import { IProgramme } from '../types/entities'
import moment from 'moment'
import { DATE_START_HOURS_OFFSET } from '../components/Broadcast/Horizontal/HorizontalBroadcast.constants'

export const isRunningProgramme = (programme: Pick<IProgramme, 'start' | 'stop'>) => {
  const now = moment()
  return now.isAfter(programme.start) && now.isBefore(programme.stop)
}

export const isFirstTomorrowProgramme = (programme: IProgramme) => {
  const startOfTomorrow = moment().add('1', 'd').startOf('day').add(DATE_START_HOURS_OFFSET, 'h')
  return startOfTomorrow.isAfter(programme.start) && startOfTomorrow.isBefore(programme.stop)
}
