import useQueryLocation from './useQueryLocation'
import AppConfig from '../config'
import { useCallback } from 'react'
import { useMouseLinkHandlers, useTouchLinkHandlers } from './useLinkHandlers'

export const useNavigateToChannelProgrammeDetail = (channelSlug: string, programmeSlug: string) => {
  const href = AppConfig.routes.channelProgrammeDetail(channelSlug, programmeSlug)

  return useNavigateToProgrammeDetail(href)
}

function useNavigateToProgrammeDetail(href: string) {
  const [, setLocation] = useQueryLocation()

  const handleRedirect = useCallback(() => {
    setLocation(href)
  }, [href])

  const mouseLinkHandlers = useMouseLinkHandlers(handleRedirect)
  const touchLinkHandlers = useTouchLinkHandlers(handleRedirect)

  return {
    mouseLinkHandlers,
    touchLinkHandlers,
    href
  }
}
