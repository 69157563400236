import useQueryLocation from '../../../hooks/useQueryLocation'
import { ProgramDetailWrapper } from '../../ProgramDetail/ProgramDetailWrapper'
import Config from '../../../config'

interface Props {
  channelGroupSlug: string
  programmeSlug: string
}

export const ChannelGroupProgrammeDetail = ({ channelGroupSlug, programmeSlug }: Props) => {
  const [, setLocation] = useQueryLocation()

  return (
    <ProgramDetailWrapper
      slug={programmeSlug}
      onClose={() => setLocation(Config.routes.channelGroup(channelGroupSlug))}
    />
  )
}
