import styled from 'styled-components'

export const Heading1 = styled.h1`
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.Heading1};
  line-height: 23px;
  color: ${({ theme }) => theme.colors.default};
`

export const Heading2 = styled.h2`
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.Heading2};
  line-height: 23px;
  color: ${({ theme }) => theme.colors.corporateBlue};
`

export const Heading3 = styled.h3`
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.bigger};
  line-height: 19px;
  color: ${({ theme }) => theme.colors.corporateBlue};
`

export const Heading4 = styled.h4`
  font-size: ${({ theme }) => theme.sizes.medium};
  margin: 0 0 6px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-weight: 500;
    font-size: ${({ theme }) => theme.sizes.big};
    line-height: 18px;
    color: ${({ theme }) => theme.colors.default};
  }
`

export const Heading4Dark = styled.h4`
  font-size: ${({ theme }) => theme.sizes.medium};
  color: ${({ theme }) => theme.colors.selectionFontGrey};
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  padding: 16px 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: ${({ theme }) => theme.sizes.smaller};
    line-height: 14px;
    text-align: left;
    padding: 4px 0 16px;
  }
`

export const Heading4Blue = styled.h4`
  font-size: ${({ theme }) => theme.sizes.bigger};
  color: ${({ theme }) => theme.colors.buttonColor};
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  margin: 20px 0 13px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: ${({ theme }) => theme.sizes.medium};
    line-height: 16px;
    text-align: left;
    margin: 20px 0 6px;
  }
`
