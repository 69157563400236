import { FirstCol, WrapperLogo } from './StyledFirstCol'
import { CentrumLogo } from '../Logos/CentrumLogo'
import { FooterLink } from './StyledFooterLink'
import { List, ListItem } from './StyledFooterList'

export const SectionCentrum = () => (
  <FirstCol>
    <WrapperLogo>
      <CentrumLogo />
    </WrapperLogo>
    <List>
      <ListItem>
        <FooterLink
          href='https://www.facebook.com/multimail/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Facebook Centrum.cz
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://www.centrum.cz/nastavte-si-domovskou-stranku/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Nastavit jako domovskou stránku
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='http://ankety.centrum.cz/s3/napiste-nam/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Napište nám
        </FooterLink>
      </ListItem>
    </List>
  </FirstCol>
)
