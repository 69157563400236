import { useEffect, useState } from 'react'
import theme from '../client/theme'

const { breakpoints } = theme

const getMatch = (breakpoint: string) => {
  return window.matchMedia(`(min-width: ${breakpoint})`).matches
}

const useWindowBreakpoint = (minWidthBreakpoint: keyof typeof breakpoints) => {
  const breakpoint = breakpoints[minWidthBreakpoint]
  const [isMatch, setMatch] = useState(typeof window === 'undefined' ? !global.__SSR_IS_UA_MOBILE : getMatch(breakpoint))

  useEffect(() => {
    const match = getMatch(breakpoint)
    const listener = () => setMatch(() => match)

    setMatch(match)

    window.addEventListener('resize', listener)

    return () => window.removeEventListener('resize', listener)
  }, [breakpoint])

  return isMatch
}

export default useWindowBreakpoint
