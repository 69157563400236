import React, { useCallback, useEffect, useRef } from 'react'
import { ControlledTimeSign } from '../../TimeSign'
import 'react-calendar-timeline/lib/Timeline.css'
import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TodayMarker
} from 'react-calendar-timeline'
import useHorizontalBroadcast from './useHorizontalBroadcast'
import AdChannelsButton from '../../AdChannelsButton'
import {
  HEADER_LABEL_FORMAT,
  MAX_ZOOM_TIME,
  MIN_ZOOM_TIME,
  ROW_HEIGHT,
  SIDEBAR_WIDTH,
  STICKY_HEADER_CLASS_NAME
} from './HorizontalBroadcast.constants'
import useHorizontalRefresher from './useHorizontalRefresher'
import useHorizontalScroller from './useHorizontalScroller'
import { StyledAddWrapper, StyledWrapper } from './HorizontalBroadcast.styles'
import useKeyboardScroll from '../../../hooks/useKeyboardScroll'
import { IChannel } from '../../../types/entities'

const MemoizedTimeline = React.memo(Timeline, (prevProps, newProps) => {
  return (
    prevProps.items === newProps.items &&
    prevProps.visibleTimeEnd === newProps.visibleTimeEnd &&
    prevProps.visibleTimeStart === newProps.visibleTimeStart
  )
})

interface Props {
  channels: IChannel[]
  onProgrammeClick: (slug: string) => void
}

const HorizontalBroadcast = ({ channels, onProgrammeClick }: Props) => {
  const { selectedDate, startTime, endTime, handleTimeChange } = useHorizontalScroller()
  const { items, groups, handleItemRender } = useHorizontalBroadcast(channels, onProgrammeClick)
  const { handleBoundsChange } = useHorizontalRefresher(channels, selectedDate)

  const scrollContainerRef = useRef<any>(null)
  useKeyboardScroll(scrollContainerRef)

  const refCallback = useCallback((instance: any) => {
    scrollContainerRef.current = instance ? (instance.scrollComponent as HTMLDivElement) : undefined
  }, [])

  useEffect(() => {
    if (selectedDate) {
      handleBoundsChange(startTime!, endTime!)
    }
  }, [selectedDate])

  if (!selectedDate) {
    return null
  }

  return (
    <StyledWrapper>
      <MemoizedTimeline
        ref={refCallback}
        groups={groups}
        items={items}
        itemTouchSendsClick={true}
        canMove={false}
        canResize={false}
        stackItems={true}
        minZoom={MIN_ZOOM_TIME}
        maxZoom={MAX_ZOOM_TIME}
        sidebarWidth={SIDEBAR_WIDTH}
        itemHeightRatio={1.0}
        lineHeight={ROW_HEIGHT}
        visibleTimeStart={startTime}
        visibleTimeEnd={endTime}
        itemRenderer={handleItemRender}
        onBoundsChange={handleBoundsChange}
        onTimeChange={handleTimeChange}
        // @ts-expect-error wrong library types
        timeSteps={{
          hour: 1
        }}
      >
        <TimelineHeaders className={STICKY_HEADER_CLASS_NAME}>
          <SidebarHeader>
            {({ getRootProps }) => (
              <StyledAddWrapper {...getRootProps()}>
                <AdChannelsButton />
              </StyledAddWrapper>
            )}
          </SidebarHeader>
          <DateHeader labelFormat={HEADER_LABEL_FORMAT} />
          {/* @ts-expect-error wrong library types */}
          <TodayMarker>
            {({ date, styles }) => <ControlledTimeSign date={date} style={styles} />}
          </TodayMarker>
        </TimelineHeaders>
      </MemoizedTimeline>
    </StyledWrapper>
  )
}

export default React.memo(HorizontalBroadcast, () => true)
