import { FooterTitle } from './StyledSecondCol'
import { FooterLink } from './StyledFooterLink'
import { List, ListItem } from './StyledFooterList'

export const ServicesList = () => (
  <div>
    <FooterTitle>Praktické služby</FooterTitle>
    <List>
      <ListItem>
        <FooterLink
          href='http://tvprogram.centrum.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Televizní program
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://pocasi.centrum.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Počasí
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://zena.aktualne.cz/horoskopy/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Horoskopy
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='http://svatky.centrum.cz/jmenny-seznam/psijmena/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Svátky
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='http://slovniky.centrum.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Slovníky
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='http://zakony.centrum.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Zákony
        </FooterLink>
      </ListItem>
    </List>
  </div>
)
