import React, { ForwardedRef, ReactNode, useRef } from 'react'
import { Overlay } from './StyledOverlay'
import { Container } from './StyledContainer'
import { useModal, UseModalType } from '../../hooks/useModal'
import { ModalNames } from '../../store/ui/modals/modals.constants'
import useEscapeKey from '../../hooks/useEscapeKey'
import { ModalContext } from '../../context/Modal.context'
import { StyledComponentPropsWithRef } from 'styled-components'
import { ScrollableContainers } from '../MobileNav/useMobileNavigation'

interface ModalProps {
  children: ReactNode
  id: ModalNames
}

interface StaticModalProps extends StyledComponentPropsWithRef<typeof Overlay> {
  children: ReactNode
  onOutsideClick?: (e: React.MouseEvent<unknown, MouseEvent>) => unknown
  modalData?: UseModalType
}

export const StaticModal = React.forwardRef(
  (
    { onOutsideClick, modalData, children, ...props }: StaticModalProps,
    ref?: ForwardedRef<HTMLDivElement>
  ) => {
    const handleOnClick = (e: React.MouseEvent<unknown, MouseEvent>) => {
      if (onOutsideClick && e.target === e.currentTarget) {
        onOutsideClick(e)
      }
    }

    return (
      <Overlay ref={ref} onClick={handleOnClick} {...props}>
        <Container>
          {modalData ? (
            <ModalContext.Provider value={modalData}>{children}</ModalContext.Provider>
          ) : (
            children
          )}
        </Container>
      </Overlay>
    )
  }
)

export const Modal = ({ id, children }: ModalProps) => {
  const modal = useModal(id)
  const overlayRef = useRef<HTMLDivElement>(null)

  useEscapeKey(modal.closeModal)

  if (!modal.state.isOpened) {
    return null
  }

  return (
    <StaticModal
      id={ScrollableContainers.MODAL_WINDOW}
      onOutsideClick={modal.closeModal}
      ref={overlayRef}
      modalData={modal}
    >
      {children}
    </StaticModal>
  )
}
