import styled from 'styled-components'

const StyledProgramDetailsContainer = styled.div`
  position: sticky;
  top: ${({ theme }) => `${theme.sizes.headerHeightMobile}px`};
  height: 100%;
  overflow-y: auto;
  flex: ${({ theme }) => `0 0 ${theme.sizes.channelPage.detailWidth}px`};
  color: ${({ theme }) => theme.colors.corporateBlue};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    top: ${({ theme }) => `${theme.sizes.headerHeightDesktop}px`};
  }
`

export default StyledProgramDetailsContainer
