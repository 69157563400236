import AppConfig from '../../config'
import { StyledImage, StyledMainLogo } from './StyledLogos'
import QueryLink from '../QueryLink'
import { useResetRecoilState } from 'recoil'
import { broadcastsState } from '../../store/broadcasts/broadcasts.atom'

export const MainLogo = () => {
  const resetBroadcastState = useResetRecoilState(broadcastsState)

  return (
    <QueryLink href={AppConfig.routes.home} onClick={resetBroadcastState}>
      <StyledMainLogo>
        <StyledImage src='/static/images/logos/tv_centrum_logo.svg' alt='Televizní programy' />
      </StyledMainLogo>
    </QueryLink>
  )
}
