import { IProgramme } from '../../types/entities'
import { ClosingCross } from '../Modal/ClosingModal'
import { Heading, ProgramDetail } from './ProgramDetail'
import { Container } from './StyledContainer'
import useProgramDetail from './useProgramDetail'
import { StaticModal } from '../Modal'
import { Helmet } from 'react-helmet'
import config from '../../config'
import { truncateString } from '../../utils/string'

const MAX_PROGRAMME_META_DESCRIPTION_LENGTH = 150

interface Props {
  slug: IProgramme['slug']
  onClose: (detail: ReturnType<typeof useProgramDetail>) => void
}

export const ProgramDetailWrapper = ({ slug, onClose }: Props) => {
  const programmeDetail = useProgramDetail(slug)
  const { data, addToCalendarLink, statusMessage } = programmeDetail

  function handleOutsideClick() {
    onClose(programmeDetail)
  }

  if (!data) {
    return (
      <StaticModal onOutsideClick={handleOutsideClick}>
        <Container>
          <ClosingCross onClick={handleOutsideClick} />
          <Heading>{statusMessage}</Heading>
        </Container>
      </StaticModal>
    )
  }

  return (
    <>
      <StaticModal onOutsideClick={handleOutsideClick}>
        <ProgramDetail
          programme={data}
          addToCalendarLink={addToCalendarLink}
          onClose={handleOutsideClick}
        />
      </StaticModal>

      <Helmet>
        <title>{`${data.title} - TV Program`}</title>
        <meta
          name={'description'}
          content={truncateString(data.description ?? '', MAX_PROGRAMME_META_DESCRIPTION_LENGTH)}
        />
        <link rel={'canonical'} href={config.domain.concat(config.routes.detail(slug))} />
      </Helmet>
    </>
  )
}
