import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: ${({ theme }) => theme.colors.channelTitleBackground};
  padding: 4px 8px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    gap: 16px;
    padding: 8px 16px;
  }
`
