import qs from 'qs'
import { IChannel } from '../types/entities'

const Config = {
  apiBaseUrl: '/api',
  domain: 'http://localhost:3000',
  endpoints: {
    programme: (id: string): string => `/programme/${id}`,
    broadcasting: (
      date: string,
      queryParams?: {
        channels?: IChannel['id'][]
      }
    ) => {
      const query = qs.stringify(queryParams || {}, {
        addQueryPrefix: true,
        arrayFormat: 'brackets'
      })
      return `/broadcasting/${date}${query}`
    },
    genres: `/genres`,
    channels: `/channels`,
    channelById: (id: string) => `/channel/id/${id}`,
    channelBySlug: (slug: string) => `/channel/slug/${slug}`,
    channelGroup: (slug: string) => `/channel-group/${slug}`
  },
  routes: {
    home: '/',
    detail: (id: string) => `/detail/${id}`,
    broadcasting: (year: string, month: string, day: string) => `/den/${year}/${month}/${day}/`,
    channelGroupBroadcasting: (slug: string, year: string, month: string, day: string) =>
      `/skupina-stanic/${slug}/den/${year}/${month}/${day}/`,
    channel: (slug: string) => `/stanice/${slug}`,
    channelGroup: (slug: string) => `/skupina-stanic/${slug}`,
    channelProgrammeDetail: (channelSlug: string, programmeSlug: string) =>
      `/stanice/${channelSlug}/detail/${programmeSlug}`,
    channelGroupProgrammeDetail: (channelGroupSlug: string, programmeSlug: string) =>
      `/skupina-stanic/${channelGroupSlug}/detail/${programmeSlug}`,
    rss: '/export/hp12/broadcast'
  },
  externalImageBaseUrl: 'https://dev-hpx-tv-image.xsd.cz'
} as const

export default Config
