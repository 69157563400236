import { useEffect } from 'react'

const useEscapeKey = (callback: () => unknown) => {
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        callback()
      }
    }

    window.document.addEventListener('keydown', handler)

    return () => {
      window.document.removeEventListener('keydown', handler)
    }
  }, [])
}

export default useEscapeKey
