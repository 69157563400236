import React, { useContext } from 'react'
import styled from 'styled-components'
import { Heading2, Heading3, Heading4Dark } from '../StyledHeadings'
import { FormSearch } from '../FormElements/FormSearch'
import { Anchor, DefaultButton, GroupButtonAnchor } from '../StyledButtons'
import { FooterModal } from './Order/StyledFooterButton'
import { Container } from './StyledContainer'
import { Programs } from './StyledPrograms'
import { Order } from './Order'
import { OrderContainer } from './Order/StyledOrderContainer'
import { HeaderModal } from './Order/StyledHeaderModal'
import { ClosingCross } from '../Modal/ClosingModal'
import { ModalContext } from '../../context/Modal.context'
import useSettings from './useSettings'
import ChannelProgrammes from './ChannelProgrammes'
import useWindowSize from '../../hooks/useWindowSize'
import { sendSettingsSaveEvent } from '../../analytics/ga4Events'
import { OnlyDesktopWrapper, OnlyMobileWrapper } from '../deviceWrappers'

export const Settings = () => {
  const { closeModal } = useContext(ModalContext)
  const {
    channels,
    selectedChannels,
    onSave,
    onSearch,
    onOrderChange,
    onCheckboxChange,
    onProgrammaticallyChange
  } = useSettings(closeModal)
  const isMobile = !useWindowSize('large')

  return (
    <Container>
      <HeaderModal>
        <ClosingCross onClick={closeModal} />
        <Title2>Nastavení stanic</Title2>
        <OnlyMobileWrapper>
          <GroupButtonAnchor>
            <Anchor href='#list'>Seznam stanic</Anchor>
            <Anchor href='#order'>Pořadí stanic</Anchor>
          </GroupButtonAnchor>
        </OnlyMobileWrapper>
      </HeaderModal>
      <Programs id='list'>
        <div>
          <OnlyDesktopWrapper>
            <Heading3>Seznam stanic</Heading3>
          </OnlyDesktopWrapper>
          <FormSearch onSearch={onSearch} />
          <ChannelProgrammes
            channels={channels}
            selectedChannels={selectedChannels}
            onCheckboxChange={onCheckboxChange}
          />
        </div>
      </Programs>
      <OrderContainer id='order'>
        <OnlyDesktopWrapper>
          <Heading3>Pořadí vybraných stanic</Heading3>
        </OnlyDesktopWrapper>
        <Heading4Dark>Pořadí můžete měnit šipkami{!isMobile && ' nebo tažením'}.</Heading4Dark>
        <Order
          selectedChannelsIds={selectedChannels}
          onOrderChange={onOrderChange}
          onProgrammaticallyChange={onProgrammaticallyChange}
        />
      </OrderContainer>
      <FooterModal>
        <DefaultButton
          onClick={() => {
            sendSettingsSaveEvent()
            onSave()
          }}
        >
          Uložit změny a zavřít
        </DefaultButton>
      </FooterModal>
    </Container>
  )
}

const Title2 = styled(Heading2)`
  text-align: center;
`
