import type { SVGProps } from 'react'

const ArrowDownIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect
        x='9.01233'
        y='13.0908'
        width='2.90909'
        height='13.0909'
        transform='rotate(-180 9.01233 13.0908)'
        fill='currentColor'
      />
      <path
        d='M7.55805 16L1.12534e-05 7.27272L7.55805 8.54137L15.1161 7.27273L7.55805 16Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ArrowDownIcon
