import { FooterTitle } from './StyledSecondCol'
import { FooterLink } from './StyledFooterLink'
import { List, ListItem } from './StyledFooterList'

export const MagazinesList = () => (
  <div>
    <FooterTitle>Magazíny</FooterTitle>
    <List>
      <ListItem>
        <FooterLink
          href='https://zena.aktualne.cz/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Žena
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://magazin.aktualne.cz/kultura/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Kultura
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://zena.aktualne.cz/zdravi/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Zdraví
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://zena.aktualne.cz/rodina/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Rodina
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://magazin.aktualne.cz/celebrity/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Celebrity
        </FooterLink>
      </ListItem>
      <ListItem>
        <FooterLink
          href='https://magazin.aktualne.cz/obrazem/?utm_medium=footer&utm_source=tvprogram.centrum.cz'
          target='_blank'
          rel='noreferrer'
        >
          Obrazem
        </FooterLink>
      </ListItem>
    </List>
  </div>
)
