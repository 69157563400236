import styled from 'styled-components'
import { ListHeader } from '../Programs/ListHeader'
import { DesktopList } from '../Programs/DesktopList'
import { useFetchChannelTodayBroadcast } from '../../../store/hooks/useFetchBroadcast'
import { Times } from '../../../constants/times'

interface Props {
  broadcastProps: {
    channelId: string
    channelSlug: string
    onShowDetails: (slug: string) => void
  }
}

export const TodayBroadcast = ({ broadcastProps: { channelId, ...broadcastProps } }: Props) => {
  const { programmes, date } = useFetchChannelTodayBroadcast(channelId)

  return (
    <StyledContainer>
      <ListHeader title={Times.TODAY} date={date} />
      <DesktopList listProps={{ programmes, ...broadcastProps }} />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  flex: 1;
`
