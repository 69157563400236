export const isProduction = process.env.REACT_APP_BUILD_ENV === 'production'
export const isDevelopment = process.env.REACT_APP_BUILD_ENV === 'development'

export const isReactDevelopment = process.env.NODE_ENV === 'development'

export const SASIC_STAGE = isProduction ? 'prod' : 'dev'

export const COOKIE_SETTINGS_NAME = 'hasCustomSettings'

export enum BroadcastDisplayMode {
  VERTICAL,
  HORIZONTAL
}
