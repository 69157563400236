import styled from 'styled-components'
import theme from '../../client/theme'

export const Container = styled.div`
  font-weight: 300;
  font-size: ${theme.sizes.bigger};
  line-height: 23px;
  color: ${theme.colors.corporateBlue};
  padding: 25px 25px 0;

  @media screen and (min-width: ${theme.breakpoints.medium}) {
    position: relative;
    font-size: ${theme.sizes.big};
    line-height: 22px;
  }
`

export const Paragraph = styled.div`
  margin-bottom: 20px;
  font-size: ${theme.sizes.smaller};
  font-weight: 300;
  line-height: 14px;
`

export const Content = styled.div`
  @media screen and (min-width: ${theme.breakpoints.medium}) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-top: 20px;

    & > div {
      &:first-child {
        margin-left: 26px;
        margin-right: 33px;
      }
    }
  }
`
