import { useWrappedRecoilValue } from '../../hooks/useWrappedRecoilValue'
import { channelSelectorById, channelSelectorBySlug } from '../channels/channel.selectors'

export const useFetchChannelById = (id: string) => {
  return useWrappedRecoilValue(channelSelectorById(id))
}

export const useFetchChannelBySlug = (slug: string) => {
  return useWrappedRecoilValue(channelSelectorBySlug(slug))
}
