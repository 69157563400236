import useMenuOptions from '../Menu/useMenuOptions'
import styled from 'styled-components'
import theme from '../../client/theme'
import { StyledSubNavHeader, Title } from './StyledSubNavHeader'
import { BackLink } from './StyledSubmenuBackLink'
import { ScrollableContainers } from './useMobileNavigation'
import { sendSelectDateMenuEvent } from '../../analytics/ga4Events'

interface MobileDateSelectProps {
  onClose: () => void
  onSelect: () => void
  isVisible: boolean
}

const MobileDateSelect = ({ onClose, onSelect, isVisible }: MobileDateSelectProps) => {
  const { options, setSelectedDate } = useMenuOptions()

  return (
    <StyledWrapper isVisible={isVisible}>
      <div>
        <StyledListWrapper>
          <StyledSubNavHeader>
            <BackLink onClick={onClose}>Zpět</BackLink>
            <Title>Datum</Title>
          </StyledSubNavHeader>
        </StyledListWrapper>
        <StyledListWrapper>
          <StyledList id={ScrollableContainers.MOBILE_DATE_SELECT}>
            {options.map((option) => (
              <StyledListItem key={option.value}>
                <StyledButton
                  isActive={option.isSelected}
                  onClick={() => {
                    sendSelectDateMenuEvent(option.offsetFromToday)
                    setSelectedDate({
                      selectedDate: option.value
                    })
                    onSelect()
                  }}
                >
                  {option.label}
                </StyledButton>
              </StyledListItem>
            ))}
          </StyledList>
        </StyledListWrapper>
      </div>
    </StyledWrapper>
  )
}

const StyledButton = styled.button<{ isActive: boolean }>`
  color: ${(props) => (props.isActive ? theme.colors.buttonLightColor : theme.colors.default)};
`

const StyledListWrapper = styled.div`
  margin: 0 auto;
  padding: 15px 0 15px 12px;
  background: ${theme.colors.corporateBlue};
  position: relative;
  transition: all 3s ease-in-out;
  color: ${theme.colors.default};
  max-height: calc(100vh - 230px);
  overflow: auto;
`

const StyledList = styled.ul`
  overflow: hidden;
`

const StyledListItem = styled.li`
  position: relative;

  button {
    cursor: pointer;
    padding: 20px 0 20px 5px;
    font-weight: 300;
    font-size: ${theme.sizes.bigger};
    text-decoration: none;
    transition: all 100ms ease 0s;
    position: relative;
    width: 100%;
    text-align: left;

    &:hover,
    &:active {
      color: ${theme.colors.buttonLightColor};
    }

    &:hover:before,
    &:active:before {
      content: '';
      background: url('/static/images/icons/checked_orange.svg') no-repeat;
      position: absolute;
      top: auto;
      bottom: auto;
      right: 20px;
      height: 20px;
      width: 20px;
      transform: scale(0.9);
    }

    strong {
      font-weight: 400;
    }
  }

  &:before {
    display: block;
    content: '';
    background: rgba(255, 255, 255, 0.25);
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`

const StyledWrapper = styled.div<{ isVisible?: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${theme.colors.corporateBlue};
  transition: opacity 500ms;
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  z-index: ${(props) => (props.isVisible ? theme.zindex.mobileNav : -1)};
  pointer-events: ${(props) => (props.isVisible ? 'auto' : 'none')};
`

export default MobileDateSelect
