import React from 'react'
import styled from 'styled-components'
import { AddButton } from './StyledButtons'
import { AddCross } from './StyledCrosses'
import { useModal } from '../hooks/useModal'
import { ModalNames } from '../store/ui/modals/modals.constants'

interface ButtonAddProps {
  isVertical?: boolean
}

const AdChannelsButton = ({ isVertical }: ButtonAddProps) => {
  const { openModal } = useModal(ModalNames.SETTINGS)

  return (
    <Add onClick={openModal} isVertical={isVertical}>
      <Cross />
      přidat
    </Add>
  )
}

const Add = styled(AddButton)`
  margin-right: 16px;
`

const Cross = styled(AddCross)`
  margin-right: 10px;
`

export default React.memo(AdChannelsButton, () => true)
