import React, { Component } from 'react'
import styled from 'styled-components'
import AdvertSignWrapper from './AdvertSignWrapper'
import SasicAdvert from './SasicAdvert'
import theme from '../../client/theme'
import { OnlyDesktopWrapper } from '../deviceWrappers'

interface Props {
  targetId: string
  supertag: string
}

const { breakpoints } = theme

export default class LeaderBoard extends Component<Props> {
  shouldComponentUpdate() {
    return false
  }

  render() {
    const { targetId, supertag } = this.props

    return (
      <AdContainer>
        <OnlyDesktopWrapper>
          <TopBanner id='cpex-remove-overflow'>
            <BannerWrapper>
              <StyledWrapper id={`${targetId}-wrapper`}>
                <AdvertSignWrapper />
                <SasicAdvert id={targetId} supertag={supertag} pos='1' />
              </StyledWrapper>
            </BannerWrapper>
          </TopBanner>
        </OnlyDesktopWrapper>
      </AdContainer>
    )
  }
}

const AdContainer = styled.div`
  max-width: 100%;
  overflow: visible;
`

const TopBanner = styled.div`
  height: auto;
  max-height: 210px;
  overflow: hidden;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${breakpoints.medium}) {
    margin: 15px auto;
    width: 100%;
  }

  @media screen and (max-width: ${breakpoints.xSmall}) {
    margin-bottom: 10px;
  }
`

const BannerWrapper = styled.div`
  display: block;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.sizes.mainContent};
  position: relative;
  text-align: center;
  width: auto;

  @media screen and (max-width: ${breakpoints.medium}) {
    max-width: 100%;
  }
`

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 210px;
`
