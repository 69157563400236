import { useWrappedRecoilValue } from '../../hooks/useWrappedRecoilValue'
import { broadcastsSelector, channelBroadcastsSelector } from '../broadcasts/broadcasts.selectors'
import { useEffect, useMemo, useState } from 'react'
import { BroadcastDateType } from '../broadcasts/broadcasts.atom'
import moment, { Moment } from 'moment/moment'
import { formatDate } from '../../utils/date'
import { IChannel, IProgramme } from '../../types/entities'
import { useBroadcasts } from './useBroadcasts'
import { DATE_START_HOURS_OFFSET } from '../../components/Broadcast/Horizontal/HorizontalBroadcast.constants'

export const useFetchChannelBroadcast = (dates: string[], channelId: string) => {
  const response = useWrappedRecoilValue(
    channelBroadcastsSelector(
      dates.map((date) => {
        return { date, channelId }
      })
    )
  )

  useBroadcasts(response.data, dates)

  return response
}

export const useFetchBroadcasts = (dates: string[], channels: IChannel[]) => {
  const response = useWrappedRecoilValue(
    broadcastsSelector({ dates, channelIds: channels.map((c) => c.id) })
  )

  useBroadcasts(response.data, dates)

  return response
}

const useFetchBroadcast = (date: string, channels: IChannel[]) => {
  const dates = useMemo(() => [date], [date])
  const response = useFetchBroadcasts(dates, channels)

  return useMemo(
    () => ({
      ...response,
      data: response.data ? response.data[0] : response.data
    }),
    [response]
  )
}

export const useFetchChannelTodayBroadcast = (channelId: string) => {
  const today = moment()
  const visibleTimeStart = today.clone().startOf('d').valueOf()

  const { programmes } = fetchChannelBroadcast(channelId, today, visibleTimeStart)

  return { programmes, date: today }
}

export const useFetchChannelTomorrowBroadcast = (channelId: string) => {
  const tomorrow = moment().startOf('d').add(1, 'd')
  const visibleTimeStart = tomorrow.clone().startOf('d').add(DATE_START_HOURS_OFFSET, 'h').valueOf()

  const { programmes } = fetchChannelBroadcast(channelId, tomorrow, visibleTimeStart)

  return { programmes, date: tomorrow }
}

const fetchChannelBroadcast = (channelId: string, date: Moment, visibleTimeStart: number) => {
  const dateStr = formatDate(date)
  const dates = calculateDates(dateStr, visibleTimeStart)
  const { data: datesData, ...response } = useFetchChannelBroadcast(dates, channelId)

  const programmes = prepareProgrammes(dateStr, datesData, visibleTimeStart, [channelId])

  return { programmes: programmes[channelId], response }
}

export const useFetchChannelMobileBroadcast = (channelId: string) => {
  const { programmes: todayProgrammes, date: todayDate } = useFetchChannelTodayBroadcast(channelId)
  const { programmes: tomorrowProgrammes, date: tomorrowDate } =
    useFetchChannelTomorrowBroadcast(channelId)
  const programmes = [...todayProgrammes, ...tomorrowProgrammes]

  return { programmes, todayDate, tomorrowDate }
}

export const useFetchBroadcastWithOffset = (
  date: string,
  channels: IChannel[],
  visibleTimeStart: number
) => {
  const dates = calculateDates(date, visibleTimeStart)
  const { data: datesData, ...response } = useFetchBroadcasts(dates, channels)

  const channelProgrammes = prepareProgrammes(
    date,
    datesData,
    visibleTimeStart,
    channels.map((c) => c.id)
  )

  return { channelProgrammes, ...response }
}

const calculateDates = (date: string, visibleTimeStart: number) => {
  const [dates, setDates] = useState([date])

  useEffect(() => {
    const offset = new Date().getTimezoneOffset()

    if (offset === 0) {
      setDates([date])
    }

    const currentDate = moment(visibleTimeStart)

    const startOfDate = moment.utc(date).startOf('day')
    const endOfDate = moment.utc(date).endOf('day')

    const shouldLoadYesterday = startOfDate.diff(currentDate, 'minutes') >= offset
    const shouldLoadTomorrow = endOfDate.diff(currentDate, 'minutes') <= offset

    const newDates = [
      shouldLoadYesterday && formatDate(moment(date).subtract(1, 'd')),
      date,
      shouldLoadTomorrow && formatDate(moment(date).add(1, 'd'))
    ].filter((x): x is string => Boolean(x))

    setDates(newDates)
  }, [date, visibleTimeStart])

  return dates
}

type ChannelProgrammes = Record<IChannel['id'], IProgramme[]>

function prepareProgrammes(
  date: string,
  datesData: BroadcastDateType[] | undefined,
  visibleTimeStart: number,
  channelIds: string[]
) {
  const endOfDate = moment(date).endOf('day').toDate().getTime()

  const channelToProgrammes: ChannelProgrammes = useMemo(() => {
    const programmes = (datesData || [])
      .map((dateData) => Object.values(dateData))
      .flat()
      .filter(Boolean)
      .flat()
      .filter((programme) => {
        if (!visibleTimeStart) {
          return true
        }

        const stopTime = moment(programme.stop).valueOf()

        return stopTime >= visibleTimeStart && stopTime <= endOfDate
      })

    const channelToProgrammes: ChannelProgrammes = {}
    channelIds.forEach((channelId) => {
      channelToProgrammes[channelId] = programmes.filter(
        (programme) => programme.channelId === channelId
      )
    })

    return channelToProgrammes
  }, [datesData, endOfDate, visibleTimeStart, JSON.stringify(channelIds)])

  return channelToProgrammes
}

export default useFetchBroadcast
