import merge from 'lodash/merge'

import DefaultConfig from './config.default'
import ProdConfig from './config.production'
import DevConfig from './config.development'

import { isProduction } from '../constants'

export type ConfigType = typeof DefaultConfig

const envConfig = isProduction ? ProdConfig : DevConfig
const finalConfig: ConfigType = merge(DefaultConfig, envConfig)

export default finalConfig
