import { VerticalContainer } from './Container'
import { IChannel, IProgramme } from '../../types/entities'
import { VerticalItem } from './ListItem'

interface Props {
  broadcast: {
    channel: IChannel
    programmes: IProgramme[]
  }
  status: {
    statusMessage: string | null
    loading: boolean
  }
  onProgrammeClick: (slug: string) => void
}

export const VerticalProgramList = ({
  broadcast: { channel, programmes },
  status: { statusMessage, loading },
  onProgrammeClick
}: Props) => {
  return (
    <VerticalContainer channel={channel} loading={loading} statusMessage={statusMessage}>
      {programmes.map((programme: IProgramme) => (
        <VerticalItem
          programme={programme}
          key={programme.id}
          onProgrammeClick={onProgrammeClick}
        />
      ))}
    </VerticalContainer>
  )
}
