import { parseInt } from 'lodash'

export function removeLeadingZero(str: string) {
  return parseInt(str).toString()
}

export function truncateString(str: string, n: number) {
  if (str.length > n) {
    return str.slice(0, n) + '...'
  }
  return str
}
