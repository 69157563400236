import { ListHeader } from '../Programs/ListHeader'
import { useFetchChannelMobileBroadcast } from '../../../store/hooks/useFetchBroadcast'
import { MobileList } from '../Programs/MobileList'
import { useState } from 'react'
import { Times } from '../../../constants/times'

interface Props {
  broadcastProps: {
    channelId: string
    channelSlug: string
    onShowDetails: (slug: string) => void
  }
}

export const MobileBroadcast = ({ broadcastProps: { channelId, ...broadcastProps } }: Props) => {
  const { programmes, todayDate, tomorrowDate } = useFetchChannelMobileBroadcast(channelId)
  const [title, setTitle] = useState(Times.TODAY)
  const [date, setDate] = useState(todayDate)

  return (
    <>
      <ListHeader title={title} date={date} />
      <MobileList
        listProps={{ programmes, ...broadcastProps }}
        onDayChangeCallbacks={{
          onChangeToTomorrow: () => {
            setTitle(Times.TOMORROW)
            setDate(tomorrowDate)
          },
          onChangeToToday: () => {
            setTitle(Times.TODAY)
            setDate(todayDate)
          }
        }}
      />
    </>
  )
}
