import { ButtonSearch, InputSearch, Search } from './StyledFormSearch'
import FormSearchIcon from '../Icons/FormSearchIcon'

interface FormSearchProps {
  onSearch: (value: string) => void
}

export const FormSearch = ({ onSearch }: FormSearchProps) => (
  <Search>
    <ButtonSearch>
      <FormSearchIcon />
    </ButtonSearch>
    <InputSearch
      autoFocus={true}
      type='text'
      defaultValue={''}
      placeholder='Hledat stanici...'
      onChange={(e) => onSearch(e.target.value)}
    />
  </Search>
)
